import { useState, useEffect, forwardRef } from "react";

import { constants, fetchWrapper } from "_helpers";
import { Loading } from "_components";
import { EventOfferLandingPage, MeetingInviteLandingPage, RealEstateOfferLandingPage, ScanAndSubscribeLandingPage } from "./offer/templates";
import { Subscribe } from "lead";

const Offer = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState(null);
    const [pictures, setPictures] = useState([]);

    useEffect(() => {
        loadOffer()
        loadPictures()
    }, [])

    const loadOffer = () => {
        let url = process.env.REACT_APP_API_URL + "/page/offer/" + props.channelName + "/" + props.invitationCode;
        if (props.code) {
            url = process.env.REACT_APP_API_URL + "/page/" + props.channelName + "/offer/" + props.code;
        }
        fetchWrapper.get(url).then(response => {
            if (response.status === 200) {
                setOffer(response.data);
                setLoading(false);
            }
        });
    }

    const loadPictures = () => {
        let url = process.env.REACT_APP_API_URL + "/page/offer/" +  props.channelName + "/" + props.invitationCode + "/pictures";
        if (props.code) {
            url = process.env.REACT_APP_API_URL + "/page/" + props.channelName + "/offer/" + props.code + "/pictures";
        }
        fetchWrapper.get(url).then(response => {
            if (response.status === 200) {
                setPictures(response.data);
            }
        });
    }

    function renderSwitch(param) {
        switch (param) {
            case constants.offerRealEstateTemplateId:
                return <RealEstateOfferLandingPage
                    offer={offer}
                    channelName={props.channelName}
                    invitationCode={props.invitationCode}
                    preview={props.preview}
                >
                    <Subscribe
                        label="Connect with us"
                        channelName={props.channelName}
                        invitationCode={props.invitationCode}
                        callToAction={offer.callToSubscribe}
                    />
                </RealEstateOfferLandingPage>;
            case constants.offerEventTemplateId:
                return <EventOfferLandingPage
                    offer={offer}
                    channelName={props.channelName}
                    invitationCode={props.invitationCode}
                    preview={props.preview}
                ><Subscribe
                        label="Connect with us"
                        channelName={props.channelName}
                        invitationCode={props.invitationCode}
                        callToAction={offer.callToSubscribe}
                    />
                </EventOfferLandingPage>;
            case constants.offerMeetingIvinteTemplateId:
                return <MeetingInviteLandingPage
                    offer={offer}
                    channelName={props.channelName}
                    invitationCode={props.invitationCode}
                >
                </MeetingInviteLandingPage>;
            case constants.offerScanAndSubscribeTemplateId:
                return <ScanAndSubscribeLandingPage
                    offer={offer}
                    pictures={pictures}
                    channelName={props.channelName}
                    invitationCode={props.invitationCode}
                >
                </ScanAndSubscribeLandingPage>;
            default:
                return null;
        }
    }

    return (
        <div ref={ref}>
            {loading === false ?
                renderSwitch(offer.templateId)
                : <Loading />}
        </div>
    )

});

export default Offer;