import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import Avatar from 'react-avatar';

import { fetchWrapper } from '_helpers';
import { Loading, NoResults, Paginate, UserFullName } from "_components";

export {
    Contacts
};

function Contacts() {
    const { register, watch, getValues, setValue } = useForm();
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        loadOwners(0);
    }, [searchPattern])

    function loadOwners(page) {
        setLoading(true);
        setValue("pageSize", 10, {shouldTouch:true});
        setValue("pageNumber", page, {shouldTouch:true});
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/admin/companies/owners", getValues()).then(response => {
            setContacts(response.data.content);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        });
    }

    const handlePageClick = (event) => {
        loadOwners(event.selected);
    };

    return (
        <div className="row justify-content-center">
            <div className="col-lg-11 col-xl-9">
                <div className="mt-5 mb-3">
                    <input type="text" className="form-control" name="searchPattern" {...register('searchPattern')} placeholder="Search contacts"/>
                </div>
                {loading === false ?
                <>
                    {contacts.length > 0 ?
                    <>
                    <div className='mt-4 border-bottom'></div>
                    <table className="table">
                        <thead>
                            <tr>
                                <td></td>
                                <td>Account owner</td>
                                <td>Company</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.map(contact =>          
                                <tr key={contact.id}>
                                    <td className="align-middle w-10">
                                        <Avatar name={contact.lastName + " " + contact.firstName} round={true} size="50" />
                                    </td>
                                    <td className="align-middle w-50">
                                        <h6 className='mb-0'><UserFullName profile={contact}/></h6>
                                        <p className="mb-0">{contact.email}</p>
                                        <p className="mb-0">{contact.mobilePhone}</p>
                                    </td>
                                    <td className="align-middle">
                                        <Link to={"/admin/company/" + contact.companyId} >{contact.companyName}</Link>
                                    </td>
                                    <td className="align-middle">

                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    </>
                    : <NoResults />}
                </>
                : <Loading/>
                }
                <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
            </div>
        </div>
    );

}
