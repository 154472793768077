import { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import normalizeUrl from 'normalize-url';
import { Footer } from "page/Footer";
import QRCode from "react-qr-code";
import { Loading } from "_components";

export function RealEstate({ offer, invitationCode, channelName, preview, children }) {
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (offer !== undefined) {
            let content = JSON.parse(offer.content);
            setContent(content);
        }
        const tempImages = []
        // eslint-disable-next-line no-lone-blocks
        {
            offer.pictures.map(item =>
                tempImages.push({
                    original: "/uploads/offer/" + item,
                    thumbnail: "/uploads/offer/" + item
                })
            )
        }
        setImages(tempImages);
        setLoading(false);
    }, [])

    return (
        <>
            {loading === true ? <Loading /> :
                <>
                <div className="container">
                    <div className="row justify-content-center mb-4 mt-4">
                        <div className="col-lg-8">
                            <h3 className="mb-2"><strong>{offer.title}</strong></h3>
                            {content.address1 &&
                                <h6 className="text-secondary">
                                    {content.address1} {content.postalCode} {content.state}{content.price && <> - {content.price} {content.currency}</>}
                                </h6>
                            }
                            {/*
                        {content.address2 && <p className='mb-0'>{content.address2}</p>}
                        {(content.city || content.state || content.postalCode) && <p className='mb-0'>{content.city}, {content.state} {content.postalCode}</p>}
                        {content.country && <p className='mb-0'>{content.country}</p>}
                    */}
                            <div className="row mt-2">
                                <div className="col-2">
                                    {content.baths && <p>Baths: {content.baths}</p>}
                                </div>
                                <div className="col-2">
                                    {content.beds && <p>Beds: {content.beds}</p>}
                                </div>
                                <div className="col-2">
                                    {content.totalFlArea && <p>{content.totalFlArea}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        <div className="row justify-content-center">
                            <div className="col">
                                {images.length > 0 && <ImageGallery items={images} showPlayButton={false} />}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col-lg-4">
                            {content.remarks && <p>{content.remarks}</p>}
                            {(content.ml || content.area || content.floorAreaFin || content.age || content.fireplaces || content.grossTaxes || content.forTaxYear) &&
                                <>
                                    <h5 className="mb-0 mt-3"><strong>Property Information</strong></h5>
                                    {content.ml && <p className="mb-0">ML#: {content.ml}</p>}
                                    {content.area && <p className="mb-0">Area: {content.area}</p>}
                                    {content.floorAreaFin && <p className="mb-0">Floor Area Fin: {content.floorAreaFin}</p>}
                                    {content.age && <p className="mb-0">Age: {content.age}</p>}
                                    {content.fireplaces && <p className="mb-0">Fireplaces: {content.fireplaces}</p>}
                                    {content.grossTaxes && <p className="mb-0">Gross taxes: {content.grossTaxes}</p>}
                                    {content.forTaxYear && <p className="mb-0">For Tax Year: {content.forTaxYear}</p>}
                                </>
                            }
                            {(content.styleOfHome || content.parking || content.bylawRestrictions || content.construction || content.amenities || content.maintFeeIncludes || content.heating || content.roof) &&
                                <>
                                    <h5 className="mb-0 mt-3"><strong>Features</strong></h5>
                                    {content.styleOfHome && <p className="mb-0">Style of home: {content.styleOfHome}</p>}
                                    {content.parking && <p className="mb-0">Parking: {content.parking}</p>}
                                    {content.bylawRestrictions && <p className="mb-0">Bylaw Restrictions: {content.bylawRestrictions}</p>}
                                    {content.construction && <p className="mb-0">Construction: {content.construction}</p>}
                                    {content.amenities && <p className="mb-0">Amenities: {content.amenities}</p>}
                                    {content.maintFeeIncludes && <p className="mb-0">Maint Fee Includes: {content.maintFeeIncludes}</p>}
                                    {content.heating && <p className="mb-0">Fuel/Heating: {content.heating}</p>}
                                    {content.roof && <p className="mb-0">Roof: {content.roof}</p>}
                                </>
                            }
                            {content.mediaLink &&
                                <>
                                    <h5 className="mb-3 mt-2"><a href={normalizeUrl(content.mediaLink)} target="_blank" rel="noreferrer" ><strong>Media link</strong></a></h5>
                                </>
                            }
                        </div>
                        <div className="col col-9 col-md-5 col-lg-4">
                            <div className='d-none d-lg-block d-md-block' >
                                {images.length > 0 && <ImageGallery items={images} showPlayButton={false} />}
                                {preview === true ?
                                    <div className="text-center mt-4">
                                        <QRCode
                                            id={offer.id}
                                            size={128}
                                            style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                            value={process.env.REACT_APP_BASE_URL + "/" + channelName + "/" + invitationCode}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    :
                                    <div className="mt-4">
                                        <div className="row justify-content-center mt-2 mb-1 g-0" >
                                            <div className="col col-9 col-md-5 col-lg-8">
                                                {children}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col-lg-8">
                            {content.rooms &&
                                <>
                                    <h5 className="mb-3 mt-3"><strong>Rooms</strong></h5>
                                    <div className="row">
                                        {content.rooms.map((room) =>
                                            <div className="col-lg-6" key={room.id} >
                                                <div className="row mb-4">
                                                    <div className="col-2">

                                                    </div>
                                                    <div className="col-8">
                                                        <h6 className="mb-0"><strong>{room.roomType}</strong></h6>
                                                        {room.level && <p className="mb-0">Level: {room.level}</p>}
                                                        {room.dimension1 && <p className="mb-0">Dimension 1: {room.dimension1}</p>}
                                                        {room.dimension2 && <p className="mb-0">Dimension 2: {room.dimension2}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            }
                            {content.listingProvidedCourtesyOf && <p className="mt-3">Listing provided courtesy of: {content.listingProvidedCourtesyOf}</p>}
                        </div>
                    </div>
                    <div className="d-block d-sm-none">
                        <div className="row justify-content-center">
                            <div className="col col-9 col-md-5 col-lg-4">
                                {preview === true ?
                                    <div className="text-center mb-4 mt-4">
                                        <QRCode
                                            id={offer.id}
                                            size={128}
                                            style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                            value={process.env.REACT_APP_BASE_URL + "/" + channelName + "/" + invitationCode}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    :
                                    <div className="mt-4">
                                        {children}
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                    <Footer />
                </>
            }
        </>
    )

}