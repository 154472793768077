import { Loading } from "_components";
import { fetchWrapper } from "_helpers";
import normalizeUrl from "normalize-url";
import { BusinessCardUserBody } from "page/BusinessCardUserBody";
import { Footer } from "page/Footer";
import { useEffect, useState } from "react";

export function MeetingInvite({ offer, channelName }) {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        loadPersonalDetails();
    }, [offer.code])

    const loadPersonalDetails = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/personal/" + channelName + "/offer/" + offer.code).then(response => {
            setProfile(response.data);
            setLoading(false);
        });
    }

    return (
        <>
            {loading && <Loading />}
            {profile &&
                <>
                    <BusinessCardUserBody profile={profile} />
                    <div className="row justify-content-center mb-4 mt-4 g-0" style={{ position: "relative" }}>
                        <div className="col text-center">
                            {offer.description &&
                            <div className="row justify-content-center mb-2">
                                <div className="col-10 col-md-5 col-lg-4">
                                    <h5 className="mb-1" style={{ whiteSpace: "pre-line" }} >{offer.description}</h5>
                                </div>
                            </div>
                            }
                            <a className="btn btn-dark btn-lg mb-4" href={normalizeUrl(offer.callToActionURL)} target="_blank">Book a Meeting</a>
                            <div className="mb-4">&nbsp;</div>
                            <div className="mb-4">&nbsp;</div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    )

}