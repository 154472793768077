import { useState } from "react";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faX } from '@fortawesome/free-solid-svg-icons'
import { useForm } from "react-hook-form";

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog } from "_components";

export {
    About
}

function About(props) {
    const authUser = useSelector(x => x.auth.user);
    const [profile, setProfile] = useState(props.profile);
    const [editMode, setEditMode] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm();
    const { errors } = formState;

    function editProperty() {
        setValue('value', profile.about);
        setEditMode(true);
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/profile/about", getValues()).then(response => {
            setEditMode(false);
            setProfile(response.data);
        });
    }

    function deleteProperty() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/profile/about").then(response => {
            setProfile(response.data);
            setShowConfirmationDialog(false);
        });
    }

    return (
        <div className="mb-3">
            <p className="mb-0">About</p>
            <p className="text-secondary mb-0 fs-6">Describe what you do and the value you provide to your contacts. This helps in growing your network.</p>
            {editMode === true ?
                <form onSubmit={handleSubmit(saveProperty)}>
                    <textarea {...register('value')} className={`form-control ${errors.value ? 'is-invalid' : ''}`} rows="3" ></textarea>
                    <div className="invalid-feedback">{errors.value?.message}</div>
                    <div className="mt-1">
                        <button type="submit" className="btn btn-sm me-1 btn-primary" >Confirm</button>
                        <button type="button" className="btn btn-sm btn-light" onClick={() => setEditMode(false)}>Cancel</button>
                    </div>
                </form>
                :
                <>
                    <p className="mt-0" style={{ whiteSpace: "pre-line" }}>{profile.about}
                        <button type="button" className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil} /></button>
                        {profile.about && <button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setShowConfirmationDialog(true)}><FontAwesomeIcon icon={faX} /></button>}
                    </p>
                    {showConfirmationDialog &&
                        <ConfirmationDialog
                            show={showConfirmationDialog}
                            handleConfirm={deleteProperty}
                            handleCancel={() => setShowConfirmationDialog(false)}
                        />
                    }
                </>
            }
        </div>
    )

}