export function PhoneNumbersPreview({phoneNumbers}) {

    return (
        <>
            {phoneNumbers &&
                phoneNumbers.map(phoneNumber => 
                    <p key={phoneNumber.value} className="mb-0">
                        <strong>{phoneNumber.title}:</strong> {phoneNumber.value}
                    </p>
                )
            }
        </>
    )

}