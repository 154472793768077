import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { ConfirmationDialog, PhoneNumberForm, SubmitBar, TextInputField } from "_components";
import { constants, fetchWrapper } from "_helpers";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { AddressForm } from "_components/AddressForm";
import { useNavigate } from "react-router-dom";

export function Edit() {
    const navigate = useNavigate();
    const [addresses, setAddresses] = useState([]);
    const [addressIdx, setAddressIdx] = useState(1);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [phoneNumberIdx, setPhoneNumberIdx] = useState(1);
    const [showUpdateWarning, setShowUpdateWarning] = useState(false);
    const [editPhoneNumberDialog, setEditPhoneNumberDialog] = useState({
        show: false,
        phoneNumber: null
    });
    const [deletePhoneNumberDialog, setDeletePhoneNumberDialog] = useState({
        show: false,
        id: null
    });
    const [editAddressDialog, setEditAddressDialog] = useState({
        show: false,
        address: null
    });
    const [deleteAddressDialog, setDeleteAddressDialog] = useState({
        show: false,
        id: null
    });

    const validationSchema = Yup.object({
        firstName: Yup.string().required('Name is required field'),
        lastName: Yup.string().required('Surname is required field'),
        email: Yup.string().required('Username is required field').email("Please enter a valid email address"),
        linkedin: Yup.string().matches(constants.linkedin, {message: 'Please provide a valid LinkedIn URL.', excludeEmptyString: true}),
        instagram: Yup.string().matches(constants.instagram, {message: 'Please provide a valid Instagram URL.', excludeEmptyString: true}),
        facebook: Yup.string().matches(constants.facebook, {message: 'Please provide a valid FaceBook URL.', excludeEmptyString: true}),
        twitter: Yup.string().matches(constants.twitter, {message: 'Please provide a valid Twitter URL.', excludeEmptyString: true})
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        loadProfile();
        loadPhoneNumbers();
        loadAddresses();
    }, [0]);

    const onSubmit = () => {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/profile", {"profile": getValues(), "phoneNumbers": phoneNumbers, "addresses": addresses}).then(response => {
            if (response.status === 200) {
                navigate("/profile");
            }
        });
    }

    function loadProfile() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile").then(response => {
            const data = response.data;
            setValue('firstName', data.firstName)
            setValue('lastName', data.lastName)
            setValue('occupation', data.occupation)
            setValue('email', data.email)
            setValue('about', data.about)
            if (data.linkedin) {
                setValue('linkedin', data.linkedin)
            }
            if (data.twitter) {
                setValue('twitter', data.twitter)
            }
            if (data.facebook) {
                setValue('facebook', data.facebook)
            }
            if (data.instagram) {
                setValue('instagram', data.instagram)
            }
            if (data.tiktok) {
                setValue('tiktok', data.tiktok)
            }
        });
    }

    function loadPhoneNumbers() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/phone-numbers").then(response => {
            const data = response.data;
            const tempPhoneNumbers = []
                // eslint-disable-next-line no-lone-blocks
                {data.map(item =>
                    tempPhoneNumbers.push({
                        id: item.id,
                        title: item.title,
                        value: item.value,
                        key: item.id
                    })
                )}
            setPhoneNumbers(tempPhoneNumbers);
            setPhoneNumberIdx(data.length + 1);
        });
    }

    function loadAddresses() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/addresses").then(response => {
            const data = response.data;
            const tempAddresses = []
                // eslint-disable-next-line no-lone-blocks
                {data.map(item => 
                    tempAddresses.push({
                        id: item.id,
                        title: item.title,
                        address1: item.address1,
                        address2: item.address2,
                        postalCode: item.postalCode,
                        state: item.state,
                        city: item.city,
                        countryId: item.countryId,
                        key: item.id
                    })
                )}
            setAddresses(tempAddresses);
            setPhoneNumberIdx(data.length + 1);
        });
    }

    function deletePhoneNumber() {
        let tempPhoneNumbers = [...phoneNumbers];
        const tempPhoneNumberIdx = tempPhoneNumbers.findIndex(element => element.key === deletePhoneNumberDialog.id);
        if (tempPhoneNumberIdx !== -1) {
            tempPhoneNumbers.splice(tempPhoneNumberIdx, 1);
            setPhoneNumbers(tempPhoneNumbers);
            setShowUpdateWarning(true);
        }
        setDeletePhoneNumberDialog({show: false});
    }

    const onSubmitPhoneNumber = (data) => {
        if (data.key === undefined) {
            let newIndex = phoneNumberIdx + 1; 
            data.key = newIndex;
            setPhoneNumberIdx(newIndex);
            let tempPhoneNumbers = [...phoneNumbers, data];
            setPhoneNumbers(tempPhoneNumbers);
        } else {
            let tempPhoneNumbers = [...phoneNumbers];
            const tempPhoneNumberIdx = tempPhoneNumbers.findIndex(element => element.key === data.key);
            if (tempPhoneNumberIdx !== -1) {
                tempPhoneNumbers[tempPhoneNumberIdx] = data;
                setPhoneNumbers(tempPhoneNumbers);
            }
        }
        setEditPhoneNumberDialog({show: false});
    }

    function deleteAddress() {
        let tempAddresses = [...addresses];
        const tempAddressIdx = tempAddresses.findIndex(element => element.key === deleteAddressDialog.id);
        if (tempAddressIdx !== -1) {
            tempAddresses.splice(tempAddressIdx, 1);
            setAddresses(tempAddresses);
        }
        setDeleteAddressDialog({show: false});
    }

    const onSubmitAddress = (data) => {
        if (data.key === undefined) {
            let newIndex = addressIdx + 1; 
            data.key = newIndex;
            setAddressIdx(newIndex);
            let tempItems = [...addresses, data];
            setAddresses(tempItems);
        } else {
            let tempItems = [...addresses];
            const tempAddressIdx = tempItems.findIndex(element => element.key === data.key);
            if (tempAddressIdx !== -1) {
                tempItems[tempAddressIdx] = data;
                setAddresses(tempItems);
            }
        }
        setEditAddressDialog({show: false});
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="mt-4 text-center">Edit Personal Profile</h1>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center mb-4">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField name="firstName" label="Name" error={errors.firstName} register={register} maxLength={100}/>
                        <TextInputField name="lastName" label="Surname" error={errors.lastName} register={register} maxLength={100}/>
                        <TextInputField name="occupation" label="Occupation" error={errors.occupation} register={register} maxLength={100}/>
                        <TextInputField name="email" label="E-mail" error={errors.email} register={register} maxLength={100}/>
                        <hr className="my-4"></hr>
                        <h4 className="mb-1" id="container-phonenumbers" >Phone numbers</h4>
                        <a href="#container-phonenumbers" onClick={() => setEditPhoneNumberDialog({show: true, phoneNumber: null})} className="btn btn-link ps-0 pb-0" type="button" >New phone number</a>
                        <p className="text-secondary mb-2 fs-6">Enter your phone number so that others can reach you.</p>
                        {phoneNumbers.map(phoneNumber => 
                            <div key={"phone_" + phoneNumber.key}>
                                {phoneNumber.title}: <a href="#container-phonenumbers" className="btn btn-link btn-sm" onClick={() => setEditPhoneNumberDialog({show: true, phoneNumber: phoneNumber})}>{phoneNumber.value}</a>
                                {phoneNumbers.length > 1 && <a href="#container-phonenumbers" className="btn btn-link btn-sm text-danger" onClick={() => setDeletePhoneNumberDialog({show:true, id: phoneNumber.key})}><FontAwesomeIcon icon={faX}/></a>}
                            </div>
                        )}
                        <hr className="my-4"></hr>
                        <h4 className="mb-1" id="container-addresses" >Addresses</h4>
                        <a href="#container-addresses" onClick={() => setEditAddressDialog({show: true, address: null})} className="btn btn-link ps-0 pb-0" type="button" >New address</a>
                        <p className="text-secondary mb-2 fs-6">Enter the physical addresses of your business.</p>
                        {addresses.map(address => 
                            <div key={address.id}>
                                {address.title && <p className="mb-0"><strong>{address.title}:</strong></p>}
                                <a href="#container-addresses" className="btn btn-link btn-sm ps-0" onClick={() => setEditAddressDialog({show: true, address: address})}>{address.address1}</a>
                                {addresses.length > 1 && <a href="#container-addresses" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteAddressDialog({show:true, id: address.id})}><FontAwesomeIcon icon={faX}/></a>}
                                {address.address2 && <p className='mb-0'>{address.address2}</p>}
                                <p className='mb-0'>{address.city}, {address.state} {address.postalCode}</p>
                                <p className='mb-2'>{address.countryName}</p>
                            </div>
                        )}
                        <hr className="my-4"></hr>
                        <TextInputField name="about" label="About" error={errors.about} register={register} textarea={true} />
                        <hr className="my-4"></hr>
                        <h4 className="mb-1" >Social Networks</h4>
                        <p className="text-secondary mb-0 fs-6">Add your social networks and enable your visitors to start following you.</p>
                        <TextInputField name="linkedin" label="LinkedIn" error={errors.linkedin} register={register} />
                        <TextInputField name="twitter" label="Twitter" error={errors.twitter} register={register} />
                        <TextInputField name="facebook" label="Facebook" error={errors.facebook} register={register} />
                        <TextInputField name="instagram" label="Instagram" error={errors.instagram} register={register} />
                        <TextInputField name="tiktok" label="TikTok" error={errors.tiktok} register={register} />
                        <div className='mt-4 mb-4'></div>
                        {showUpdateWarning && <p className="text-warning">Please save your changes before leaving this page.</p>}
                        <SubmitBar labelConfirm="Save" isSubmitting={isSubmitting} cancelLink="/profile" />
                    </form>
                </div>
            </div>
            <Modal show={editPhoneNumberDialog.show} onHide={() => setEditPhoneNumberDialog({show: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>{editPhoneNumberDialog.phoneNumber ? 'Edit Phone Number' : 'Add New Phone Number'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PhoneNumberForm data={editPhoneNumberDialog.phoneNumber} onSubmitCallback={onSubmitPhoneNumber} />
                </Modal.Body>
            </Modal>
            <ConfirmationDialog 
                show={deletePhoneNumberDialog.show}
                message="You are about to delete phone number. Are you sure?"
                handleConfirm={deletePhoneNumber} 
                handleCancel={() => setDeletePhoneNumberDialog({show: false})}
            />
            <Modal show={editAddressDialog.show} onHide={() => setEditAddressDialog({show: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>{editAddressDialog.address ? 'Edit Address' : 'Add New Address'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddressForm data={editAddressDialog.address} onSubmitCallback={onSubmitAddress} />
                </Modal.Body>
            </Modal>
            <ConfirmationDialog 
                show={deleteAddressDialog.show}
                message="You are about to delete address. Are you sure?"
                handleConfirm={deleteAddress} 
                handleCancel={() => setDeleteAddressDialog({show: false})}
            />
        </>
    )

}