import { useEffect, useState } from "react";

import { Loading, Socials, UserFullName, VCardPersonal } from "_components";
import { fetchWrapper } from "_helpers";
import { Subscribe } from "../lead";
import { Footer } from "./Footer";
import QRCode from "react-qr-code";
import { Modal } from "react-bootstrap";
import {BusinessCardUserBody} from "./BusinessCardUserBody";

export function Personal({ channelName, invitationCode, preview }) {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState();
    const [company, setCompany] = useState();
    const [phoneNumbersDialog, setPhoneNumbersDialog] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/personal/" + channelName + "/" + invitationCode).then(response => {
            setProfile(response.data);
            let profileAddresses = [...response.data.addresses]
            if (response.data.company.addresses.length > 0) {
                profileAddresses.push(...response.data.company.addresses);
            }
            setAddresses(profileAddresses);
            let profilePhoneNumbers = [...response.data.phoneNumbers];
            if (response.data.company.phoneNumbers.length > 0) {
                profilePhoneNumbers.push(...response.data.company.phoneNumbers)
            }
            setPhoneNumbers(profilePhoneNumbers);
            setCompany(response.data.company);
            setLoading(false);
        });
    }, [])

    return (
        <>
            {loading && <Loading />}
            {profile &&
                <>
                    <BusinessCardUserBody profile={profile} />
                    <div className="row justify-content-center mb-1 g-0" style={{ position: "relative" }}>
                        <div className="col-10 col-md-5 col-lg-4">
                            {profile.about && <p className="mb-0 mt-1" style={{ whiteSpace: "pre-line" }}>{profile.about}</p>}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center mb-4 g-0" style={{ position: "relative" }}>
                            <div className="col">
                                <Socials data={profile} />
                                <div className="row mt-3">
                                    <div className="col-6 text-center">
                                        <VCardPersonal profile={profile} company={company} phoneNumbers={phoneNumbers} addresses={addresses} />
                                    </div>
                                    <div className="col-6 text-center">
                                        <button className="btn btn-dark btn-10 float-start" onClick={() => setPhoneNumbersDialog(true)}>
                                            {"Call " + profile.firstName}
                                        </button>
                                    </div>
                                </div>
                                {preview === true ?
                                    <div className="row justify-content-center mt-2 mb-1 g-0" style={{ position: "relative" }}>
                                        <div className="col-10 col-md-5 col-lg-5">
                                            <div className="text-center mt-2">
                                                <QRCode
                                                    id={profile.id}
                                                    size={128}
                                                    style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                                    value={process.env.REACT_APP_BASE_URL + "/" + channelName + "/" + invitationCode}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row justify-content-center mt-2 mb-1 g-0" style={{ position: "relative" }}>
                                        <div className="col-9 col-md-5 col-lg-3">
                                            <Subscribe
                                                label={"Submit the form to join " + profile.firstName + "’s network "}
                                                welcomeMessage="Thanks for sharing."
                                                channelName={channelName}
                                                invitationCode={invitationCode}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <Modal show={phoneNumbersDialog} onHide={() => setPhoneNumbersDialog(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Phone numbers</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {phoneNumbers.length > 0 &&
                                phoneNumbers.map(phoneNumber =>
                                    <div key={phoneNumber.value}>
                                        <strong>{phoneNumber.title}:</strong> <a className='mb-0' href={"tel:" + phoneNumber.value}>{phoneNumber.value}</a>
                                    </div>
                                )
                            }
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )

}