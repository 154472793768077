import normalizeUrl from 'normalize-url';

export function Socials({data, label}) {

    return (
        <>
            {(data.linkedin || data.twitter || data.facebook || data.instagram || data.tiktok) &&
                <div className='row text-center'>
                    <div className='col'>
                        {label &&<p className="mb-1"><strong>{label}</strong></p>}
                        {data.linkedin && <a href={normalizeUrl(data.linkedin)} className="me-2"><img src="/linkedin.svg" alt="linkedin" width={24} height={24}/></a>}
                        {data.twitter && <a href={normalizeUrl(data.twitter)} className="me-2"><img src="/twitter-tile.svg" alt="linkedin" width={24} height={24}/></a>}
                        {data.facebook && <a href={normalizeUrl(data.facebook)} className="me-2"><img src="/facebook-tile.svg" alt="facebook" width={24} height={24}/></a>}
                        {data.instagram && <a href={normalizeUrl(data.instagram)} className="me-2"><img src="/instagram-icon.svg" alt="instagram" width={24} height={24}/></a>}
                        {data.tiktok && <a href={normalizeUrl(data.tiktok)} className="me-1"><img src="/tiktok.svg" alt="tiktok" width={24} height={24}/></a>}
                    </div>
                </div>
            }
        </>
    )

}