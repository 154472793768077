import { constants, fetchWrapper } from "_helpers";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SubmitBar, TextInputField } from "_components";


export function MeetingInvite({ offer, selectedTemplateId }) {
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        callToActionURL: Yup.string().required('URL is required field').matches(constants.websiteRegExp, { message: "Calendar URL be valid!", excludeEmptyString: true }),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (offer !== undefined) {
            setValue('title', offer.title);
            setValue('description', offer.description);
            setValue('callToActionURL', offer.callToActionURL);
        }
    }, [offer])

    const onSubmit = async () => {
        const formData = new FormData();
        if (offer) {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "callToActionURL": getValues('callToActionURL') }))
            fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id, formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        } else {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "templateId": selectedTemplateId, "callToActionURL": getValues('callToActionURL') }))
            fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer", formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        }
    }

    return (
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength="100" />
                    <TextInputField register={register} label="Message" name="description" error={errors.description} textarea={true} />
                    <TextInputField register={register} label="Calendar URL" name="callToActionURL" error={errors.callToActionURL} />
                    <SubmitBar isSubmitting={isSubmitting} labelConfirm="Save" cancelLink="/offers" />
                </form>
            </div>
        </div>
    )


}