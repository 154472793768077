import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper, constants} from '_helpers';
import { PasswordInputField, PhoneNumberInputField, SubmitBar, TextInputField } from "_components";
import {PhoneNumberVerification} from "../_components/PhoneNumberVerification";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export {
    VendorRegistration
}

function VendorRegistration() {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState(null);
    let [searchParams] = useSearchParams()
    const code = searchParams.get("code")
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(false);
    const [showConfirmationCodeDialog, setShowConfirmationCodeDialog] = useState(false)

    // form validation rules
    const validationSchema = Yup.object({
        user: Yup.object(
            {
                firstName: Yup.string().required('Name is required field'),
                lastName: Yup.string().required('Surname is required field'),
                email: Yup.string().required('Username is required field').email("Please enter a valid email address"),
                password: Yup.string().required('Password is required field').min(8, "Password must be at least 8 characters"),
                confirmPassword: Yup.string().oneOf(
                    [
                        Yup.ref('password'), null
                    ],
                    'Passwords must match'
                ),
                phoneNumber: Yup.object({
                    value: Yup.string().matches(constants.phoneRegExp, 'Phone number is not valid'),
                })
            }
        )
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        setValue,
        setError,
        clearErrors,
        getValues,
        setFocus
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;


    useEffect(() => {
        if (code) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/register/vendor?code=" + code).then(response => {
                setValue("user.email", response.data.user?.email);
                setCompanyName(response.data.company?.name);
            });
        } else {
            navigate("/login");
        }
        setFocus("user.firstName");
    }, [code])

    const onSubmit = (data => {
        const values = getValues();
        console.log(values);
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/register/vendor?code=" + code, values).then(response => {
            console.log(response.data);
            if (response.status === 530) {
                setError("user.email", { type: 'custom', message: "Email has been already registered." }, {shouldFocus: true});
            } else if (response.status === 536) {
                setError("user.phoneNumber.value", {
                    type: 'custom', 
                    message: "Phone number is not valid! Please try again" 
                }, {shouldFocus: true});
            } else {
                navigate("/login");
            }
        });
    })

    const requestPhoneVerification = () => {
        if (getValues("user.phoneNumber.value") !== undefined) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/public/phone-number/verification",
                {"value": getValues("user.phoneNumber.value")}).then(response => {
                if (response.status === 536) {
                    setError("user.phoneNumber.value", {
                        type: 'custom',
                        message: "Phone number is not valid! Please try again"
                    }, {shouldFocus: true});
                }
                if (response.status === 540) {
                    setShowConfirmationCodeDialog(true)
                }
            });
        } else {
            setError("user.phoneNumber.value", {
                type: 'custom',
                message: "Phone number is not valid! Please try again"
            }, {shouldFocus: true});
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col">
                    <h1 className="mt-4 text-center">Sign Up</h1>
                    <p className="text-center text-uppercase">Vendor for {companyName && 'company ' + companyName}</p>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center mb-4">
                <div className="col-lg-8 col-xl-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField name="user.firstName" label="Name" error={errors.user?.firstName} register={register} maxLength={100}/>
                        <TextInputField name="user.lastName" label="Surname" error={errors.user?.lastName} register={register} maxLength={100}/>
                        <TextInputField name="user.occupation" label="Occupation" error={errors.user?.occupation} register={register} maxLength={100}/>
                        <TextInputField name="user.email" label="E-mail" error={errors.user?.email} register={register} maxLength={100}/>
                        <PasswordInputField register={register} name="user.password" error={errors.user?.password} />
                        <PasswordInputField register={register} name="user.confirmPassword" error={errors.user?.confirmPassword} />
                        <div className="row">
                            <div className="col-9">
                                <PhoneNumberInputField label="Mobile phone" name="user.phoneNumber.value" control={control} phoneNumberChange={() => setPhoneNumberVerified(false)} />
                            </div>
                            <div className="col-3">
                                {phoneNumberVerified === true ?
                                    <><FontAwesomeIcon icon={faCheck} className={"text-success"} style={{marginTop: "2.30rem"}} /> Verified</>
                                    :
                                    <a className="btn btn-light" onClick={requestPhoneVerification} style={{marginTop: "1.75rem"}}>Verify</a>
                                }
                            </div>
                        </div>
                        <p className={"text-sm text-secondary"}><small>A mobile phone number is a mandatory field, and it must be verified. You’ll get a 4-digit code after clicking the Verify button.</small></p>
                        <div className='mt-4 mb-4'></div>
                        <SubmitBar labelConfirm="Sign up" cancelLink={"/"} disabled={!phoneNumberVerified} isSubmitting={isSubmitting}/>
                    </form>
                </div>
            </div>
            <PhoneNumberVerification
                show={showConfirmationCodeDialog}
                onHide={() => setShowConfirmationCodeDialog(false)}
                getPhoneNumberValue={() => getValues('user.phoneNumber.value')}
                callback={(confirmationCode, status, data) => {
                    clearErrors("user.phoneNumber.value")
                    setPhoneNumberVerified(true)
                }}
            />
        </div>
    )


}
