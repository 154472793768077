import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {constants, fetchWrapper} from "_helpers";
import {TextInputField} from "./base/TextInputField";
import {SubmitBar} from "./base/SubmitBar";
import {useEffect, useState} from "react";
import {PhoneNumberInputField} from "./PhoneNumberInputField";
import {PhoneNumberVerification} from "./PhoneNumberVerification";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export function PhoneNumberForm({onSubmitCallback, data, showDefaultResponseNumber}) {
    const [phoneNumberVerified, setPhoneNumberVerified] = useState(data !== undefined && data?.id !== undefined ? true : false);
    const [showConfirmationCodeDialog, setShowConfirmationCodeDialog] = useState(false)

    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        value: Yup.string().matches(constants.phoneRegExp, 'Phone number is not valid')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        setError,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        clearErrors();
        resetField('title');
        resetField('value');
        if (data) {
            if (data.id) {
                setValue('id', data.id);
            }
            setValue('title', data.title);
            setValue('value', data.value);
            if (data.key) {
                setValue('key', data.key);
            }
            if (data.defaultResponseNumber) {
                setValue('defaultResponseNumber', data.defaultResponseNumber);
            }
        }
    }, [])

    const onSubmit = () => {
        let values = getValues();
        onSubmitCallback(values);
    }

    const requestPhoneVerification = () => {
        if (getValues('value') !== undefined) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/public/phone-number/verification", {"value": getValues('value')}).then(response => {
                if (response.status === 536) {
                    setError("value", {
                        type: 'custom',
                        message: "Phone number is not valid! Please try again"
                    }, {shouldFocus: true});
                }
                if (response.status === 540) {
                    setShowConfirmationCodeDialog(true)
                }
            });
        } else {
            setError("value", {
                type: 'custom',
                message: "Phone number is not valid! Please try again"
            }, {shouldFocus: true});
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInputField register={register} label="Name" name="title" error={errors.title} maxLength={100}/>
                <div className="row">
                    <div className="col-9">
                        <PhoneNumberInputField label="Mobile phone" name="value" control={control}
                                               errorMessage={errors.value}
                                               phoneNumberChange={() => setPhoneNumberVerified(false)}/>
                    </div>
                    <div className="col-3">
                        {phoneNumberVerified === true ?
                            <><FontAwesomeIcon icon={faCheck} className={"text-success"}
                                               style={{marginTop: "2.30rem"}}/> Verified</>
                            :
                            <a className="btn btn-light" onClick={requestPhoneVerification}
                               style={{marginTop: "1.75rem"}}>Verify</a>
                        }
                    </div>
                </div>
                {showDefaultResponseNumber === true &&
                    <div className="form-check">
                        <input type="checkbox" className="form-check-input"
                               id="default-response-number" {...register('defaultResponseNumber')}
                        />
                        <label className="form-check-label" htmlFor="default-response-number">Default response
                            number</label>
                    </div>
                }
                <SubmitBar labelConfirm="Save" disabled={!phoneNumberVerified} isSubmitting={isSubmitting}
                           showCancel={false}/>
            </form>
            <PhoneNumberVerification
                show={showConfirmationCodeDialog}
                onHide={() => setShowConfirmationCodeDialog(false)}
                getPhoneNumberValue={() => getValues('value')}
                callback={(confirmationCode, status, data) => {
                    setPhoneNumberVerified(true)
                }}
            />
        </>
    )

}