import { Loading } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function ManageAccount() {
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState(null);
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        loadAccount();
    }, [])

    const loadAccount = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/accountowner/account").then(response => {
            setAccount(response.data);
            setLoading(false);
        });
    }

    const saveAccount = () => {
        setSubmitting(true);
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/accountowner/account/type", {"value": selectedAccountType}).then(response => {
            setAccount(response.data);
            setSubmitting(false);
        });
    }

    return (
        <>
            {loading === true ? <Loading />
            :
            <>
                <div className="row justify-content-center">
                    <div className="col">
                        <h3 className="mt-4 text-center">Manage Account</h3>
                    </div>
                </div>
                <div className='mt-4 mb-4'></div>
                <div className="row justify-content-center">
                    <div className="col-lg-11 col-xl-4">
                        <p className="mb-0">Account ID</p>
                        <h4>{account.id}</h4>
                        <div className="form-group mb-4">
                            <label htmlFor="accountType" className="mb-1">Account type</label>
                            <select id="accountType" className={`form-select`} onChange={(e) => setSelectedAccountType(e.target.value)}>
                                <option key="PERSONAL" selected={account.accountType === 'PERSONAL' ? true : false} value="PERSONAL">{t("AccountType.Personal")}</option>
                                <option key="PROFESSIONAL" selected={account.accountType === 'PROFESSIONAL' ? true : false} value="PROFESSIONAL">{t("AccountType.PROFESSIONAL")}</option>
                            </select>
                        </div>
                        <div className="d-grid gap-2 mb-3">
                            <button className="btn btn-lg btn-dark mt-3 rounded-pill" onClick={saveAccount}>
                                {submitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )

}