
export function Address({address}) {

    return (
        <div key={address.id}>
            {address.title && <p className="mb-0"><strong>{address.title}:</strong></p>}
            {address.address1}
            {address.address2 && <p className='mb-0'>{address.address2}</p>}
            <p className='mb-0'>{address.city}, {address.state} {address.postalCode}</p>
            <p className='mb-2'>{address.countryName}</p>
        </div>
    )

}