import { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

import { fetchWrapper, history } from '_helpers';
import { SubmitBar, TextInputField } from '_components';

export { PasswordForget };

function PasswordForget() {
    const navigate = useNavigate();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Email is required').email("Please enter a valid email address")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setFocus, getValues, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        // redirect to home if already logged in
        setFocus("username");
        if (authUser) history.navigate('/');
    }, []);


    function onSubmit() {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/password/forget", getValues()).then(response => {
            if (response.status === 532) {
                setError("username", { type: 'custom', message: "Email has not been registered." }, {shouldFocus: true});
            } else {
                navigate("/login");
            }
        });
    }

    return (
        <>
            <div className="row g-0 justify-content-center">
                <div className="col-sm-6">
                    <div className="d-none d-lg-block d-md-block">
                        <img src="/login-optimized.png" alt="logo" className='img-fluid float-end' />
                    </div>
                    <div className='d-block d-sm-none' >
                        <img src="/login-narrow.png" alt="logo" className='img-fluid' />
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className='row g-0'>
                        <div className='col-1 d-none d-lg-block'>&nbsp;</div>
                        <div className='col'>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <h3 className='text-center mt-4'><strong>Request Password Reset</strong></h3>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <div className='container'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <TextInputField label="Email" name="username" register={register} error={errors.username} />
                                    <SubmitBar labelConfirm="Send Request" cancelLink="/" isSubmitting={isSubmitting} />
                                    {authError &&
                                        <div className="alert alert-danger mt-3 mb-0">{authError.message}</div>
                                    }
                                </form>
                            </div>
                        </div>
                        <div className='col-3 d-none d-lg-block'>&nbsp;</div>
                    </div>
                </div>
                <div className="col"></div>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center py-3" style={{backgroundColor: "#F2F3FA"}}>
                <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2024. All rights reserved.</p>
                <ul className="nav justify-content-center">
                    <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                    <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
                </ul>
            </div>
        </>
    )
}
