import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "_components";
import { fetchWrapper } from "_helpers";

export function EmailVerify() {
    const {code} = useParams();
    const [loading, setLoading] = useState(true);
    const [showSuccessful, setShowSuccessful] = useState(false);
    const [showUnsuccessful, setShowUnsuccessful] = useState(false);

    useEffect(() => {
        verifyEmail();
    }, [code])

    function verifyEmail() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/email-verify/" + code).then(response => {
            if (response.status === 539) {
                setShowUnsuccessful(true);
                setShowSuccessful(false);
            } else {
                setShowSuccessful(true);
            }
            setLoading(false);
        });
    }

    return (
        <div className="row">
            <div className="col-sm-6 g-0 text-center">
                <img src="/login.png" alt="logo" className='img-fluid' />
            </div>
            <div className="col-sm-6 g-5 text-center">
            {loading === true ? <Loading/> :
                <>
                {showSuccessful === true &&
                    <h3 className='text-center mt-5'>You have successfully verified email address.</h3>
                }
                {showUnsuccessful === true &&
                    <>
                        <h3 className='text-center mt-5'>
                            The token has expired. 
                        </h3>
                        <h3 className='text-center mt-5'>
                            Go to your account to <a href={process.env.REACT_APP_BASE_URL + "/m"}>manage the subscription</a>. 
                        </h3>
                    </>
                }
                </>
            }
            </div>
        </div>
    )

}