import { useEffect } from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextInputField } from "./base/TextInputField";
import { SubmitBar } from "./base/SubmitBar";

export function EmailForm({onSubmitCallback, data}) {

    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        value: Yup.string().required('Email is required').email("Please enter a valid email address"),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        clearErrors();
        resetField('title');
        resetField('value');
        if (data) {
            if (data.id) {
                setValue('id', data.id);
            }
            setValue('title', data.title);
            setValue('value', data.value);
            if (data.key) {
                setValue('key', data.key);
            }
        }
    }, [])

    const onSubmit = () => {
        let values = getValues();
        onSubmitCallback(values);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputField register={register} label="Name" name="title" error={errors.title} maxLength={100} />
            <TextInputField register={register} label="Email" name="value" error={errors.value} maxLength="50" />
            <SubmitBar labelConfirm="Save" isSubmitting={isSubmitting} showCancel={false} />
        </form>
    )

}