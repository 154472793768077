import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { SubmitBar, TextInputField } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect } from "react";

export function AudienceForm({audienceId, callback}) {

    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        if (audienceId != null) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audience/" + audienceId).then(response => {
                setValue('title', response.data.title);
                setValue('description', response.data.description);
            });
        }
    }, [audienceId]);

    function onSubmit() {
        if (audienceId != null) {
            fetchWrapper.put(process.env.REACT_APP_API_URL + "/vendor/audience/" + audienceId, getValues()).then(response => {
                callback();
            });
        } else {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/audience", getValues()).then(response => {
                callback();
            });
        }
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField
                            register={register}
                            label="Title"
                            name="title"
                            error={errors.title}
                            maxLength={100}
                        />
                        <TextInputField
                            register={register}
                            label="Description"
                            name="description"
                            error={errors.description}
                            textarea={true}
                        />
                        <SubmitBar showCancel={false} labelConfirm="Save" isSubmitting={isSubmitting}/>
                    </form>
                </div>
            </div>
        </>
    )

}