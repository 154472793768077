import { Loading, NoResults, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MessageReply } from "./MessageReply";

export function Messages({ channelName, code }) {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([])
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [replyMessageDialog, setReplyMessageDialog] = useState({
        messageCode: null,
        show: false
    });

    useEffect(() => {
        loadMessages()
    }, [channelName, code, page])

    const loadMessages = () => {
        setLoading(true)
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + code + "/messages", { "pageSize": 5, "pageNumber": page }).then(response => {
            const messages = response.data.content;
            setMessages(messages)
            setTotalElements(response.data.totalElements)
            setTotalPages(response.data.totalPages);
            setLoading(false);
            for (let index = 0; index < messages.length; index++) {
                const message = messages[index];
                if (message.leadMessageType === 'INCOMING' && message.status === 'SENT') {
                    seenMessage(message.code);
                }
            }
        });
    }

    const seenMessage = (code) => {
        fetchWrapper.patch(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + code + "/message/seen").then(response => {

        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    const newMessageCallback = () => {
        setReplyMessageDialog({ show: false, messageCode: null });
        loadMessages();
    }

    return (
        <>
            <div className="row" style={{ backgroundColor: "#e2e2e2", borderRadius: "1.5rem" }} id="messages-section">
                <div className="col-10">
                    <h6 className="mt-1 mb-1"><strong>Messages{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>
                </div>
                <div className="col-2">
                    <a onClick={() => { setReplyMessageDialog({ show: true, messageCode: null }) }} className="float-end cursor" ><img src="/circle-plus-regular.svg" width={20} alt="message-add" /></a>
                </div>
            </div>
            {(loading === false && messages.length === 0) &&
                <div className="row justify-content-center">
                    <div className="col">
                        <NoResults />
                    </div>
                </div>
            }
            {loading === true &&
                <div className="row justify-content-center">
                    <div className="col-8">
                        <Loading />
                    </div>
                </div>
            }
            {loading === false && messages.length !== 0 &&
                <>
                    {messages.map((item, index) =>
                        <div className="row" key={item.code}>
                            {item.leadMessageType === 'OUTGOING' && <div className="col-4">&nbsp;</div>}
                            <div className="col-8">
                                <div className={`${item.leadMessageType === 'INCOMING' ? `speech-bubble aleft` : `speech-bubble aright`} pbottom mt-2 ${item.status === 'SENT' ? `sent` : ``}`} key={"message-" + index} >
                                    <div className="title" style={{ whiteSpace: "pre-line" }}>{item.content}</div>
                                    {item.offerCode &&
                                        <div className="row">
                                            <div className="col">
                                                <Link to={"/" + channelName + "/lead/" + code + "/offer/" + item.offerCode} className="float-end cursor fs-6" style={{ fontWeight: 600 }} >{item.offerTitle} &gt;&gt;</Link>
                                            </div>
                                        </div>
                                    }
                                    <p className="mb-0 fs-6">
                                        {item.createdByFirstName !== null ?
                                            <>{item.createdByFirstName} {item.createdByLastName}</>
                                            :
                                            <>{item.leadFirstName} {item.leadLastName}</>
                                        } on {moment(item.creationDate).format('MMM Do YYYY HH:mm')}
                                    </p>
                                    {item.leadMessageType === 'INCOMING' &&
                                        <div className="row">
                                            <div className="col">
                                                <a className="float-end cursor fs-6" onClick={() => setReplyMessageDialog({ show: true, messageCode: item.code })} style={{ fontWeight: 600 }} >Reply</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    }
                </>
            }
            <Modal show={replyMessageDialog.show} onHide={() => setReplyMessageDialog({ show: false, messageCode: null })}>
                <Modal.Header closeButton>
                    <Modal.Title>New Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MessageReply
                        channelName={channelName}
                        leadCode={code}
                        code={replyMessageDialog.messageCode}
                        callback={newMessageCallback}
                    />
                </Modal.Body>
            </Modal>
            <div className="row justify-content-center">
                <div className="col">
                    <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
                </div>
            </div>
        </>
    )

}