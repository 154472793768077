import React, { useRef, useEffect, useState } from 'react';

export { TextAreaField };

function TextAreaField({ register, error, label, setValue, name, maxLines = 8, rows = 4 }) {
    const textareaRef = useRef(null);
    const [maxCharsPerLine, setMaxCharsPerLine] = useState(40);
    
    useEffect(() => {
        const calculateMaxChars = () => {
            if (textareaRef.current) {
                const width = textareaRef.current.clientWidth;
                // Assuming average character width is about 8px (adjust as necessary)
                const averageCharWidth = 10; 
                setMaxCharsPerLine(Math.floor(width / averageCharWidth));
            }
        };
        
        calculateMaxChars();
        window.addEventListener('resize', calculateMaxChars);
        
        return () => {
            window.removeEventListener('resize', calculateMaxChars);
        };
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        const lines = value.split('\n');
        if (lines.length > maxLines) {
            const newValue = lines.slice(0, maxLines).join('\n');
            setValue(name, newValue);
            return;
        }
        const limitedLines = lines.map(line => line.slice(0, maxCharsPerLine));
        setValue(name, limitedLines.join('\n'));
    };

    return (
        <div className="form-group mb-2">
            <label className="mb-0">{label}</label>
            <textarea
                ref={textareaRef}
                {...register(name, { onChange: handleChange })}
                rows={rows}
                className={`form-control ${error ? 'is-invalid' : ''}`}
            />
            <div className="invalid-feedback">{error?.message}</div>
        </div>
    );
}
