import { Loading } from "_components";
import { constants, fetchWrapper } from "_helpers";
import { EventOfferLandingPage, MeetingInviteLandingPage, RealEstateOfferLandingPage, ScanAndSubscribeLandingPage } from "page/offer/templates";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OfferState } from "./OfferState";

export function Offer() {
    const { channelName, leadCode, offerCode } = useParams();
    const [loading, setLoading] = useState(true);
    const [offer, setOffer] = useState(null);

    useEffect(() => {
        loadOffer();
    }, [])

    const loadOffer = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/" + channelName + "/offer/" + offerCode).then(response => {
            if (response.status === 200) {
                setOffer(response.data);
                setOfferOpened();
                setLoading(false);
            }
        });
    }

    const setOfferOpened = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + leadCode + "/offer/" + offerCode + "/open").then(response => {
            if (response.status === 200) {
                
            }
        });
    }

    function renderSwitch(param) {
        switch (param) {
            case constants.offerRealEstateTemplateId:
                return <RealEstateOfferLandingPage
                    offer={offer}
                    channelName={channelName}
                >
                    <OfferState
                        channelName={channelName}
                        leadCode={leadCode}
                        offerCode={offerCode}
                    />
                </RealEstateOfferLandingPage>;
            case constants.offerEventTemplateId:
                return <EventOfferLandingPage
                    offer={offer}
                    channelName={channelName}
                ><OfferState
                        channelName={channelName}
                        leadCode={leadCode}
                        offerCode={offerCode}
                    />
                </EventOfferLandingPage>;
            case constants.offerMeetingIvinteTemplateId:
                return <MeetingInviteLandingPage
                    offer={offer}
                    channelName={channelName}
                >
                </MeetingInviteLandingPage>;
            case constants.offerScanAndSubscribeTemplateId:
                    return <ScanAndSubscribeLandingPage
                        offer={offer}
                        channelName={channelName}
                    >
                    </ScanAndSubscribeLandingPage>;
            default:
                return null;
        }
    }

    return (
        <>
            {loading === false ?
                renderSwitch(offer.templateId)
                : <Loading />}
        </>
    )

}