import React from 'react';

export {
    NoResults
}

function NoResults() {
    return (
        <p className="lead mt-2 text-center">No results found.</p>
    )
}