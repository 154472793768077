import Modal from 'react-bootstrap/Modal';
import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil, faX, faPlus} from '@fortawesome/free-solid-svg-icons'

import { SubmitBar, TextInputField } from "_components";
import { fetchWrapper } from '_helpers';
import { CountriesSelectField } from "_components/CountriesSelectField";
import { ConfirmationDialog } from '_components';

export function Addresses({description, loadUrl, editUrl, editAllowed}) {
    const [addresses, setAddresses] = useState([]);
    const [show, setShow] = useState(false);
    const [addressId, setAddressId] = useState(null);
    const [countryId, setCountryId] = useState(null);
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        id: null
    });
    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        address1: Yup.string().required('Address 1 is required field'),
        postalCode: Yup.string().required('Zip is required field'),
        city: Yup.string().required('City is required field'),
        countryId: Yup.string().required('Country is required field'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const handleClose = () => setShow(false);

    function newAddress() {
        resetField("title");
        resetField("address1");
        resetField("address2");
        resetField("postalCode");
        resetField("state");
        resetField("city");
        clearErrors();
        setAddressId(null);
        setCountryId(null);
        setShow(true);
    }
    
    function editAddress(addressId) {
        fetchWrapper.get(process.env.REACT_APP_API_URL + editUrl + "/" + addressId).then(response => {
            clearErrors();
            setValue("title", response.data.title);
            setValue("address1", response.data.address1);
            setValue("address2", response.data.address2);
            setValue("postalCode", response.data.postalCode);
            setValue("state", response.data.state);
            setValue("city", response.data.city);
            setValue("countryId", response.data.countryId);
            setCountryId(response.data.countryId);
            setAddressId(response.data.id);
            setShow(true);
        });
    }

    function onSubmit() {
        if (addressId) {
            fetchWrapper.put(process.env.REACT_APP_API_URL + editUrl + "/" + addressId, getValues()).then(response => {
                setShow(false);
                loadAddresses();
            });
        } else {
            fetchWrapper.post(process.env.REACT_APP_API_URL + editUrl, getValues()).then(response => {
                setShow(false);
                loadAddresses();
            });
        }
    };

    function deleteAddress() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + editUrl + "/" + confirmationDialog.id).then(response => {
            loadAddresses();
            setConfirmationDialog({show: false})
        });
    }

    function loadAddresses() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + loadUrl).then(response => {
            setAddresses(response.data);
        });
    }

    useEffect(() => {
        loadAddresses();
    }, [])

    return (
        <>
        <div className="row mb-3">
            <p className='mb-0'>Addresses{editAllowed === true && <button type='button' className="btn btn-sm btn-primary ms-2 rounded-circle" href="#" onClick={newAddress}><FontAwesomeIcon icon={faPlus}/></button>}</p>
            <p className="text-secondary mb-0 fs-6">{description}</p>
            {addresses.map(address => 
                <div key={address.id}>
                    {address.address1}
                    {editAllowed === true ?
                        <>
                            <button type='button' className="btn btn-link btn-sm" onClick={() => editAddress(address.id)}><FontAwesomeIcon icon={faPencil}/></button>
                            {addresses.length > 1 && <button type='button' className="btn btn-link btn-sm text-danger" onClick={() => setConfirmationDialog({show:true, id: address.id})}><FontAwesomeIcon icon={faX}/></button>}
                        </>
                    : null}
                    {address.address2 && <p className='mb-0'>{address.address2}</p>}
                    <p className='mb-0'>{address.city}, {address.state} {address.postalCode}</p>
                    <p className='mb-2'>{address.countryName}</p>
                </div>
            )}
        </div>
        {editAllowed === true &&
        <> 
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{addressId ? 'Edit Address' : 'Add New Address'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength={100} />
                        <TextInputField 
                            register={register}
                            label="Address 1"
                            name="address1"
                            error={errors.address1}
                            maxLength={100}
                        />
                        <TextInputField 
                            register={register}
                            label="Address 2"
                            name="address2"
                            error={errors.address2}
                            maxLength={100}
                        />
                        
                        <CountriesSelectField
                            register={register}
                            setValue={setValue}
                            name="countryId"
                            error={errors.countryId}
                            selected={countryId}
                        />

                        <TextInputField 
                            register={register}
                            label="State"
                            name="state"
                            error={errors.state}
                            maxLength={100}
                        />
                        <TextInputField 
                            register={register}
                            label="City"
                            name="city"
                            error={errors.city}
                            maxLength={100}
                        />
                        <TextInputField 
                            register={register}
                            label="Zip"
                            name="postalCode"
                            error={errors.postalCode}
                            maxLength={100}
                        />
                        <SubmitBar labelConfirm="Save" showCancel={false} isSubmitting={isSubmitting}/>
                    </form>
                </div>
            </div>
        </Modal.Body>
        </Modal>
        {confirmationDialog.show && 
            <ConfirmationDialog 
                show={confirmationDialog.show}
                handleConfirm={deleteAddress} 
                handleCancel={() => setConfirmationDialog({show: false})}
            />
        }
        </>
        }
        </>
    )

}