import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { fetchWrapper } from "_helpers";
import { ConfirmationCode, Loading } from "_components";

export function ResolveConflict({conflictValues, callback, channelName, invitationCode}) {
    const [loading, setLoading] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState(null);
    const [lead, setLead] = useState(null);
    const [newName, setNewName] = useState(null);
    const [newLastName, setNewLastName] = useState(null);
    const [newEmail, setNewEmail] = useState(null);

    const {
        handleSubmit,
        formState,
        getValues,
        setValue,
        resetField
    } = useForm();
    const {isSubmitting} = formState;

    useEffect(() => {
        setNewName(conflictValues('firstName'));
        setNewLastName(conflictValues('lastName'));
        if (conflictValues('email') !== "") {
            setNewEmail(conflictValues('email'));
        }
    }, [])

    function onSubmit() {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/subscribe/" + channelName + "/" + invitationCode + "/" + confirmationCode + "/resolve-conflict", getValues()).then(response => {
            callback(response.status, response.data);
        });
    }

    const handleShowSubcriptionConflict = (confirmationCode, status, data) => {
        setLoading(true);
        setConfirmationCode(confirmationCode);
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/subscribe/" + confirmationCode + "/show-conflict").then(response => {
            const lead = response.data;
            setLead(lead);
            setValue('firstName', lead.firstName);
            setValue('lastName', lead.lastName);
            if (lead.email !== "") {
                setValue('email', lead.email);
            }
                setLoading(false);
        });
    }

    const handleNewEmail = () => {
        if (newEmail !== "") {
            setValue('email', newEmail)
        } else {
            resetField('email');
        }
    }

    return (
        <>
        <p>It looks like you already have an account on ABC. Please revise and confirm your contact details.</p>
        {lead === null ?
            <ConfirmationCode
                url={"/subscribe/" + channelName + "/" + invitationCode}
                callback={handleShowSubcriptionConflict}
            />
            :
            <>
                {loading === true ? <Loading/> :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {lead.firstName !== newName &&
                            <>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="nameRadio" id="existingNameRadio"
                                        onChange={() => setValue('firstName', lead.firstName)} defaultChecked
                                    />
                                    <label className="form-check-label" htmlFor="existingNameRadio">
                                        Use the existing first name: {lead.firstName}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="nameRadio" id="newNameRadio" 
                                        onChange={() => setValue('firstName', newName)}
                                    />
                                    <label className="form-check-label" htmlFor="newNameRadio">
                                        Use new first name: {newName}
                                    </label>
                                </div>
                            </>
                        }
                        {lead.lastName !== newLastName &&
                            <>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="lastNameRadio" id="existingLastNameRadio"
                                        onChange={() => setValue('lastName', lead.lastName)} defaultChecked
                                    />
                                    <label className="form-check-label" htmlFor="existingLastNameRadio">
                                        Use the existing last name: {lead.lastName}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="lastNameRadio" id="newLastNameRadio" 
                                        onChange={() => setValue('lastName', newLastName)}
                                    />
                                    <label className="form-check-label" htmlFor="newLastNameRadio">
                                        Use new last name: {newLastName}
                                    </label>
                                </div>
                            </>
                        }
                        <div className="mb-3"></div>
                        {lead.email !== newEmail &&
                            <>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="emailRadio" id="existingEmailRadio"
                                        onChange={() => setValue('email', lead.email)} defaultChecked
                                    />
                                    <label className="form-check-label" htmlFor="existingEmailRadio">
                                        Use the existing email: {lead.email}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="emailRadio" id="newEmailRadio" 
                                        onChange={handleNewEmail}
                                    />
                                    <label className="form-check-label" htmlFor="newEmailRadio">
                                        Use new email: {newEmail}
                                    </label>
                                </div>
                            </>
                        }
                        <div className="d-grid gap-2 mb-3">
                            <button disabled={isSubmitting} className="btn btn-lg btn-dark mt-3 rounded-pill">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Confirm selection
                            </button>
                        </div>
                    </form>
                }
            </>
        }
        </>
    )
}