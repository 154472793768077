import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react"

export function OffersActiveCount({accountId}) {
    const [count, setCount] = useState(null);

    useEffect(() => {
        loadCount()
    }, [])

    const loadCount = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/account/" + accountId + "/offers-count/active").then(response => {
            setCount(response.data);
        });
    }

    return (
        <>
            <h6>Active offers</h6>
            <h5>{count?.value}</h5>
        </>
    )

}