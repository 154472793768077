import { Address } from "./Address";

export function AddressesPreview({addresses}) {

    return (
        <>
        {addresses.length > 0 &&
            addresses.map(address => 
                <Address address={address}/>
            )
        }
        </>
    )

}