import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";

import { SubmitBar, TextInputField } from "_components";
import { fetchWrapper } from "_helpers";

export function MessageReply({channelName, leadCode, code, callback}) {

    // form validation rules
    const validationSchema = Yup.object({
        value: Yup.string().required('Message is a required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        getValues,
        resetField,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        clearErrors();
        resetField('content');
    }, []);

    function onSubmit() {
        let url = process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + leadCode + "/message/reply";
        if (code) {
            url = process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + leadCode + "/message/" + code + "/reply";
        }
        fetchWrapper.post(url, getValues()).then(response => {
            callback();
        });
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField
                            register={register}
                            label="Message"
                            name="value"
                            error={errors.value}
                            textarea={true}
                            maxLength="100"
                        />
                        <SubmitBar showCancel={false} labelConfirm="Send" isSubmitting={isSubmitting}/>
                    </form>
                </div>
            </div>
        </>
    )

}