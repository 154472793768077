import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationDialog, Loading } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import { Edit } from ".";

export function Tags({leadId}) {
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true);
    const [addTagMode, setAddTagMode] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState({
        show: false,
        id: null
    });

    useEffect(() => {
        loadTags()
    }, [leadId])

    const loadTags = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/tags").then(response => {
            if (response.status === 200) {
                setTags(response.data);
                setLoading(false);
            }
        });
    }

    const deleteTag = () => {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/tag/" + deleteDialog.id).then(response => {
            if (response.status === 200) {
                loadTags();
            }
            setDeleteDialog({show: false})
        });
    }

    return (
        <>
            {loading === true ? <Loading /> :
            <>
                {tags.map((item, index) =>
                    <span className={"badge badge-sm rounded-pill me-1 mb-1"} style={{fontSize: "0.38cm", fontWeight: "400", backgroundColor: item.backgroundColor}} key={item.value} >
                        {item.value} <a className="cursor" onClick={() => setDeleteDialog({show: true, id: item.id})} ><FontAwesomeIcon icon={faX}/></a>
                    </span>
                )}
            </>
            }
            {addTagMode === true ?
                <Edit 
                    leadId={leadId} 
                    callback={() => {setAddTagMode(false); loadTags();}}
                    cancelCallback={() => setAddTagMode(false)}
                    count={tags.length}
                />
                :
                <a className="cursor" onClick={() => setAddTagMode(true)}><img src="circle-plus-regular.svg" width={20} alt="tag-add"/></a>
            }
            <ConfirmationDialog 
                show={deleteDialog.show}
                message="You are about to delete this tag. Are you sure?"
                handleConfirm={deleteTag} 
                handleCancel={() => setDeleteDialog({show: false, id: null})}
            />
        </>
    )

}