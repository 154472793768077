import { Link } from "react-router-dom";

export function SubmitBar({disabled, isSubmitting, labelConfirm, showCancel, cancelLink}) {

    return (
        <div className="d-grid gap-2 mb-3">
            <button disabled={disabled !== undefined ? disabled : isSubmitting} className="btn btn-lg btn-dark mt-3 rounded-pill">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                {labelConfirm}
            </button>
            {showCancel !== undefined && showCancel === false ? null :
                <Link to={cancelLink} className="btn btn-light rounded-pill" >Cancel</Link>
            }
        </div>
    )

}