import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Messages } from "./Messages";
import { Offers } from "./Offers";
import { fetchWrapper } from "_helpers";
import { Loading } from "_components";
import { HeaderPhones } from "./profile/HeaderPhones";
import { HeaderEmails } from "./profile/HeaderEmails";
import { Footer } from "page/Footer";
import {BusinessCardUserBody} from "../page/BusinessCardUserBody";

export function Profile() {
    const { channelName, code } = useParams();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        loadProfile()
    }, [channelName, code])

    const loadProfile = () => {
        setLoading(true)
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + code + "/profile").then(response => {
            setProfile(response.data);
            setLoading(false);
        });
    }

    return (
        <>
            {loading === true ? <Loading />
                :
                <>
                    {profile.user &&
                        <BusinessCardUserBody profile={profile.user} />
                    }
                    <div className="row g-0 justify-content-center g-0" >
                        <div className="col-10 col-md-5 col-lg-4">
                            <div className="row g-0">
                                <div className="col-3 text-center">
                                    <HeaderPhones phoneNumbers={profile.phoneNumbers} />
                                </div>
                                <div className="col-3 text-center">
                                    <HeaderEmails emails={profile.emails} />
                                </div>
                                <div className="col-3 text-center">
                                    <a className="btn" href="#messages-section">
                                        <img src="/comments-sharp-regular.svg" alt="messages" width={24} />
                                    </a>
                                    <p className="mb-0">Message</p>
                                </div>
                                <div className="col-3 text-center">
                                    <Link to="/m" className="btn">
                                        <img src="/right-from-bracket-regular.svg" alt="unsubscribe" width={24} />
                                    </Link>
                                    <p className="mb-0">Unsubscribe</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-md-5 col-lg-5">
                        <Offers channelName={channelName} code={code} />
                        <Messages channelName={channelName} code={code} />
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}