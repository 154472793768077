import { store, authActions } from '_store';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    postFormData: postFormData(),
    plainFetch: plainFetch(),
    put: request('PUT'),
    delete: request('DELETE'),
    patch: request('PATCH'),
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: {
                Accept: "application/json",
                "Content-Type": 'application/json',
                ...authHeader(url)
            }
        };
        if (body) {
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

function postFormData() {
    return (url, body) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                Accept: "application/json",
                ...authHeader(url)
            }
        };
        if (body) {
            requestOptions.body = body;
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

function plainFetch() {
    return (url, body) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": 'application/json',
                ...authHeader(url)
            }
        };
        if (body) {
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const token = authToken();
    const isLoggedIn = !!token;
    const isApiUrl = url.startsWith(process.env.REACT_APP_API_URL);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

function authToken() {
    return store.getState().auth.user?.token;
}

function handleResponse(response) {
    return response.text().then(text => {
        if (!response.ok) {
            if ([401, 403].includes(response.status) && authToken()) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                const logout = () => store.dispatch(authActions.logout());
                logout();
            }
            console.log("Error: " + response.status);
            if (response.status <= 500) {
                return Promise.reject(response);
            }
        }
        try {
            const data = text && JSON.parse(text);
            return new Promise((resolve) => {
                resolve({data: data, status: response.status})
            })
        } catch (e) {
            return response;
        }
    });
}