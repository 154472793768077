import { useState } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCameraAlt} from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'react-bootstrap'
import { fetchWrapper } from '_helpers';
import { Logo, ConfirmationDialog } from '_components';
import { ImageUpload } from './ImageUpload';

export function Image(props) {
    const [show, setShow] = useState(false);
    const [profile, setProfile] = useState(props.profile);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    function handleUpload(response) {
        setProfile(response);
        setShow(false);
    }

    function deleteImage() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/profile/image").then(response => {
            setProfile(response.data);
            setShowConfirmationDialog(false);
        });
    }

    return(
        <>
        <Logo 
            logo={profile.profileImage} 
            avatar={profile.firstName + " " + profile.lastName} 
            path="/uploads/user"
            handleDelete={() => setShowConfirmationDialog(true)}
            deleteAllowed={true}
        />
        <br/><button type='button' className='btn btn-link' onClick={() => setShow(true)}><FontAwesomeIcon icon={faCameraAlt}/></button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Upload Profile Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ImageUpload onUploadFinished={handleUpload}/>
            </Modal.Body>
        </Modal>
        {showConfirmationDialog && 
            <ConfirmationDialog 
                show={showConfirmationDialog}
                handleConfirm={deleteImage} 
                handleCancel={() => setShowConfirmationDialog(false)}
            />
        }
        </>
    )

}