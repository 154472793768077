

export function BusinessCardUserBody({profile}) {

    return (
        <>
            <div className="row justify-content-center g-0 business-card-top" >
                <div className="col-12" >
                    <img src="/business_card_background.svg" alt="business_card_background" style={{ width: "100%", height: "auto", display: "block" }} />
                </div>
            </div>
            <div className="row justify-content-center business-card-body g-0" >
                <div className="col-10 col-md-5 col-lg-4">
                    <div className="row business-card-body-content" style={{ backgroundColor: "#145da0", color: "white", borderRadius: "1.5rem" }}>
                        <div className="col">
                            {profile.profileImage &&
                                <div className="" style={{ maxHeight: "13rem" }}>
                                    <img src={"/uploads/user/" + profile.profileImage}
                                         alt={profile.profileImage}
                                         className='float-start profile-pic me-3'
                                    />
                                </div>
                            }
                            <h3 className="ms-4 mt-3 mb-0 business-card-lastname" >{profile.firstName}</h3>
                            <h3 className="ms-4 mt-0 business-card-lastname">{profile.lastName}</h3>
                            {profile.occupation && <h4 className="mt-2 ms-4">{profile.occupation}</h4>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}