import {useState, useEffect} from "react";
import {useSelector} from 'react-redux';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Modal from 'react-bootstrap/Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil, faX, faPlus} from '@fortawesome/free-solid-svg-icons'

import { fetchWrapper } from '_helpers';
import { TextInputField, ConfirmationDialog } from "_components";

export {
    Emails
}

function Emails() {
    const authUser = useSelector(x => x.auth.user);
    const [emails, setEmails] = useState([]);
    const [show, setShow] = useState(false);
    const [emailId, setEmailId] = useState(null);
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        id: null
    });

    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        value: Yup.string().required('Email is required').email("Please enter a valid email address"),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const handleClose = () => setShow(false);

    useEffect(() => {
        loadEmails();
    }, [])

    function loadEmails() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/company/emails").then(response => {
            setEmails(response.data);
        });
    }

    function newEmail() {
        resetField("title");
        resetField("value");
        clearErrors();
        setEmailId(null);
        setShow(true);
    }
    
    function editEmail(emailId) {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/accountowner/company/email/" + emailId).then(response => {
            clearErrors();
            setValue("title", response.title);
            setValue("value", response.value);
            setEmailId(response.data.id);
            setShow(true);
        });
    }

    function deleteEmail() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/accountowner/company/email/" + confirmationDialog.id).then(response => {
            loadEmails();
            setConfirmationDialog({show: false})
        });
    }

    function onSubmit() {
        if (emailId) {
            fetchWrapper.put(process.env.REACT_APP_API_URL + "/accountowner/company/email/" + emailId, getValues()).then(response => {
                setShow(false);
                loadEmails();
            });
        } else {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/accountowner/company/email", getValues()).then(response => {
                setShow(false);
                loadEmails();
            });
        }
    };

    return (
        <>
        <div className="row mb-3">
        <p className='mb-0'>Emails{authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true && <a className="btn btn-sm btn-primary ms-2 rounded-circle" href="#" onClick={newEmail}><FontAwesomeIcon icon={faPlus}/></a>}</p>
        <p className="text-secondary mb-0 fs-6">Enter the company’s email addresses so that others can write to you.</p>
        {emails.map(email => 
            <div key={email.id}>
            {email.title}: {email.value}
            {authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true ?
                <>
                    <a className="btn btn-link btn-sm" onClick={() => editEmail(email.id)}><FontAwesomeIcon icon={faPencil}/></a>
                    {emails.length > 1 && <button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setConfirmationDialog({show: true, id: email.id})}><FontAwesomeIcon icon={faX}/></button>}
                </>
            : null}
            </div>
        )}
        </div>
        {authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true &&
        <>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{emailId ? 'Edit E-mail' : 'Add New E-mail'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField
                            register={register}
                            label="Name"
                            name="title"
                            error={errors.title}
                            maxLength={100}
                        />
                        <TextInputField 
                            register={register}
                            label="Email"
                            name="value"
                            error={errors.value}
                            maxLength="100"
                        />
                        <div className="d-grid gap-2">
                            <button disabled={isSubmitting} className="btn btn-lg btn-dark mt-3">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal.Body>
        </Modal>
        {confirmationDialog.show && 
            <ConfirmationDialog 
                show={confirmationDialog.show}
                handleConfirm={deleteEmail} 
                handleCancel={() => setConfirmationDialog({show: false})}
            />
        }
        </>
        }
        </>
    )

}