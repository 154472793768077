import ReactPaginate from "react-paginate";

export function Paginate({totalPages, handlePageClick}) {

    return (
        <>
            {totalPages > 1 ?
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    pageCount={totalPages}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className="pagination justify-content-center mt-4"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    activeClassName="page-item active"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                />
            : null}
        </>
    )

}