import { useSelector } from "react-redux";
import { ProfileField } from "_components";

export function Socials({company}) {
    const authUser = useSelector(x => x.auth.user);

    return (
        <>
            <p className="mb-0">Social Networks</p>
            <p className="text-secondary mb-0 fs-6">Add your social networks and enable your visitors to start following you.</p>
            <ProfileField data={company} name="linkedin" editAllowed={authUser.accountOwner} deleteAllowed={authUser.accountOwner} apiEndpoint="/accountowner/company/linkedin" icon="/linkedin.svg" />
            <ProfileField data={company} name="twitter" editAllowed={authUser.accountOwner} deleteAllowed={authUser.accountOwner} apiEndpoint="/accountowner/company/twitter" icon="/twitter-tile.svg" />
            <ProfileField data={company} name="facebook" editAllowed={authUser.accountOwner} deleteAllowed={authUser.accountOwner} apiEndpoint="/accountowner/company/facebook" icon="/facebook-tile.svg" />
            <ProfileField data={company} name="instagram" editAllowed={authUser.accountOwner} deleteAllowed={authUser.accountOwner} apiEndpoint="/accountowner/company/instagram" icon="/instagram-icon.svg" />
            <ProfileField data={company} name="tiktok" editAllowed={authUser.accountOwner} deleteAllowed={authUser.accountOwner} apiEndpoint="/accountowner/company/tiktok" icon="/tiktok.svg" />
        </>
    )

}