import { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { fetchWrapper } from "_helpers";
import { Loading, SubmitBar } from "_components";

export function StateChange({leadId, offerId, selectedState, callback}) {
    const [states, setStates] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    
    const {
        register,
        handleSubmit,
        formState,
        getValues,
        clearErrors
    } = useForm();
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        if (leadId && offerId) {
            clearErrors();
            loadOfferStates();
        }
    }, [leadId, offerId])

    const loadOfferStates = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + offerId + "/states").then((response) => {
            setStates(response.data);
            setLoading(false)
        });
    }

    const onSubmit = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/offer/" + offerId + "/state/" + getValues('state')).then(() => {
            callback();
        });
    }

    return (
        <>
        {loading === true ? <Loading /> :
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-4">
                    <label htmlFor="type" className="mb-1">State</label>
                    <select id="type" {...register('state')} className={`form-select ${errors.state ? 'is-invalid' : ''}`}>
                        {states.map(item => 
                            <option key={item.id} value={item.id} selected={selectedState === item.state ? true : false} >{t("LEADOFFERSTATE." + item.state)}</option>
                        )}
                    </select>
                    <div className="invalid-feedback">{errors.state?.message}</div>
                </div>
                <SubmitBar showCancel={false} labelConfirm="Save" isSubmitting={isSubmitting}/>
            </form>
        }
        </>
    )
}