import Avatar from 'react-avatar';
import '../notifications.css';
import { Fragment, useContext, useEffect, useState } from 'react';
import { fetchWrapper } from '_helpers';
import moment from 'moment';
import AppContext from '_context/AppContext';

export function Notifications() {
    const [notifications, setNotifications] = useState([]);
    const [countUnseen, setCountUnseen] = useState(null);
    const { setSelectedLead, setReloadMessages } = useContext(AppContext);

    useEffect(() => {
        loadNotifications();
    }, [])

    const loadNotifications = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/notifications").then(response => {
            setNotifications(response.data.dateNotifications);
            setCountUnseen(response.data.countUnseen);
            setTimeout(loadNotifications, 15000);
        });
    }

    const markAllAsRead = (e) => {
        e.stopPropagation();
        fetchWrapper.patch(process.env.REACT_APP_API_URL + "/vendor/notifications/mark-all-as-read").then(response => {
            loadNotifications()
        });
        return false;
    }

    const handleNotificationClicked = (leadId, code) => {
        markAsRead(code)
        loadLead(leadId);
    }

    const loadLead = (id) => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + id).then(response => {
            setSelectedLead(response.data)
            setReloadMessages(true)
        });
    }

    const markAsRead = (code) => {
        fetchWrapper.patch(process.env.REACT_APP_API_URL + "/vendor/notification/" + code + "/mark-as-read");
    }

    return (
        <>
            <div className="dropdown dropdown-notification">
                <a onClick={() => loadNotifications()} id="dropdownMenu-notifications" data-target="#" style={{float: "left"}} data-bs-toggle="dropdown"  aria-expanded="true" className='cursor'>
                    <img src="/bell-sharp-regular-2.svg" alt="messages" width={18} />
                </a>
                {countUnseen > 0 && <span className="badge-notification badge-notification-danger">{countUnseen}</span>}
                <ul className="dropdown-menu dropdown-menu-left pull-right" role="menu" aria-labelledby="dropdownMenu-notifications">
                    <li className='dropdown-header dropdown-header-notification'>
                        <h5 className='mb-0'>Notifications</h5>
                    </li>
                    <li className='dropdown-header dropdown-header-notification'>
                        <h6 style={{"display": "inline"}}>Direct</h6>
                        {countUnseen > 0 &&
                            <a className='cursor float-end' style={{color: "black"}} onClick={markAllAsRead}>Mark All As Read</a>
                        }
                    </li>
                    {notifications.length > 0 ?
                        notifications.map((item, index) => 
                            <Fragment key={index}>
                                <li className='dropdown-header dropdown-header-notification' style={{backgroundColor: "#c6c6c6"}} >
                                    {moment(item.date).format('MMM Do YYYY')}
                                </li>
                                <ul className="timeline timeline-icons timeline-sm" style={{margin:"10px", width:"310px"}}>
                                    {item.notifications.map((notification, notificationIndex) =>
                                        <li key={notificationIndex} className={notificationIndex < item.notifications.length - 1 && "border-bottom"} >
                                        <span className="timeline-icon">
                                                {!notification.acknowledged && <span className="badge-notification badge-notification-avatar">&nbsp;</span>}
                                                <Avatar name={notification.creatorFirstName + " " + notification.creatorLastName} round={true} size="40" />
                                            </span>
                                        <p className='mb-0'>
                                            <a className='cursor' style={{color: "black"}} onClick={() => handleNotificationClicked(notification.creatorId, notification.code)} >{notification.creatorFirstName} {notification.creatorLastName} sent you message.</a>
                                            <span className="timeline-date">{moment(notification.creationDate).format('MMM Do YYYY HH:mm')}</span>
                                        </p>
                                    </li>
                                    )}
                                </ul>
                            </Fragment>
                        )
                    : 
                    <li className='dropdown-header dropdown-header-notification'>
                        <p className="lead mt-2 text-center">No notifications.</p>
                    </li>
                    }
                </ul>
            </div>
        </>
    )

}