import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {constants} from "_helpers";
import {TextInputField} from "./base/TextInputField";
import {SubmitBar} from "./base/SubmitBar";
import {useEffect} from "react";
import {PhoneNumberInputField} from "./PhoneNumberInputField";

export function CompanyPhoneNumberForm({onSubmitCallback, data, showDefaultResponseNumber}) {

    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        value: Yup.string().matches(constants.phoneRegExp, 'Phone number is not valid')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        clearErrors();
        resetField('title');
        resetField('value');
        if (data) {
            if (data.id) {
                setValue('id', data.id);
            }
            setValue('title', data.title);
            setValue('value', data.value);
            if (data.key) {
                setValue('key', data.key);
            }
            if (data.defaultResponseNumber) {
                setValue('defaultResponseNumber', data.defaultResponseNumber);
            }
        }
    }, [])

    const onSubmit = () => {
        let values = getValues();
        onSubmitCallback(values);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputField register={register} label="Name" name="title" error={errors.title} maxLength={100}/>
            <PhoneNumberInputField label="Number" name="value" control={control} errorMessage={errors.value}/>
            {showDefaultResponseNumber === true &&
                <div className="form-check">
                    <input type="checkbox" className="form-check-input"
                           id="default-response-number" {...register('defaultResponseNumber')}
                    />
                    <label className="form-check-label" htmlFor="default-response-number">Default response number</label>
                </div>
            }
            <SubmitBar labelConfirm="Save" isSubmitting={isSubmitting} showCancel={false}/>
        </form>
    )

}