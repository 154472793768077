import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';
import { useForm } from "react-hook-form";

import { fetchWrapper } from "_helpers";
import {SubmitBar} from "./base";

export function ConfirmationCode({url, callback}) {
    // form validation rules
    const validationSchema = Yup.object().shape({
        confirmationCode: Yup.string().max(4).required('Please enter confirmation code')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setError,
        getValues,
    } = useForm(formOptions);
    const {isSubmitting, errors} = formState;

    const handleConfirmedIdentity = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + url + "/" + getValues('confirmationCode')).then(response => {
            if (response.status === 539) {
                setError("confirmationCode", { type: 'custom', message: "Confirmation code is not valid. Please try again." }, {shouldFocus: true});
            } else {
                callback(getValues('confirmationCode'), response.status, response.data);
            }
        });
    }

    return (
        <>
            <form onSubmit={handleSubmit(handleConfirmedIdentity)}>
                <h5 className="mb-4">Please enter the code you received to confirm your number.</h5>
                <div className="row">
                    <div className="col-2">&nbsp;</div>
                    <div className="col">
                        <div className="form-group mb-2">
                            <input type={'text'} {...register('confirmationCode')} maxLength={4}
                                   className={`form-control ${errors.confirmationCode ? 'is-invalid' : ''}`}
                                   autoComplete={"one-time-code"}
                            />
                            <div className="invalid-feedback">{errors.confirmationCode?.message}</div>
                        </div>
                    </div>
                    <div className="col-2">&nbsp;</div>
                </div>
                <div className="mb-0">&nbsp;</div>
                <SubmitBar labelConfirm="Confirm" isSubmitting={isSubmitting} showCancel={false} />
            </form>
        </>
    )

}