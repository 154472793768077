import { fetchWrapper } from "_helpers";
import moment from "moment";
import { useEffect, useState } from "react";

export function InteractionsSummary({leadId}) {
    const [interaction, setInteraction] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    useEffect(() => {
        loadDetails();
    }, [leadId])


    const loadDetails = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/interactions/last").then(response => {
            setInteraction(response.data.content[0]);
            setTotalElements(response.data.totalElements)
        });
    }

    return (
        <>
            {leadId &&
                <>
                    {totalElements > 0 &&
                    <>
                        <p className="mb-0">Number of interactions: {totalElements}</p>
                        <p className="mb-2">Last interaction on {moment(interaction.creationDate).format('MMM Do YYYY HH:mm')}</p>
                    </>
                    }
                </>
            }
        </>
    )

}