import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { isSafari, isMobileSafari } from 'react-device-detect';

import { fetchWrapper } from '_helpers';
import { AudienceTitle, PhoneNumberInputField, QRCodePreview } from '_components';
import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function Invitation({ show, handleCallback }) {
    const { user: authUser } = useSelector(x => x.auth);
    const { t } = useTranslation();
    const [audiences, setAudiences] = useState([]);
    const [pages, setPages] = useState([]);
    const [invitationShortCode, setInvitationShortCode] = useState(null);
    const [pageUrl, setPageUrl] = useState(null);
    const [selectedAudience, setSelectedAudience] = useState(null);
    const [selectedLandingPage, setSelectedLandingPage] = useState(null);
    const [urlCopied, setUrlCopied] = useState(false);
    const [lastAddedTag, setLastAddedTag] = useState(null);
    const [lastRemovedTag, setLastRemovedTag] = useState(null);
    const [whitelist, setWhitelist] = useState([]);
    const tagifyRef = useRef();

    useEffect(() => {
        if (show === true) {
            loadAudiences();
            loadPages();
        }
    }, [show])

    useEffect(() => {
        getInvitationShortCode();
    }, [selectedAudience, selectedLandingPage])

    useEffect(() => {
        if (invitationShortCode != null) {
            getTags()
        }
    }, [invitationShortCode])

    useEffect(() => {
        if (lastAddedTag) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/invite/lead/" + selectedAudience + "/" + selectedLandingPage + "/tag/add", { "value": lastAddedTag })
        }
    }, [lastAddedTag])

    useEffect(() => {
        if (lastRemovedTag) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/invite/lead/" + selectedAudience + "/" + selectedLandingPage + "/tag/remove", { "value": lastRemovedTag })
        }
    }, [lastRemovedTag])

    function loadAudiences() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audiences/active").then(response => {
            const data = response.data;
            setAudiences(data);
            const selectedAudienceId = data.filter(item => item.type === "PERSONAL").map(item => item.id);
            setSelectedAudience(selectedAudienceId[0])
            setValue('audience', selectedAudienceId[0]);
            getTags()
        })
    }

    function loadPages() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/pages").then(response => {
            const data = response.data;
            setPages(response.data);
            const selectedPageId = data.filter(item => item.destination === "PERSONAL").map(item => item.id);
            setSelectedLandingPage(selectedPageId[0])
            setValue('pageType', selectedPageId[0]);
            getTags()
        })
    }

    function getInvitationShortCode() {
        if (selectedAudience && selectedLandingPage) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/invite/lead/" + selectedAudience + "/" + selectedLandingPage).then(response => {
                setInvitationShortCode(response.data.shortCode);
                setPageUrl(process.env.REACT_APP_BASE_URL + "/" + authUser.channelName + "/" + response.data.shortCode);
                getTags()
            })
        } else {
            setInvitationShortCode(null);
            setPageUrl(null);
        }
    }

    // form validation rules
    const validationSchema = Yup.object({
        pageType: Yup.string().required("Please select landing page"),
        audience: Yup.string().required("Please select audience"),
        contactDetails: Yup.string().required('Phone number is required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        getValues,
        reset,
        setValue,
        setError,
        clearErrors,
        resetField
    } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit() {
        if (invitationShortCode) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/invite/lead/" + invitationShortCode, { ...getValues() }).then(response => {
                if (response.status === 536) {
                    setError("contactDetails", {
                        type: 'custom',
                        message: "Please enter a valid phone number."
                    }, { shouldFocus: true });
                } else {
                    handleCallback(getValues('contactDetails'));
                    clearErrors();
                    resetField('contactDetails')
                }
            }).catch(() => {
                setError("contactDetails", {
                    type: 'custom',
                    message: "Something went wrong. Please try again."
                }, { shouldFocus: true });
            });
        } else {
            console.log("No invitation short code");
        }
    };

    function onAudienceChanged(event) {
        setUrlCopied(false);
        setSelectedAudience(event.target.value);
    }

    function onLandingPageChanged(event) {
        setUrlCopied(false);
        setSelectedLandingPage(event.target.value);
    }

    async function copyUrl() {
        if (invitationShortCode != null) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/invite/lead/" + invitationShortCode, { ...getValues() }).then(response => {
                let textToCopy = process.env.REACT_APP_BASE_URL + "/" + authUser.channelName + "/" + invitationShortCode;
                if (navigator.clipboard) {
                    // Use the Clipboard API if available
                    navigator.clipboard.writeText(textToCopy)
                        .then(() => {
                            setUrlCopied(true);
                            console.log("Copy to clipboard successfully.");
                        })
                        .catch(error => {
                            fallbackCopy(textToCopy); // Fallback to older method
                        });
                } else {
                    // Fallback for older browsers
                    fallbackCopy(textToCopy);
                }
            });
        }
    }

    // Fallback method for copying text
    const fallbackCopy = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;

        // Make the textarea element invisible
        textArea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
        textArea.style.opacity = 0;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
            setUrlCopied(true);
            console.log("Fallback copy to clipboard successfully.");
        } catch (err) {
            alert("Fallback copy to clipboard unsuccessfully. " + err);
        } finally {
            document.body.removeChild(textArea);
        }
    };

    // Tagify settings object
    const baseTagifySettings = {
        blacklist: [],
        backspace: "edit",
        placeholder: "Enter tag",
        editTags: true,
        dropdown: {
            position: 'text',
            enabled: 1,
        },
        transformTag: transformTag
    };

    const loadTags = (e) => {
        var value = e.detail.value;
        //tagifyRef.whitelist = null;
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/tags/search", { "searchPattern": value }).then(response => {
            const whitelist = []
            // eslint-disable-next-line no-lone-blocks
            {
                response.data.map(item =>
                    whitelist.push({
                        value: item.value,
                        style: 'background-color: ' + item.backgroundColor + ';',
                        backgroundColor: item.backgroundColor
                    })
                )
            }
            setWhitelist(whitelist);
        });
    }

    const getTags = () => {
        if (selectedAudience && selectedLandingPage) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/invite/lead/" + selectedAudience + "/" + selectedLandingPage + "/tags").then(response => {
                if (response.data.length > 0) {
                    const tags = []
                    // eslint-disable-next-line no-lone-blocks
                    {
                        response.data.map(item =>
                            tags.push({
                                value: item.value,
                                style: 'background-color: ' + item.backgroundColor + ';',
                                backgroundColor: item.backgroundColor
                            })
                        )
                    }
                    tagifyRef.current.addTags(tags);
                } else {
                    tagifyRef.current.removeAllTags();
                }
            })
        }
    }

    function transformTag(tagData) {
        if (tagData.backgroundColor) {
            tagData.color = tagData.backgroundColor;
            tagData.style = "--tag-bg:" + tagData.backgroundColor;
        }
    }

    const handleAddTag = e => {
        setLastAddedTag(e.detail.data.value);
    };

    const handleRemoveTag = e => {
        setLastRemovedTag(e.detail.data.value);
    };

    return (
        <>
            <Modal show={show} onHide={handleCallback}>
                <Modal.Header closeButton>
                    <Modal.Title>Invite Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-4">
                                    <label htmlFor="pageType" className="mb-1">Landing page</label>
                                    <select id="pageType" {...register('pageType', { onChange: onLandingPageChanged })} className={`form-select ${errors.pageType ? 'is-invalid' : ''}`}>
                                        <option value="">Choose...</option>
                                        {pages.map(landingPage =>
                                            <option key={landingPage.id} value={landingPage.id} selected={landingPage.id == selectedLandingPage}>
                                                {landingPage.title !== null ? landingPage.title : t("LANDINGPAGETYPE." + landingPage.destination)}
                                            </option>
                                        )}
                                    </select>
                                    <div className="invalid-feedback">{errors.pageType?.message}</div>
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="audience" className="mb-1">Audience</label>
                                    <select id="audience" {...register('audience', { onChange: onAudienceChanged })} className={`form-select ${errors.audience ? 'is-invalid' : ''}`}>
                                        <option value="">Choose...</option>
                                        {audiences.map(audience =>
                                            <option key={audience.id} value={audience.id} selected={audience.id == selectedAudience}>
                                                <AudienceTitle audience={audience} />
                                            </option>
                                        )}
                                    </select>
                                    <div className="invalid-feedback">{errors.audience?.message}</div>
                                </div>
                                <div className="form-group mb-4">
                                    <Tags
                                        id="leadtags"
                                        tagifyRef={tagifyRef}
                                        settings={baseTagifySettings}
                                        onInput={loadTags}
                                        onAdd={handleAddTag}
                                        onRemove={handleRemoveTag}
                                        whitelist={whitelist}
                                    />
                                </div>
                                <PhoneNumberInputField label="Phone number" name="contactDetails" control={control} />
                                {pageUrl &&
                                    <div className='row justify-content-center'>
                                        <div className='col-6'>
                                            <QRCodePreview value={pageUrl} maxWidth={128} downloadAllowed={true} />
                                        </div>
                                    </div>
                                }
                                <div className="d-grid gap-2 mt-2">
                                    <button disabled={isSubmitting || !invitationShortCode}
                                        className="btn btn-lg btn-dark rounded-pill mt-3">
                                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                        Send Invitation
                                    </button>
                                    {invitationShortCode &&
                                        <>
                                            {(isSafari || isMobileSafari) === true ?
                                                <>
                                                    <input type="text" className={`form-control`} readOnly={true} value={process.env.REACT_APP_BASE_URL + "/" + authUser.channelName + "/" + invitationShortCode} />
                                                    <p className='mt-0'>Feel free to select and copy this URL for further use.</p>
                                                </>
                                                :
                                                <div className='text-center'>
                                                    {urlCopied === false ?
                                                        <a className='cursor' onClick={() => copyUrl()} >Copy URL</a>
                                                        :
                                                        <p className='text-success mb-0'>URL copied. <a className='cursor' onClick={() => copyUrl()} >Copy again.</a></p>
                                                    }
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}