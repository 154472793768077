import {useState} from "react";
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/free-solid-svg-icons'
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { fetchWrapper } from '_helpers';

export function Name(props) {
    const authUser = useSelector(x => x.auth.user);
    const [company, setCompany] = useState(props.company);
    const [editMode, setEditMode] = useState(false);

    const validationSchema = Yup.object({
        value: Yup.string().required('Company name is required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        clearErrors
    } = useForm(formOptions);
    const {errors} = formState;

    function editProperty() {
        setValue('value', company.name);
        clearErrors();
        setEditMode(true);
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/accountowner/company/" + company.id + "/name", getValues()).then(response => {
            setEditMode(false);
            setCompany(response.data);
        });
    }

    return (
        <div className="mb-3">
            <p className="mb-0">The name of your company</p>
            {editMode === true ? 
            <>
                <form onSubmit={
                        handleSubmit(saveProperty)
                    }>
                <div className="row g-2">
                <div className="col-sm">
                    <input type="text" {...register('value')} maxLength="100" className={`form-control ${errors.value ? 'is-invalid' : ''}`}/>
                    <div className="invalid-feedback">{errors.value?.message}</div>
                </div>
                <div className="col">
                    <button type="submit" className="btn btn-primary" >Confirm</button>
                    <a className="btn btn-light" onClick={() => setEditMode(false)}>Cancel</a>
                </div>
                </div>
                </form>
            </>
            :
            <h4 className="mt-0">{company.name}
                {authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true ? <a className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil}/></a> : null}
            </h4>
            }
        </div>
    )

}