import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from 'react-hook-form';
import { fetchWrapper } from '_helpers';
import { useRef, useState } from 'react';
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

export function Edit({leadId, callback, cancelCallback, count}) {
    const [tags, setTags] = useState([]);
    const [whitelist, setWhitelist] = useState([]);
    const tagifyRef = useRef();
    
    const {
        handleSubmit,
        formState,
    } = useForm();
    const {errors, isSubmitting} = formState;

    // Tagify settings object
    const baseTagifySettings = {
        blacklist: [],
        backspace: "edit",
        placeholder: "Enter tag",
        editTags: true,
        dropdown: {
            position: 'text',
            enabled: 1,
        },
        transformTag: transformTag
    };

    const loadTags = (e) => {
        var value = e.detail.value;
        //tagifyRef.whitelist = null;
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/tags/search", {"searchPattern": value}).then(response => {
            const whitelist = []
            // eslint-disable-next-line no-lone-blocks
            {response.data.map(item =>
                whitelist.push({
                    value: item.value,
                    style: 'background-color: ' + item.backgroundColor + ';',
                    backgroundColor: item.backgroundColor
                })
            )}
            setWhitelist(whitelist);
        });
    }

    function transformTag(tagData) {
        if (tagData.backgroundColor) {
            tagData.color = tagData.backgroundColor;
            tagData.style = "--tag-bg:" + tagData.backgroundColor;
        }
    }

    const onSubmit = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/tags", {"value": tags}).then(response => {
            callback();
        });
    }

    const handleChange = e => {
        setTags(e.detail.tagify.value.map(item => item.value))
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Tags
                    id="leadtags"
                    tagifyRef={tagifyRef}
                    settings={baseTagifySettings}
                    onInput={loadTags}
                    onAdd={handleChange}
                    onRemove={handleChange}
                    whitelist={whitelist}
                />
                <button className="btn btn-sm" isSubmitting={isSubmitting} type='submit' ><FontAwesomeIcon icon={faCheck}/></button>
                <button className="btn btn-sm" onClick={cancelCallback} ><FontAwesomeIcon icon={faX}/></button>
                <div className="invalid-feedback mt-0">{errors.value?.message}</div>
            </form>
        </>
    )

}