import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';

import { fetchWrapper } from '_helpers';
import { Paginate } from "_components";

export {
    VendorInvitation
};

function VendorInvitation() {
    const [invitations, setInvitations] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Enter an email').email("Please enter a valid email address")
    });
    const formOptions = { resolver: yupResolver(validationSchema), criteriaMode: "firstError", shouldFocusError: true };
    const { register, handleSubmit, formState, getValues, setError } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        loadInvitations(0);
    }, [])

    function onSubmit() {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/invite/vendor", getValues()).then(response => {
            loadInvitations(0);
            if (response.status === 530 || response.status === 532) {
                setError("email", { type: 'server', message: "Email has been already registered." }, { shouldFocus: true });
            }
        });
    }

    function loadInvitations(page) {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/accountowner/invitations?page=" + page).then(response => {
            setInvitations(response.data.content);
            setTotalPages(response.data.totalPages);
        })
    }

    const handlePageClick = (event) => {
        loadInvitations(event.selected);
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h3 className="mt-4 text-center">Invite Vendor</h3>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={
                        handleSubmit(onSubmit)
                    }>
                        <div className="form-group mb-4">
                            <label className="mb-2">Email</label>
                            <input type="text" {...register('email')} maxLength={100}
                                className={
                                    `form-control ${errors.email ? 'is-invalid' : ''
                                    }`
                                } />
                            <div className="invalid-feedback">
                                {
                                    errors.email?.message
                                }</div>
                        </div>
                        <div className="d-grid gap-2">
                            <button disabled={isSubmitting}
                                className="btn btn-dark mt-3">
                                {
                                    isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>
                                }
                                Send Invitation
                            </button>
                        </div>
                    </form>
                    {invitations.length > 0 ?
                        <>
                            <table className="table mt-4">
                                <thead>
                                    <tr>
                                        <td>Email</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invitations.map(invitation =>
                                        <tr key={invitation.id}>
                                            <td>
                                                {invitation.inviteeEmail}
                                                <p className="small mb-0">{moment(invitation.creationDate).format('Do MMM YYYY HH:mm')}</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </>
                    : null}
                    <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
                </div>
            </div>
        </>
    )

}
