import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";

import { SubmitBar, TextInputField } from "_components";
import { fetchWrapper } from "_helpers";

export function New({submitDisabled, callback, errorMessage}) {
    const [offers, setOffers] = useState([]);

    // form validation rules
    const validationSchema = Yup.object({
        value: Yup.string().required('Message is a required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState: { isSubmitting, isSubmitted, isSubmitSuccessful },
        getValues,
        resetField,
        clearErrors,
        reset,
        errors
    } = useForm(formOptions);


    useEffect(() => {
        reset()
        clearErrors();
        resetField('content');
        loadOffers()
    }, []);

    const loadOffers = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/offers", {"status": "ACTIVE"}).then(response => {
            setOffers(response.data.content);
        });
    }

    const onSubmit = () => {
        callback(getValues());
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField
                            register={register}
                            label="Message"
                            name="value"
                            error={errors?.value}
                            textarea={true}
                            maxLength="100"
                        />
                        {offers.length > 0 &&
                            <div className="form-group mb-4">
                                <label htmlFor="offer" className="mb-1">Offer</label>
                                <select id="offer" {...register('offerId')} className="form-select" >
                                    <option value="">Choose...</option>
                                    {offers.map(offer => <option key={offer.id} value={offer.id}>{offer.title}</option>)}
                                </select>
                            </div>
                        }
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="contact-vendor" defaultChecked={true} {...register('contactVendorAllowed')} />
                            <label className="form-check-label" htmlFor="contact-vendor">Add mobile number so that the recipient can respond?</label>
                        </div>
                        {errorMessage}
                        <SubmitBar
                            disabled={submitDisabled}
                            showCancel={false}
                            labelConfirm="Send"
                            isSubmitting={isSubmitting || (isSubmitted && isSubmitSuccessful)}
                        />
                    </form>
                </div>
            </div>
        </>
    )

}