import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmationDialog, SubmitBar, TextInputField } from "_components";
import { CountriesSelectField } from "_components/CountriesSelectField";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import FileResizer from "react-image-file-resizer";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export function Event({ offer, selectedTemplateId }) {
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [existingImages, setExistingImages] = useState([]);
    const [deletePictureConfirmationDialog, setDeletePictureConfirmationDialog] = useState({
        show: false
    });

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        data: Yup.object({
            locationPlace: Yup.string().required('Location place is required field')
        })
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        setError
    } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (offer !== undefined) {
            setValue('title', offer.title);
            setValue('description', offer.description);
            setValue('callToActionText', offer.callToActionText);
            setValue('callToActionURL', offer.callToActionURL);
            let content = JSON.parse(offer.content);
            setValue('data.locationPlace', content.locationPlace);
            setValue('data.address1', content.address1);
            setValue('data.address2', content.address2);
            setValue('data.state', content.state);
            setValue('data.city', content.city);
            setValue('data.postalCode', content.postalCode);
            if (content.country) {
                setSelectedCountry(content.country);
            }
            if (content.fromDate) {
                setStartDate(new Date(content.fromDate));
                setValue('data.fromDate', content.fromDate);
            }
            if (content.toDate) {
                setEndDate(new Date(content.toDate));
                setValue('data.toDate', content.toDate);
            }
            setValue('data.description', content.description);
            loadPictures();
        }
    }, [offer])

    const onSelectFile = e => {
        setImageSrc(null);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImageSrc(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const loadPictures = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id + "/pictures").then(response => {
            setExistingImages(response.data);
        });
    }

    const handleFromDateChange = (date) => {
        setStartDate(date);
        var toDate = new Date(date);
        toDate.setHours(toDate.getHours() + 1);
        setEndDate(toDate);
        setValue('data.fromDate', date);
        setValue('data.toDate', toDate);
    }

    const handleToDateChange = (date) => {
        setEndDate(date);
        setValue('data.toDate', date);
    }

    const onSubmit = async () => {
        const formData = new FormData();
        if (startDate < new Date()) {
            setError("fromDate", { type: 'custom', message: "From date must be after now." }, { shouldFocus: true });
            return;
        }
        if (startDate >= endDate) {
            setError("data.fromDate", { type: 'custom', message: "Start date must be before end date." }, { shouldFocus: true });
            return;
        }
        if (imageSrc) {
            const image = await resizeFile(dataURLtoBlob(imageSrc));
            formData.append("files", image, "image.png");
        }
        if (offer) {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "callToActionText": getValues('callToActionText'), "callToActionURL": getValues('callToActionURL'), "data": getValues('data'), "remainingImages": existingImages.map(item => item.id) }))
            fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id, formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        } else {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "callToActionText": getValues('callToActionText'), "callToActionURL": getValues('callToActionURL'), "templateId": selectedTemplateId, "data": getValues('data') }))
            fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer", formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        }
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(
                file,
                2048,
                1080,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const deletePicture = () => {
        setImageSrc(null)
        setExistingImages([])
        setDeletePictureConfirmationDialog({ show: false });
    }

    return (
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength="100" />
                    <TextInputField register={register} label="Subtitle" name="description" error={errors.description} textarea={true} />
                    <div className="form-group mb-2">
                        <label className="mb-0">Image</label>
                        <input className="form-control" type="file" onChange={onSelectFile} accept="image/*" max={1} />
                    </div>
                    {imageSrc && (
                        <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                            <img src={imageSrc} alt="preview" className='card-img-top' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                            <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeletePictureConfirmationDialog({ show: true })} ><FontAwesomeIcon icon={faX} /></button></span>
                        </div>
                    )}
                    <div style={{ display: "flex" }} >
                        {existingImages.map((picture, index) =>
                            <div key={picture.id} style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                <img src={"/uploads/offer/" + picture.path} alt={picture.path} className='card-img-top' style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                                <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeletePictureConfirmationDialog({ show: true })}><FontAwesomeIcon icon={faX} /></button></span>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <Controller
                                name="fromDate"
                                control={control}
                                rules={{
                                    required: true
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <>
                                        <div className="form-group mb-2">
                                            <label className="mb-0">From date</label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => handleFromDateChange(date)}
                                                className="form-control"
                                                minDate={new Date()}
                                                showTimeSelect
                                                timeFormat="p"
                                                timeIntervals={15}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                            />
                                        </div>
                                        {error?.message.length > 0 && <h6 className="mb-2 text-danger">{error?.message}</h6>}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col">
                            <div className="form-group mb-2">
                                <Controller
                                    name="toDate"
                                    control={control}
                                    rules={{
                                        required: true
                                    }}
                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                        <>
                                            <div className="form-group mb-2">
                                                <label className="mb-0">To date</label>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => handleToDateChange(date)}
                                                    className="form-control"
                                                    minDate={new Date()}
                                                    showTimeSelect
                                                    timeFormat="p"
                                                    timeIntervals={15}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                />
                                            </div>
                                            {error?.message.length > 0 && <h6 className="mb-2 text-danger">{error?.message}</h6>}
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <TextInputField register={register} label="Location Place" name="data.locationPlace" error={errors.data?.locationPlace} maxLength="100" />

                    <TextInputField register={register} label="Address 1" name="data.address1" maxLength={100} />
                    <TextInputField register={register} label="Address 2" name="data.address2" maxLength={100} />
                    <CountriesSelectField register={register} setValue={setValue} name="data.country" valueName={true} selected={selectedCountry} />
                    <TextInputField register={register} label="State" name="data.state" maxLength={100} />
                    <TextInputField register={register} label="City" name="data.city" maxLength={100} />
                    <TextInputField register={register} label="Zip" name="data.postalCode" maxLength={100} />
                    <TextInputField register={register} label="Description" name="data.description" error={errors.data?.description} textarea={true} />

                    <h4 className="mb-1 mt-3">Call To Action</h4>
                    <TextInputField register={register} label="Link text" name="callToActionText" error={errors.callToActionText} maxLength="100" />
                    <TextInputField register={register} label="URL" name="callToActionURL" error={errors.callToActionURL} maxLength="100" />

                    <SubmitBar isSubmitting={isSubmitting} labelConfirm="Save" cancelLink="/offers" />
                </form>
                <ConfirmationDialog
                    show={deletePictureConfirmationDialog.show}
                    message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                    handleConfirm={deletePicture}
                    handleCancel={() => setDeletePictureConfirmationDialog({ show: false })}
                />
            </div>
        </div>
    )

}