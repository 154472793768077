import {faCheckCircle, faEnvelope, faPhone, faX} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";

import {AudienceTitle, Loading, NoResults, Paginate} from "_components";
import {fetchWrapper} from "_helpers";
import {SendToGroup} from "./SendToGroup";
import {useTranslation} from "react-i18next";

export function Leads({audience, showLeadsSection, handleClicked, handleBackClick}) {
    const { register, watch, getValues, setValue } = useForm();
    const { t } = useTranslation();
    const searchPattern = watch('searchPattern');
    const interactionsCount = watch('interactionsCount');
    const lastInteractionWithin = watch('lastInteractionWithin');
    const offerId = watch('offerId');
    const offerStateId = watch('offerStateId');
    const [loading, setLoading] = useState(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [leads, setLeads] = useState([]);
    const [excludeLeadIds, setExcludeLeadIds] = useState(new Set());
    const [includeLeadIds, setIncludeLeadIds] = useState(new Set());
    const [selectAll, setSelectAll] = useState(true);
    const [deselectAll, setDeselectAll] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [offers, setOffers] = useState([]);
    const [offerStates, setOfferStates] = useState([]);
    const [whitelist, setWhitelist] = useState([]);
    const [tags, setTags] = useState([]);
    const tagifyRef = useRef();

    // Tagify settings object
    const baseTagifySettings = {
        blacklist: [],
        backspace: "edit",
        placeholder: "Enter tag",
        editTags: false,
        enforceWhitelist: true,
        dropdown: {
            position: 'text',
            enabled: 1,
        },
        transformTag: transformTag
    };

    useEffect(() => {
        if (audience !== null) {
            setPage(0);
            loadOffers();
            clearFilter()
            setExcludeLeadIds(new Set())
            setIncludeLeadIds(new Set())
            setSelectAll(true)
            setDeselectAll(false)
        }
    }, [audience])

    useEffect(() => {
        if (audience !== null) {
            loadLeads();
        }
    }, [page, audience])

    useEffect(() => {
        if (audience !== null) {
            setPage(0)
            loadLeads();
        }
    }, [searchPattern, interactionsCount, lastInteractionWithin, offerId, offerStateId, tags])

    useEffect(() => {
        if (offerId !== undefined && offerId !== null && offerId !== "") {
            loadOfferStates()
        } else {
            setValue("offerStateId", "")
            setOfferStates([])
        }
    }, [offerId])

    function loadLeads() {
        setLoading(true);
        setValue("pageSize", 10, { shouldTouch: true });
        setValue("pageNumber", page, { shouldTouch: true });
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/audience/" + audience.id + "/leads", getValues()).then(response => {
            const content = response.data.content;
            setLeads(content);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    const toggleAdvancedSearch = () => {
        setShowAdvancedSearch(!showAdvancedSearch);
    }

    const clearFilter = () => {
        setValue("lastInteractionWithin", "")
        setValue("interactionsCount", "")
        setValue("offerId", "")
        setValue("offerStateId", "")
        if (tagifyRef.current) {
            tagifyRef.current.removeAllTags();
            setTags([])
            setValue('tags', [])
        }
    }

    const loadOffers = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/offers", {"searchPattern": ""}).then(response => {
            setOffers(response.data.content);
        });
    }

    const loadOfferStates = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + offerId + "/states").then(response => {
            setOfferStates(response.data);
        });
    }

    const loadTags = (e) => {
        var value = e.detail.value;
        //tagifyRef.whitelist = null;
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/tags/search", {"searchPattern": value}).then(response => {
            const whitelist = []
            // eslint-disable-next-line no-lone-blocks
            {response.data.map(item =>
                whitelist.push({
                    value: item.value,
                    style: 'background-color: ' + item.backgroundColor + ';',
                    backgroundColor: item.backgroundColor
                })
            )}
            setWhitelist(whitelist);
        });
    }

    function transformTag(tagData) {
        if (tagData.backgroundColor) {
            tagData.color = tagData.backgroundColor;
            tagData.style = "--tag-bg:" + tagData.backgroundColor;
        }
    }

    const handleChange = e => {
        setTags(e.detail.tagify.value.map(item => item.value))
        setValue('tags', e.detail.tagify.value.map(item => item.value))
    };

    const handleChecked = (leadId) => {
        if (selectAll === true) {
            const tempCheckedLeadIds = new Set(excludeLeadIds);
            if (tempCheckedLeadIds.has(leadId)) {
                tempCheckedLeadIds.delete(leadId);
            } else {
                tempCheckedLeadIds.add(leadId);
            }
            setExcludeLeadIds(tempCheckedLeadIds);
        } else if (deselectAll === true) {
            const tempCheckedLeadIds = new Set(includeLeadIds);
            if (tempCheckedLeadIds.has(leadId)) {
                tempCheckedLeadIds.delete(leadId);
            } else {
                tempCheckedLeadIds.add(leadId);
            }
            setIncludeLeadIds(tempCheckedLeadIds);
        }
    }

    const selectAllLeads = () => {
        setSelectAll(true)
        setDeselectAll(false)
        setIncludeLeadIds(new Set())
        setExcludeLeadIds(new Set())
    }

    const deselectAllLeads = () => {
        setSelectAll(false)
        setDeselectAll(true)
        setIncludeLeadIds(new Set())
        setExcludeLeadIds(new Set())
    }

    return (
        <>
        {audience !== null ?
            <>
            <div className="row justify-content-center mt-3">
                <div className="col-4">
                    <h4 className="mt-2">Leads</h4>
                </div>
                <div className="col-8">
                    <input type="text" className="form-control rounded-pill" name="searchPattern" {...register('searchPattern')} placeholder="Search leads" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span className="badge badge-sm rounded-pill text-bg-secondary">
                        {showLeadsSection === true &&
                            <>
                                <AudienceTitle audience={audience} />
                                <a className="ms-2 cursor" style={{color: "white"}} onClick={handleBackClick}><FontAwesomeIcon icon={faX} /></a>
                            </>
                        }
                    </span>
                    <div className="float-end">
                        <a onClick={() => toggleAdvancedSearch()} className="cursor" >Filtering</a>
                    </div>
                </div>
            </div>
            {leads.length > 0 &&
                <div className="row mb-2">
                    <div className="col">
                        {showAdvancedSearch === false &&
                            <div className="float-start">
                                <a onClick={() => selectAllLeads()} className="cursor" >Select All</a>&nbsp;/&nbsp;
                                <a onClick={() => deselectAllLeads()} className="cursor" >Deselect All</a>
                            </div>
                        }
                        <div className="float-end">
                            {audience !== undefined &&
                                <SendToGroup
                                    selectedAll={selectAll}
                                    deselectedAll={deselectAll}
                                    audienceId={audience.id}
                                    includeLeadIds={includeLeadIds}
                                    excludeLeadIds={excludeLeadIds}
                                    filterValues={(name) => getValues(name)}
                                />
                            }
                        </div>
                    </div>
                </div>
            }
            {showAdvancedSearch &&
                <>
                <div className="row mb-2" style={{transition: "0.5s all"}}>
                    <div className="col">
                        <div className="form-group mb-2">
                            <label htmlFor="offer" className="mb-1">Last interaction within</label>
                            <select className="form-select" {...register('lastInteractionWithin')} >
                                <option value="">All</option>
                                <option value="DAY">Day</option>
                                <option value="WEEK">Week</option>
                                <option value="MONTH">Month</option>
                                <option value="SIX_MONTHS">6 Months</option>
                            </select>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="offer" className="mb-1">Number of interactions</label>
                            <select className="form-select" {...register('interactionsCount')} >
                                <option value="">All</option>
                                <option value="ONE_PLUS">1+</option>
                                <option value="TWO_PLUS">2+</option>
                                <option value="FOUR_PLUS">4+</option>
                                <option value="SIX_PLUS">6+</option>
                                <option value="TEN_PLUS">10+</option>
                            </select>
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="leadtags" className="mb-1" style={{display: 'block'}}>Tags</label>
                            <Tags
                                id="leadtags"
                                tagifyRef={tagifyRef}
                                settings={baseTagifySettings}
                                onInput={loadTags}
                                onAdd={handleChange}
                                onRemove={handleChange}
                                whitelist={whitelist}
                            />
                        </div>
                        {offers.length > 0 &&
                            <div className="form-group mb-2">
                                <label htmlFor="offer" className="mb-1">Offer</label>
                                <select id="offer" {...register('offerId')} className="form-select" >
                                    <option value="">All</option>
                                    {offers.map(offer => <option key={offer.id} value={offer.id}>{offer.title}</option>)}
                                </select>
                            </div>
                        }
                        {offerStates.length > 0 &&
                            <div className="form-group mb-2">
                                <label htmlFor="offer" className="mb-1">Offer state</label>
                                <select id="offerState" {...register('offerStateId')} className="form-select" >
                                    <option value="">All</option>
                                    {offerStates.map(offerState => <option key={offerState.id} value={offerState.id}>{t("LEADOFFERSTATE." + offerState.state)}</option>)}
                                </select>
                            </div>
                        }
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col">
                        {showAdvancedSearch === true &&
                            <div className="float-start">
                                <a onClick={() => selectAllLeads()} className="cursor" >Select All</a>&nbsp;/&nbsp;
                                <a onClick={() => deselectAllLeads()} className="cursor" >Deselect All</a>
                            </div>
                        }
                        <div className="float-end">
                            <a onClick={() => clearFilter()} className="cursor" >Clear Filters</a>
                        </div>
                    </div>
                </div>
                </>
            }
            {loading === false ?
                <>
                    {leads.length > 0 ?
                        <>
                            {leads.map(lead =>
                                <div key={lead.id} >
                                    <div className="row g-0 mt-2 mb-2" >
                                        <div className="col-7">
                                            <input type="checkbox" className="form-check-input me-2" style={{marginTop: "0.70rem"}}
                                                   checked={excludeLeadIds.has(lead.id) || (deselectAll === true && !includeLeadIds.has(lead.id)) ? false : true}
                                                   onChange={(event) => handleChecked(lead.id)} />
                                            <button className="btn btn-link ps-0" style={{textAlign: 'left'}} onClick={() => handleClicked(lead)} ><p className='mb-0'>{lead.firstName + " " + lead.lastName}</p></button>
                                            <p className="text-secondary mb-0 fs-6">{lead.phoneNumber}{lead.email && ", " + lead.email}</p>
                                        </div>
                                        <div className="col-5">
                                            <div className="mt-2">
                                                <span className="btn-sm-like text-bg-success shadow me-1" style={{width: "4rem"}}>
                                                    <FontAwesomeIcon icon={faCheckCircle} className="me-1" /><strong>{lead.phoneNumberVerified && lead.emailVerified ? "2" : "1"}/2</strong>
                                                </span>
                                                <div style={{display: "inline"}}>
                                                    <a href={"tel:" + lead.phoneNumber} className="btn btn-light btn-sm btn-sm-dashboard shadow me-1" ><FontAwesomeIcon icon={faPhone} /></a>
                                                </div>
                                                <div style={{display: "inline"}}>
                                                    {lead.email && <a href={"mailto:" + lead.email} className="btn btn-light btn-sm btn-sm-dashboard me-1 shadow" ><FontAwesomeIcon icon={faEnvelope} /></a>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom "></div>
                                </div>
                            )}
                        </>
                        : <NoResults />}
                </>
                : <Loading />
            }
            <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
            </>
        :
        <div className="px-4 py-5 my-5 text-center">
            <div className="col-lg-6 mx-auto">
                <p>Select the Audience to see the leads.</p>
            </div>
        </div>}
        </>
    )

}