import { useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Loading } from "_components";
import { fetchWrapper } from "_helpers";

export function ImageUpload({ onUploadFinished }) {
    const [imageSrc, setImageSrc] = useState(null);
    const imgRef = useRef();
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 255,
        height: 300,
        x: 50,
        y: 50
    });
    const [loading, setLoading] = useState(false);

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const onSelectFile = e => {
        setLoading(true);
        setImageSrc(null);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImageSrc(reader.result);
                setLoading(false);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoaded = (image) => {
        imgRef.current = image;
    };

    const uploadImage = () => {
        const image = imgRef.current;

        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
 
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
 
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height,
        );
 
        // Converting to base64
        const base64Image = canvas.toDataURL('image/png');
        console.log("dataURL " + base64Image);
        const formData = new FormData();
        formData.append("file", dataURLtoBlob(base64Image), "test.png");
        fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/profile/image", formData).then(response => {
            onUploadFinished(response.data);
        });
    };

    return (
        <>
            <div className="row justify-content-center mb-4">
                <div className="col text-center">
                    <input className="form-control" type="file" onChange={onSelectFile} accept="image/*" />
                </div>
            </div>
            {loading && <Loading />}
            {imageSrc && (
                <>
                    <div className="row justify-content-center mb-4">
                        <div className="col text-center">
                            <ReactCrop
                                crop={crop}
                                onChange={c => setCrop(c)}
                                onImageLoaded={onImageLoaded}
                                aspect="0.85"
                            >
                                <img src={imageSrc} ref={imgRef} alt="preview" />
                            </ReactCrop>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-4">
                        <div className="col text-center">
                            <button type="button" onClick={() => uploadImage()} className="btn btn-dark">Upload</button>
                        </div>
                    </div>
                </>
            )}
        </>
    )

}