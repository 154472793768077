import {useState} from "react";
import FileResizer from "react-image-file-resizer";
import { Loading } from "_components";

export function ImageUpload({postData}) {
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(false);

    const onSelectFile = e => {
        setLoading(true);
        setImageSrc(null);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImageSrc(reader.result);
                setLoading(false);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(
            file,
            2048,
            1080,
            "png",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
            );
    });

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }

    const uploadImage = async () => {
        const image = await resizeFile(dataURLtoBlob(imageSrc));
        const formData = new FormData();
        formData.append("file", image, "image.png");
        await postData(formData);
    }

    return(
        <>
            <div className="row justify-content-center mb-4">
                <div className="col text-center">
                    <input className="form-control" type="file" onChange={onSelectFile} accept="image/*" />
                </div>
            </div>
            {loading && <Loading/>}
            {imageSrc && (
            <>
                <div className="row justify-content-center mb-4">
                <div className="col text-center">
                    <img src={imageSrc} alt="preview" className="img-fluid"/>
                </div>
                </div>
                <div className="row justify-content-center">
                <div className="col text-center">
                    <div className="d-grid gap-2 mb-3">
                        <button type="button" onClick={() => uploadImage()} className="btn btn-dark rounded-pill">Upload</button>
                    </div>
                </div>
                </div>
            </>
            )}
        </>
    )

}