import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from "react";
import { Modal } from "react-bootstrap";

import { ConfirmationCode, PhoneNumberInputField, SubmitBar } from "_components";
import { constants, fetchWrapper } from "_helpers";

export function RequestManage({callback}) {
    const [show, setShow] = useState(false);

    const validationSchema = Yup.object().shape({
        value: Yup.string().matches(constants.phoneRegExp, 'Phone number is not valid')
    }, ['value']);
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        handleSubmit,
        formState,
        getValues,
        setError
    } = useForm(formOptions);
    const { isSubmitting} = formState;

    const onSubmit = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/lead/manage", getValues()).then(response => {
            if (response.status === 540) {
                setShow(true);
            }
            if (response.status === 533 || response.status === 536) {
                setError("value", { type: 'custom', message: "Unrecognized phone number. Please check the number and try again." }, {shouldFocus: true});
            }
            if (response.status === 536) {
                setError("value", { type: 'custom', message: "Phone number is not valid. Please try again." }, {shouldFocus: true});
            }
            if (response.status === 553) {
                setError("value", { type: 'custom', message: "Something went wrong. Please try again." }, {shouldFocus: true});
            }
        });
    }

    const handleConfirmed = (confirmationCode, status, data) => {
        setShow(false);
        callback(confirmationCode, status, data);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <p>Provide a phone number you used for registration on this account, and we’ll text you a 4-digit verification code. This will confirm you are the owner of the account. </p>
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <PhoneNumberInputField label="Cell" name="value" control={control} />
                        <div className='mt-4 mb-4'></div>
                        <SubmitBar labelConfirm="Confirm" showCancel={false} isSubmitting={isSubmitting} />
                    </div>
                </div>
            </form>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm your identity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConfirmationCode
                        url={"/lead/manage"}
                        callback={handleConfirmed}
                    />
                </Modal.Body>
            </Modal>
        </>
    )

}