import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import Avatar from 'react-avatar';
import moment from "moment";

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog, Loading, NoResults, Paginate } from "_components";

export {
    Dashbboard
};

function Dashbboard() {
    const { register, watch, getValues, setValue } = useForm();
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(0);
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        id: null
    });

    useEffect(() => {
        loadAccounts();
    }, [searchPattern, page])

    function loadAccounts() {
        setLoading(true);
        setValue("pageSize", 10, {shouldTouch:true});
        setValue("pageNumber", page, {shouldTouch:true});
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/admin/companies", getValues()).then(response => {
            setCompanies(response.data.content);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        });
    }

    const deleteAccount = () => {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/admin/account/" + confirmationDialog.id).then(response => {
            loadAccounts();
            setConfirmationDialog({show: false})
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };


    return (
        <div className="row justify-content-center">
            <div className="col-lg-11 col-xl-9">
                <div className="mt-5 mb-3">
                    <input type="text" className="form-control" name="searchPattern" {...register('searchPattern')} placeholder="Search accounts"/>
                </div>
                <div className="">
                    <Link to="/admin/invitation">Add new</Link>
                </div>
                {loading === false ?
                <>
                    {companies.length > 0 ?
                    <>
                    <div className='mt-4 border-bottom'></div>
                    <table className="table">
                        <tbody>
                            {companies.map(company =>          
                                <tr key={company.id}>
                                    <td className="align-middle w-10">
                                        <Avatar name={company.channelName} round={true} size="50" />
                                    </td>
                                    <td className="align-middle w-50">
                                        <Link to={"/admin/company/" + company.id}>
                                            {company.name ? company.name : company.licence}
                                        </Link>
                                        <p>Member since {moment(company.creationDate).format('MMM Do YYYY')}</p>
																						  
                                    </td>
                                    <td className="align-middle text-center">
                                        <div className="dropdown mt-2 float-end">
                                            <button className="btn btn-link btn-sm dropdown-toggle shadow" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faEllipsisV} />
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><button className="dropdown-item" onClick={() => setConfirmationDialog({show: true, id: company.licence})}>Delete</button></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    </>
                    : <NoResults />}
                </>
                : <Loading/>
                }
                <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
                <ConfirmationDialog 
                    show={confirmationDialog.show}
                    message="You are about to remove this account. Are you sure?"
                    handleConfirm={deleteAccount} 
                    handleCancel={() => setConfirmationDialog({show: false, id: null})}
                />
            </div>
        </div>
    );

}
