import { useParams} from 'react-router-dom';
import {useState, useEffect} from "react";
import Avatar from 'react-avatar';
import moment from 'moment';

import { fetchWrapper } from '_helpers';
import { AddressesPreview, EmailsPreview, PhoneNumbersPreview, UserFullName } from '_components';
import { AudiencesCount, LeadsCount, OffersActiveCount, OffersCount } from './index';

export {
    Company
};

function Company() {
    const params = useParams();
    const [company, setCompany] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [emails, setEmails] = useState([]);
    const [companyOwner, setCompanyOwner] = useState(null);
    const [companyOwnerPhoneNumbers, setCompanyOwnerPhoneNumbers] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadCompany();
        loadAddresses();
        loadPhoneNumbers()
        loadEmails()
        loadOwner()
        loadOwnerPhoneNumbers()
    }, [params.companyId]);

    const loadCompany = () => {
        setLoading(true);
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/company/" + params.companyId).then(response => {
            setCompany(response.data);
            setLoading(false);
        });
    }

    const loadAddresses = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/company/" + params.companyId + "/addresses").then(response => {
            setAddresses(response.data);
        });
    }

    const loadPhoneNumbers = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/company/" + params.companyId + "/phone-numbers").then(response => {
            setPhoneNumbers(response.data);
        });
    }

    const loadEmails = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/company/" + params.companyId + "/emails").then(response => {
            setEmails(response.data);
        });
    }

    const loadOwner = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/company/" + params.companyId + "/owner").then(response => {
            setCompanyOwner(response.data);
        });
    }

    const loadOwnerPhoneNumbers = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/admin/company/" + params.companyId + "/owner/phone-numbers").then(response => {
            setCompanyOwnerPhoneNumbers(response.data);
        });
    }

    return (
        <>
        {loading === false ?
            <>
            <div className='row text-center'>
                <div className='col border-bottom'>
                <h1 className='mb-0'>{company?.name}</h1>
                <p>Member since {moment(company.creationDate).format('MMM Do YYYY')}</p>
                </div>  
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    <h6>Company</h6>
                    <div className='row'>
                    <div className='col-3 text-center'>
                        <Avatar name={company.channelName} round={true} size="75" />
                    </div>
                    <div className='col-9'>
                        <AddressesPreview addresses={addresses}/>
                        <PhoneNumbersPreview phoneNumbers={phoneNumbers}/>
                        <EmailsPreview emails={emails}/>
                    </div>
                    </div>
                </div>
                <div className='col-lg-6'>
                    {companyOwner &&
                    <>
                        <h6>Contact</h6>
                        <div className='row'>
                        <div className='col-3 text-center'>
                            <Avatar name={companyOwner.lastName + " " + companyOwner.firstName} round={true} size="75" />
                        </div>
                        <div className='col-9'>
                            <p className='mb-0'><UserFullName profile={companyOwner}/></p>
                            <p className='mb-0'>{companyOwner.email}</p>
                            <PhoneNumbersPreview phoneNumbers={companyOwnerPhoneNumbers}/>
                        </div>
                        </div>
                    </>
                    }
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className='row'>
                <div className='col-lg-8'>
                    <div className='row'>
                        <div className='col-3'>
                            <OffersActiveCount accountId={company.licence} />
                        </div>
                        <div className='col-3'>
                            <OffersCount accountId={company.licence} />
                        </div>
                        <div className='col-3'>
                            <AudiencesCount accountId={company.licence} />
                        </div>
                        <div className='col-3'>
                            <LeadsCount accountId={company.licence} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'></div>

            </div>
            <div className='mt-4 border-bottom'></div>
            </>
        : null
        }
        </>
    )
}
