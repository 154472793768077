import {Link, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {authActions} from '_store';
import { NavbarLogo, UserFullName } from '_components';

export {
    Nav
};

function Nav() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    return (
        <header className="p-3 mb-3" style={{backgroundColor: 'black'}}>
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <NavbarLogo/>
                    <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                        <li>
                            <NavLink to="/" className="nav-item nav-link text-light">Accounts</NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/contacts" className="nav-item nav-link text-light">Contacts</NavLink>
                        </li>
                    </ul>
                    <div className="dropdown">
                        <a href="#" className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <UserFullName profile={authUser}/>
                        </a>
                        <ul className="dropdown-menu text-small dropdown-menu-dark">
                            <li>
                                <Link to="/profile" className='dropdown-item'>My Profiles</Link>
                            </li>
                            <li>
                                <Link to="/password/manage" className='dropdown-item'>Manage Password</Link>
                            </li>
                            <li><hr className="dropdown-divider"/></li>
                            <li>
                                <a className="dropdown-item" href='#' onClick={logout}>Sign out</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );

}
