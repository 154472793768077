import {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil, faX} from '@fortawesome/free-solid-svg-icons'
import {useForm} from "react-hook-form";

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog } from "_components";

export function Occupation(props) {
    const [profile, setProfile] = useState(props.profile);
    const [editMode, setEditMode] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm();
    const {errors} = formState;

    function editProperty() {
        setValue('value', profile.occupation);
        setEditMode(true);
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/profile/occupation", getValues()).then(response => {
            setEditMode(false);
            setProfile(response.data);
        });
    }

    function deleteProperty() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/profile/occupation").then(response => {
            setProfile(response.data);
            setShowConfirmationDialog(false);
        });
    }

    return (
        <div className="mb-3">
            <p className="mb-0">Occupation</p>
            {editMode === true ? 
                <form onSubmit={handleSubmit(saveProperty)}>
                    <div className="row g-2">
                    <div className="col-sm">
                        <input type="text" {...register('value')} className={`form-control ${errors.value ? 'is-invalid' : ''}`} maxLength={100} />
                        <div className="invalid-feedback">{errors.value?.message}</div>
                    </div>
                    <div className="col">
                        <button type="submit" className="btn btn-primary" >Confirm</button>
                        <button type="button" className="btn btn-light" onClick={() => setEditMode(false)}>Cancel</button>
                    </div>
                    </div>
                </form>
            :
            <>
                <p className="mt-0">
                    {profile.occupation}
                    <button type="button" className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil}/></button>
                    {profile.occupation && <button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setShowConfirmationDialog(true)}><FontAwesomeIcon icon={faX}/></button>}
                </p>
                {showConfirmationDialog && 
                    <ConfirmationDialog 
                        show={showConfirmationDialog}
                        handleConfirm={deleteProperty} 
                        handleCancel={() => setShowConfirmationDialog(false)}
                    />
                }
            </>
            }
        </div>
    )

}