export function Footer() {

    return (
        <div className="row g-0 justify-content-center" >
            <div className="col" style={{ backgroundColor: "#000", height: "60px" }}>
                <img src="/navbar.png" className="mt-2" alt="" width="160" height="40" />
                <p className="float-end mt-3 me-2 text-light">Powered by <a href="https://abccrm.ca" style={{ color: "#FFF" }} >ABC.CRM</a></p>
            </div>
        </div>
    )

}