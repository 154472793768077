import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { LeadTags } from "./tags";
import { LeadNotes } from "./notes";
import { LeadOffers } from "./offers";
import { LeadAudiences } from "./audiences";
import { useContext, useEffect, useState } from "react";
import { InteractionsSummary, LogEvents } from "./logevents";
import { LeadMessages } from "./messages";
import { Delete } from "./Delete";
import AppContext from "_context/AppContext";

export function Details({lead, handleBackClick}) {
    const [showBackButton, setShowBackButton] = useState(false);
    const { reloadMessages } = useContext(AppContext);

    useEffect(() => {
        if (window.screen.width < 850) {
            setShowBackButton(true);
        }
    }, [window.screen.width])

    return (
        <>
        {lead &&
            <>
            <div className="row" style={{backgroundColor: "black", color: "white", height: "6rem"}}>
                <div className="col mt-3 ms-4">
                    <h4 className="mt-4 mb-0">{lead.firstName + " " + lead.lastName}
                        {showBackButton && 
                            <span className="badge badge-sm rounded-pill float-end">
                                <a className="cursor" style={{color: "white"}} onClick={handleBackClick}><FontAwesomeIcon icon={faX} /></a>
                            </span>
                        }
                    </h4>
                    <p className="mb-0 fs-6" style={{color: "white"}}>Subscribed on {moment(lead.creationDate).format('MMM Do YYYY')}</p>
                </div>
            </div>
            <div className="row mb-2" style={{backgroundColor: "#e2e2e2", color: "white", height: "2.4rem"}}>
                <div className="col-3 text-center">
                    <a className="btn" href={"tel:" + lead.phoneNumber}>
                        <img src="phone-sharp-regular.svg" alt="phone" width={24} />
                    </a>
                </div>
                <div className="col-3 text-center">
                    {lead.email && 
                        <a className="btn" href={"mailto:" + lead.email}>
                            <img src="envelope-sharp-regular.svg" alt="email" width={24} />
                        </a>
                    }
                </div>
                <div className="col-3 text-center">
                    <a className="btn" href="#messages-section">
                        <img src="comments-sharp-regular.svg" alt="messages" width={24} />
                    </a>
                </div>
                <div className="col-3 text-center">
                    <Delete leadId={lead.id} successCallBack={handleBackClick} />
                </div>
            </div>
            {lead.email && <p className="mb-0">{lead.email}</p>}
            {lead.phoneNumber && <p className="mb-0">{lead.phoneNumber}</p>}
            <InteractionsSummary leadId={lead.id} />
            <div className="row mb-2">
                <div className="col">
                    <LeadTags leadId={lead.id}/>
                </div>
            </div>
            <LeadAudiences leadId={lead.id} />
            <LeadOffers leadId={lead.id} />
            <LeadNotes leadId={lead.id} />
            <LeadMessages leadId={lead.id} reload={reloadMessages}/>
            <LogEvents leadId={lead.id}/>
            </>
        }
        </>
    )

}