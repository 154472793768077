import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "_components";
import { fetchWrapper } from "_helpers";

export function ManageSubscriptions({leadManageSubscriptions, confirmationCode}) {
    const navigate = useNavigate();
    const [manageSubscriptions, setManageSubscriptions] = useState(leadManageSubscriptions);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [showUnsubscribeDialog, setShowUnsubscribeDialog] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [checkedLandingPages, setCheckedLandingPages] = useState([]);

    const {
        handleSubmit,
        formState
    } = useForm();
    const {isSubmitting} = formState;

    useEffect(() => {
        let checkedLandingPages = [];
        manageSubscriptions.forEach((element, index) => {
            checkedLandingPages.push(element.channelName);
        });
        setCheckedLandingPages(checkedLandingPages);
    }, []);


    const handleUnsubscribe = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/lead/unsubscribe/" + confirmationCode).then(response => {
            if (response.status === 200) {
                navigate('/unsubscribed');
            }
            setShowConfirmationDialog(false);
        });
    }

    const onSubmit = () => {
        setShowSuccessMessage(false);
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/lead/manage/confirm/" + confirmationCode, {"channelNames": checkedLandingPages}).then(response => {
            if (response.status === 200) {
                setManageSubscriptions(response.data);
                setShowSuccessMessage(true);
            }
            if (response.status === 533) {
                navigate('/unsubscribed');
            }
            setShowConfirmationDialog(false);
        });
    }

    const handleSubcriptionChange = (event) => {
        setShowSuccessMessage(false);
        if (event.target.checked === false) {
            const filteredLandingPages = checkedLandingPages.filter(number => number !== event.target.value);
            setCheckedLandingPages(filteredLandingPages);
        } else {
            if (checkedLandingPages.includes(event.target.value) === false) {
                let filteredLandingPages = [...checkedLandingPages];
                filteredLandingPages.push(event.target.value);
                setCheckedLandingPages(filteredLandingPages);
            }
        }
    }

    return (
        <>
            <p className="mb-2 mt-3">On ABC.CRM, you are connected with the following accounts. Feel free to change your preferences and confirm.</p>
            <form onSubmit={handleSubmit(() => setShowConfirmationDialog(true))}>
                {manageSubscriptions.map((item, index) => 
                    <div className="form-check mb-2" key={"company-" + index} >
                        <input type="checkbox" name="channelNames" className="form-check-input" id={index} value={item.channelName} onChange={handleSubcriptionChange} defaultChecked/>
                        <label className="form-check-label" htmlFor={index} >{item.title}</label>
                    </div>
                )}
                {showSuccessMessage &&
                    <div className="alert alert-info mt-3 mb-0 alert-dismissible fade show" >You successfully updated your profile</div>
                }
                <div className="d-grid gap-2 mb-3">
                    <button disabled={isSubmitting} className="btn btn-lg btn-primary mt-3 rounded-pill">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Confirm selection
                    </button>
                </div>
            </form>
            <div className="d-grid gap-2 mb-3">
                <button className="btn btn-sm btn-dark mt-3 rounded-pill" onClick={() => setShowUnsubscribeDialog(true)}>Delete All my Data and Unsubscribe</button>
            </div>
            <ConfirmationDialog 
                show={showConfirmationDialog}
                message="Are you sure?"
                handleConfirm={onSubmit} 
                handleCancel={() => setShowConfirmationDialog(false)}
            />
            <ConfirmationDialog 
                show={showUnsubscribeDialog}
                message="This action will delete your data completely. Are you sure?"
                handleConfirm={handleUnsubscribe} 
                handleCancel={() => setShowUnsubscribeDialog(false)}
            />
        </>
    )
}