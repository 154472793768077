import { useState, useEffect } from "react"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FileResizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

import { ConfirmationDialog, SubmitBar, TextInputField } from "_components";
import { CountriesSelectField } from "_components/CountriesSelectField";
import { RealEstateRoom } from "./RealEstateRoom";
import { constants, fetchWrapper } from "_helpers";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export function RealEstate({ offer, selectedTemplateId }) {
    const navigate = useNavigate();
    const authUser = useSelector(x => x.auth.user);
    const [rooms, setRooms] = useState([]);
    const [roomIdx, setRoomIdx] = useState(1);
    const [existingImages, setExistingImages] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [roomDialog, setRoomDialog] = useState({
        show: false,
        id: null
    });
    const [roomDeleteDialog, setRoomDeleteDialog] = useState({
        show: false,
        id: null
    });
    const [images, setImages] = useState([]);
    const [deletePictureConfirmationDialog, setDeletePictureConfirmationDialog] = useState({
        show: false,
        id: null
    });
    const [deleteExistingImageConfirmationDialog, setDeleteExistingImageConfirmationDialog] = useState({
        show: false,
        id: null
    });

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        data: Yup.object({
            mediaLink: Yup.string().matches(constants.websiteRegExp, { message: "Media link must be a valid URL", excludeEmptyString: true }),
        })
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (offer !== undefined) {
            setValue('title', offer.title);
            setValue('description', offer.description);
            let content = JSON.parse(offer.content);
            setValue('data.price', content.price);
            setValue('data.currency', content.currency);
            setValue('data.address1', content.address1);
            setValue('data.address2', content.address2);
            setValue('data.state', content.state);
            setValue('data.city', content.city);
            setValue('data.postalCode', content.postalCode);
            setValue('data.beds', content.beds);
            setValue('data.baths', content.baths);
            setValue('data.totalFlArea', content.totalFlArea);
            setValue('data.remarks', content.remarks);
            setValue('data.ml', content.ml);
            setValue('data.area', content.area);
            setValue('data.floorAreaFin', content.floorAreaFin);
            setValue('data.age', content.age);
            setValue('data.fireplaces', content.fireplaces);
            setValue('data.grossTaxes', content.grossTaxes);
            setValue('data.forTaxYear', content.forTaxYear);
            setValue('data.mediaLink', content.mediaLink);
            setValue('data.styleOfHome', content.styleOfHome);
            setValue('data.parking', content.parking);
            setValue('data.bylawRestrictions', content.bylawRestrictions);
            setValue('data.construction', content.construction);
            setValue('data.amenities', content.amenities);
            setValue('data.maintFeeIncludes', content.maintFeeIncludes);
            setValue('data.heating', content.heating);
            setValue('data.roof', content.roof);
            setValue('data.listingProvidedCourtesyOf', content.listingProvidedCourtesyOf);
            if (content.rooms) {
                setRooms(content.rooms);
            }
            if (content.country) {
                setSelectedCountry(content.country);
            }
            loadPictures();
        }
    }, [offer])

    const loadPictures = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id + "/pictures").then(response => {
            setExistingImages(response.data);
        });
    }

    const onSubmit = async () => {
        const formData = new FormData();
        for (const file of images) {
            const contents = await resizeFile(dataURLtoBlob(file));
            formData.append("files", contents, "test.jpeg");
        }
        if (offer) {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "data": getValues('data'), "remainingImages": existingImages.map(item => item.id) }))
            fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id, formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        } else {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "templateId": selectedTemplateId, "data": getValues('data') }))
            fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer", formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        }
    }

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                let tempImages = [...images, reader.result];
                setImages(tempImages);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(
                file,
                2048,
                1080,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    const deletePicture = () => {
        let tempImages = [...images];
        tempImages.splice(deletePictureConfirmationDialog.id, 1);
        setImages(tempImages);
        setDeletePictureConfirmationDialog({ show: false });
    }

    const deleteExistingImage = () => {
        let tempImages = [...existingImages];
        tempImages.splice(deleteExistingImageConfirmationDialog.id, 1);
        setExistingImages(tempImages);
        setDeleteExistingImageConfirmationDialog({ show: false });
    }

    const handleRoomAdded = (room) => {
        let tempRooms = rooms.filter(item => item.id === room.id);
        if (tempRooms.length === 0) {
            let newRooms = [...rooms, room];
            setRooms(newRooms);
            setRoomIdx(roomIdx + 1);
            setValue('data.rooms', newRooms);
        } else {
            const tempRoom = rooms.find(element => element.id = room.id);
            if (tempRoom !== undefined) {
                let tempRooms = [...rooms];
                let editedRoomIdx = tempRooms.findIndex(element => element.id === room.id);
                tempRooms[editedRoomIdx] = room;
                setRooms(tempRooms);
                setValue('data.rooms', tempRooms);
            }
        }
        setRoomDialog({ show: false });
    }

    const deleteRoom = () => {
        let tempRooms = [...rooms];
        tempRooms.splice(roomDeleteDialog.id, 1);
        setRooms(tempRooms);
        setValue('data.rooms', tempRooms);
        setRoomDeleteDialog({ show: false, id: null });
    }

    return (
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength="100" />
                    <TextInputField register={register} label="Description" name="description" error={errors.description} textarea={true} />
                    <div className="form-group mb-4">
                        <label className="mb-1">Price</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text">{authUser.currency}</span>
                            <input type='number' {...register("data.price")} maxLength={30} className={`form-control ${errors.data?.price ? 'is-invalid' : ''}`} />
                        </div>
                    </div>
                    <TextInputField register={register} label="Address 1" name="data.address1" maxLength={100} />
                    <TextInputField register={register} label="Address 2" name="data.address2" maxLength={100} />
                    <CountriesSelectField register={register} setValue={setValue} name="data.country" valueName={true} selected={selectedCountry} />
                    <TextInputField register={register} label="State" name="data.state" maxLength={100} />
                    <TextInputField register={register} label="City" name="data.city" maxLength={100} />
                    <TextInputField register={register} label="Zip" name="data.postalCode" maxLength={100} />
                    <TextInputField register={register} label="Beds" name="data.beds" maxLength="3" />
                    <TextInputField register={register} label="Baths" name="data.baths" maxLength="3" />
                    <TextInputField register={register} label="Total Floor Area in sqft" name="data.totalFlArea" maxLength="50" />
                    <TextInputField register={register} label="Description of the property" name="data.remarks" textarea={true} />

                    <h4 className="mb-3">Property Information</h4>
                    <TextInputField register={register} label="ML#" name="data.ml" maxLength="100" />
                    <TextInputField register={register} label="Area" name="data.area" maxLength="10" />
                    <TextInputField register={register} label="Floor Area Fin" name="data.floorAreaFin" maxLength="50" />
                    <TextInputField register={register} label="Age" name="data.age" maxLength="10" />
                    <TextInputField register={register} label="Fireplaces " name="data.fireplaces" maxLength="10" />
                    <TextInputField register={register} label="Gross taxes" name="data.grossTaxes" maxLength="100" />
                    <TextInputField register={register} label="For Tax Year" name="data.forTaxYear" maxLength="100" />
                    <hr className="my-4"></hr>

                    <h4 className="mb-3">Media links</h4>
                    <TextInputField register={register} label="Media link" name="data.mediaLink" error={errors.data?.mediaLink} maxLength="150" />
                    <hr className="my-4"></hr>

                    <h4 className="mb-3">Features</h4>
                    <TextInputField register={register} label="Style of home" name="data.styleOfHome" maxLength="100" />
                    <TextInputField register={register} label="Parking" name="data.parking" maxLength="100" />
                    <TextInputField register={register} label="Bylaw Restrictions" name="data.bylawRestrictions" maxLength="100" />
                    <TextInputField register={register} label="Construction" name="data.construction" maxLength="100" />
                    <TextInputField register={register} label="Amenities" name="data.amenities" maxLength="100" />
                    <TextInputField register={register} label="Maint Fee Includes" name="data.maintFeeIncludes" maxLength="100" />
                    <TextInputField register={register} label="Fuel/Heating" name="data.heating" maxLength="100" />
                    <TextInputField register={register} label="Roof" name="data.roof" maxLength="100" />
                    <hr className="my-4"></hr>

                    <h4 className="mb-3" id="container-rooms" >Rooms</h4>
                    <button onClick={() => setRoomDialog({ show: true, id: roomIdx })} className="btn btn-link" type="button" >Add new room</button>
                    {rooms.map((room, index) =>
                        <div className="row mb-2" key={room.id} >
                            <div className="col">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <a href="#container-rooms" onClick={() => setRoomDialog({ show: true, id: room.id })} >{room.roomType}</a>
                                            <a href="#container-rooms" onClick={() => setRoomDeleteDialog({ show: true, id: index })} className="btn btn-link btn-sm text-danger"><FontAwesomeIcon icon={faX} /></a>
                                        </h5>
                                        {room.level && <p className="mb-0">Level: {room.level}</p>}
                                        {room.dimension1 && <p className="mb-0">Dimension 1: {room.dimension1}</p>}
                                        {room.dimension2 && <p className="mb-0">Dimension 2: {room.dimension2}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <hr className="my-4"></hr>
                    <TextInputField register={register} label="Listing provided courtesy of" name="data.listingProvidedCourtesyOf" maxLength="100" />

                    <div className="row justify-content-center mb-4">
                        <div className="col text-center">
                            <input className="form-control" type="file" onChange={onSelectFile} accept="image/*" />
                        </div>
                    </div>
                    <div style={{ display: "flex" }} >
                        {existingImages.map((picture, index) =>
                            <div key={picture.id} style={{ height: "150px", maxWidth: "150px", flex: "1", position: "relative", margin: "0.5rem" }}>
                                <img src={"/uploads/offer/" + picture.path} alt={picture.path} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                                <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteExistingImageConfirmationDialog({ show: true, id: index })}><FontAwesomeIcon icon={faX} /></button></span>
                            </div>
                        )}
                        {images.map((picture, index) =>
                            <div key={picture.id} style={{ height: "150px", maxWidth: "150px", flex: "1", position: "relative", margin: "0.5rem" }}>
                                <img src={picture} className='card-img-top' alt={index} style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                                <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeletePictureConfirmationDialog({ show: true, id: index })} ><FontAwesomeIcon icon={faX} /></button></span>
                            </div>
                        )}
                    </div>
                    <SubmitBar isSubmitting={isSubmitting} labelConfirm="Save" cancelLink="/offers" />
                </form>

                <ConfirmationDialog
                    show={roomDeleteDialog.show}
                    message="You are about to delete this room. Are you sure?"
                    handleConfirm={deleteRoom}
                    handleCancel={() => setRoomDeleteDialog({ show: false, id: null })}
                />
                <ConfirmationDialog
                    show={deletePictureConfirmationDialog.show}
                    message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                    handleConfirm={deletePicture}
                    handleCancel={() => setDeletePictureConfirmationDialog({ show: false })}
                />
                <ConfirmationDialog
                    show={deleteExistingImageConfirmationDialog.show}
                    message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                    handleConfirm={deleteExistingImage}
                    handleCancel={() => setDeleteExistingImageConfirmationDialog({ show: false })}
                />
                <Modal show={roomDialog.show} onHide={() => setRoomDialog({ show: false })} >
                    <Modal.Header closeButton>
                        <Modal.Title>Room details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <RealEstateRoom handleConfirmed={handleRoomAdded} id={roomDialog.id} room={rooms.find(element => element.id === roomDialog.id)} />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )

}