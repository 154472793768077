import { AudienceTitle, SubmitBar } from "_components"
import { fetchWrapper } from "_helpers"
import { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

export function Add({leadId, successCallBack}) {
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [audiences, setAudiences] = useState([]);

    // form validation rules
    const validationSchema = Yup.object({
        audience: Yup.string().required("Please select audience")
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        getValues,
        reset
    } = useForm(formOptions);
    const {errors, isSubmitting, isSubmitted, isSubmitSuccessful} = formState;

    useEffect(() => {
        if (showEditDialog === true) {
            reset()
            loadAudiences();
        }
    }, [showEditDialog])

    function loadAudiences() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audiences/active").then(response => {
            setAudiences(response.data);
        })
    }

    const onSubmit = async () => {
        await fetchWrapper.put(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/audience/" + getValues('audience')).then(response => {
            if (response.status === 200) {
                successCallBack()
            }
            setShowEditDialog(false)
        });
    };

    return (
        <>
            <a onClick={() => setShowEditDialog(true)} className="float-end cursor" style={{marginTop: "1.2px"}} ><img src="circle-plus-regular.svg" width={20} alt="audience-add"/></a>

            <Modal show={showEditDialog} onHide={() => setShowEditDialog(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Add To Audience</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-4">
                                    <label htmlFor="audience" className="mb-1">Audience</label>
                                    <select id="audience" {...register('audience')} className={`form-select ${errors.audience ? 'is-invalid' : ''}`}>
                                        <option value="">Choose...</option>
                                        {audiences.map(audience => <option key={audience.id} value={audience.id}><AudienceTitle audience={audience} /></option>)}
                                    </select>
                                    <div className="invalid-feedback">{errors.audience?.message}</div>
                                </div>
                                <SubmitBar isSubmitting={isSubmitting || (isSubmitted && isSubmitSuccessful)} labelConfirm="Confirm" showCancel={false} />
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}