import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '_components';
import { fetchWrapper } from '_helpers';
import { Personal } from './Personal';
import { Error } from './Error';
import Offer from './Offer';

export function Page() {
    const { channelName, invitationCode } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [landingPageResponse, setLandingPageResponse] = useState(null);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/destination/" + channelName + "/" + invitationCode).then(response => {
            if (response.status === 200) {
                setLandingPageResponse(response.data);
            } else {
                setError(true);
            }
            setLoading(false);
        });
    }, [])

    return (
        <>
            {loading === false ?
                <>
                    {error === true ?
                        <>
                            <div className="row">
                                <div className="col-sm-6 g-0 text-center">
                                    <img src="/login.png" alt="logo" className='img-fluid' />
                                </div>
                                <div className="col-sm-6 g-5">
                                    <h3 className='text-center'>Unknown landing page</h3>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <Error errorCode={landingPageResponse.errorCode} />
                            {landingPageResponse.destination === 'PERSONAL' && <Personal channelName={landingPageResponse.channelName} invitationCode={landingPageResponse.invitationCode} />}
                            {landingPageResponse.destination === 'OFFER' && <Offer channelName={landingPageResponse.channelName} invitationCode={landingPageResponse.invitationCode} />}
                        </>
                    }
                </>
                : <Loading />}
        </>
    )

}