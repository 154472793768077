import { constants, fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ConfirmationDialog, SubmitBar, TextAreaField, TextInputField } from "_components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import FileResizer from "react-image-file-resizer";


export function ScanAndSubscribe({ offer, selectedTemplateId }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pages, setPages] = useState([]);
    const [profile, setProfile] = useState(null);
    const [profilePhoneNumbers, setProfilePhoneNumbers] = useState([]);
    const [company, setCompany] = useState(null);
    const [companyPhoneNumbers, setCompanyPhoneNumbers] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);

    const [selectedLandingPage, setSelectedLandingPage] = useState(null);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

    const [mainImageSrc, setMainImageSrc] = useState(null);
    const [mainImageResizedSrc, setMainImageResizedSrc] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [leftImageSrc, setLeftImageSrc] = useState(null);
    const [leftImageResizedSrc, setLeftImageResizedSrc] = useState(null);
    const [leftImage, setLeftImage] = useState(null);
    const [rightImageSrc, setRightImageSrc] = useState(null);
    const [rightImageResizedSrc, setRightImageResizedSrc] = useState(null);
    const [rightImage, setRightImage] = useState(null);
    const [removeImages, setRemoveImages] = useState([false, false, false]);

    const [deletePictureConfirmationDialog, setDeletePictureConfirmationDialog] = useState({
        show: false
    });

    const [deleteLeftPictureConfirmationDialog, setDeleteLeftPictureConfirmationDialog] = useState({
        show: false
    });

    const [deleteRightPictureConfirmationDialog, setDeleteRightPictureConfirmationDialog] = useState({
        show: false
    });

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        data: Yup.object({
            welcomeMessage: Yup.string().required('Welcome message is a required field'),
            companyWebsite: Yup.string().nullable().matches(constants.websiteRegExp, { message: "Calendar URL be valid!", excludeEmptyString: true }),
            landingPageId: Yup.string().required('Landing page is a required field')
        })
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        if (offer) {
            loadPictures();
            setValue('title', offer.title);
            setValue('description', offer.description);
            let content = JSON.parse(offer.content);
            setValue('data.companyWebsite', content.companyWebsite);
            setValue('data.welcomeMessage', content.welcomeMessage);
            setValue('data.info', content.info);
            setValue('data.landingPageId', content.landingPageId);
            setValue('data.phoneNumber', content.phoneNumber);
            setSelectedLandingPage(content.landingPageId);
            setSelectedPhoneNumber(content.phoneNumber)
        } else {
            setValue('data.welcomeMessage', "Welcome");
        }
        loadPages()
        loadProfile()
        loadCompany()
    }, [offer])

    const processImage = async (imageSrc, setImageResizedSrc) => {
        if (imageSrc) {
            try {
                const blob = dataURLtoBlob(imageSrc);
                const resizedImage = await resizeFile(blob);
                setImageResizedSrc(resizedImage);
            } catch (error) {
                console.error('Error processing image:', error);
            }
        }
    };

    useEffect(() => {
        const handleImageProcessing = async () => {
            await processImage(mainImageSrc, setMainImageResizedSrc);
        };
        if (mainImageSrc) handleImageProcessing();
    }, [mainImageSrc]);

    useEffect(() => {
        const handleImageProcessing = async () => {
            await processImage(leftImageSrc, setLeftImageResizedSrc);
        };
        if (leftImageSrc) handleImageProcessing();
    }, [leftImageSrc]);

    useEffect(() => {
        const handleImageProcessing = async () => {
            await processImage(rightImageSrc, setRightImageResizedSrc);
        };
        if (rightImageSrc) handleImageProcessing();
    }, [rightImageSrc]);

    const loadPictures = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id + "/pictures").then(response => {
            let pictures = response.data;
            for (let index = 0; index < pictures.length; index++) {
                const picture = pictures[index];
                if (picture.info === 'mainImage') {
                    setMainImage(picture)
                } else if (picture.info === 'leftImage') {
                    setLeftImage(picture);
                } else if (picture.info === 'rightImage') {
                    setRightImage(picture);
                }
            }
        });
    }

    const loadPages = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/pages").then(response => {
            const data = response.data;
            setPages(response.data);
            if (selectedLandingPage === null) {
                const selectedPageId = data.filter(item => item.destination === "PERSONAL").map(item => item.id);
                setValue('data.landingPageId', selectedPageId[0]);
                setSelectedLandingPage(selectedPageId[0])
            }
        })
    }

    const loadProfile = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/my/personal").then(response => {
            setProfile(response.data);
            if (response.data.phoneNumbers) {
                setProfilePhoneNumbers(response.data.phoneNumbers);
            }
        })
    }

    const loadCompany = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/my/company").then(response => {
            setCompany(response.data);
            if (response.data.phoneNumbers) {
                setCompanyPhoneNumbers(response.data.phoneNumbers);
            }
            if (offer === undefined) {
                setValue("data.companyWebsite", response.data.website);
                if (response.data.name) {
                    setValue("data.welcomeMessage", "Welcome to " + response.data.name);
                }
                if (response.data.addresses) {
                    setValue("data.info", response.data.addresses[0].displayText);
                }
            }
        })
    }

    useEffect(() => {
        let tNumbers = new Set();
        for (let index = 0; index < profilePhoneNumbers.length; index++) {
            const phoneNumber = profilePhoneNumbers[index];
            tNumbers.add(phoneNumber.value);
        }
        for (let index = 0; index < companyPhoneNumbers.length; index++) {
            const phoneNumber = companyPhoneNumbers[index];
            tNumbers.add(phoneNumber.value);
        }
        setPhoneNumbers(Array.from(tNumbers));
    }, [profilePhoneNumbers, companyPhoneNumbers])

    function onLandingPageChanged(event) {
        setSelectedLandingPage(event.target.value);
    }

    const onSelectFile = (e, srcFunction) => {
        srcFunction(null);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                srcFunction(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const deleteImage = (setImageSrc, setImage, index, setDeletePictureConfirmationDialog) => {
        setImageSrc(null)
        setImage(null)
        if (index !== undefined) {
            let tempRemoveImages = [...removeImages];
            tempRemoveImages[index] = true;
            setRemoveImages(tempRemoveImages);
        }
        setDeletePictureConfirmationDialog({ show: false });
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            FileResizer.imageFileResizer(
                file,
                2048,
                1080,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    function dataURLtoBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const onSubmit = async () => {
        const formData = new FormData();
        const addImageToFormData = (imageSrc, fileName) => {
            if (imageSrc) {
                formData.append("files", imageSrc, fileName);
            }
        };
        addImageToFormData(mainImageResizedSrc, "mainImage");
        addImageToFormData(leftImageResizedSrc, "leftImage");
        addImageToFormData(rightImageResizedSrc, "rightImage");
        if (offer) {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "data": getValues('data') }))
            formData.append("removeImages", removeImages)
            await fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id, formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        } else {
            formData.append("offer", JSON.stringify({ "title": getValues('title'), "description": getValues('description'), "templateId": selectedTemplateId, "data": getValues('data') }))
            await fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/vendor/offer", formData).then(response => {
                if (response.status === 200) {
                    navigate("/offers");
                }
            });
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container scan-and-subcribe">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength="100" />
                            <TextInputField register={register} label="Subtitle" name="description" error={errors.description} textarea={true} />
                        </div>
                    </div>
                    <div className="mb-3" style={{ borderBottom: ".07rem dotted" }}>&nbsp;</div>
                    <div className="row mt-3 mb-3">
                        <div className="col-3">
                            <TextInputField register={register} placeholder="Company website" name="data.companyWebsite" maxLength="100" error={errors.data?.welcomeMessage} />
                            <div className="form-group mb-4">
                                <select id="phoneNumber" {...register('data.phoneNumber')} className={`form-select`}>
                                    <option value="">Choose Phone number</option>
                                    {phoneNumbers.map(phoneNumber =>
                                        <option key={phoneNumber} value={phoneNumber} selected={phoneNumber === selectedPhoneNumber}>
                                            {phoneNumber}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3 mb-2 justify-content-center">
                        <div className="col-5 text-center">
                            <TextInputField register={register} placeholder="Welcome message" name="data.welcomeMessage" error={errors.data?.welcomeMessage} maxLength="100" />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col text-center" >
                            {mainImage === null ?
                                mainImageSrc === null ?
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem", height: "23rem", borderRadius: ".475rem", border: ".01rem solid" }}>
                                        <div className="form-group mb-2 position-absolute top-50 start-50 translate-middle rounded-pill ">
                                            <input className="form-control" type="file" onChange={(e) => onSelectFile(e, setMainImageSrc)} accept="image/*" max={1} />
                                        </div>
                                    </div>
                                    :
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={mainImageSrc} style={{ objectFit: "cover", height: "23rem", width: "100%" }} alt={mainImageSrc} />
                                        <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeletePictureConfirmationDialog({ show: true })}><FontAwesomeIcon icon={faX} /></button></span>
                                    </div>
                                :
                                <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                    <img src={"/uploads/offer/" + mainImage.path} style={{ objectFit: "cover", height: "23rem", width: "100%" }} alt={mainImage.path} />
                                    <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeletePictureConfirmationDialog({ show: true, id: 0 })}><FontAwesomeIcon icon={faX} /></button></span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row mt-4 mb-4 justify-content-center">
                        <div className="col-4" style={{ borderRight: ".15rem solid" }}>
                            <TextAreaField
                                register={register}
                                setValue={setValue}
                                placeholder="Address"
                                name="data.info"
                                error={errors.data?.info}
                                maxLines={8}
                                rows={8} />
                        </div>
                        <div className="col-4" style={{ borderRight: ".15rem solid" }}>
                            <h4 className="mt-4 text-center montserrat-text">PLEASE SCAN & SUBSCRIBE</h4>
                            <div className="row justify-content-center">
                                <div className="col-9">
                                    <div className="form-group mb-4">
                                        <label htmlFor="pageType" className="mb-1">Landing page</label>
                                        <select id="pageType" {...register('data.landingPageId', { onChange: onLandingPageChanged })} className={`form-select ${errors.pageType ? 'is-invalid' : ''}`}>
                                            {pages.map(landingPage =>
                                                <option key={landingPage.id} value={landingPage.id} selected={landingPage.id == selectedLandingPage}>
                                                    {landingPage.title !== null ? landingPage.title : t("LANDINGPAGETYPE." + landingPage.destination)}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            {profile &&
                                <>
                                    <h4 className="mt-1">&nbsp;</h4>
                                    <div className="row business-card-body-content" >
                                        <div className="col">
                                            {profile.profileImage &&
                                                <div className="text-center" >
                                                    <img src={"/uploads/user/" + profile.profileImage} alt={profile.profileImage} style={{ maxHeight: "8.5rem", borderRadius: ".475rem" }} />
                                                </div>
                                            }
                                            <h3 className="mt-2 mb-0 business-card-lastname text-center montserrat-text">{profile.firstName} {profile.lastName}</h3>
                                            <h5 className="mb-0 text-center montserrat-text">{profile.occupation}</h5>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-6 text-center">
                            {leftImage === null ?
                                leftImageSrc === null ?
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem", height: "23rem", borderRadius: ".475rem", border: ".01rem solid" }}>
                                        <div className="form-group mb-2 position-absolute top-50 start-50 translate-middle rounded-pill ">
                                            <input className="form-control" type="file" onChange={(e) => onSelectFile(e, setLeftImageSrc)} accept="image/*" max={1} />
                                        </div>
                                    </div>
                                    :
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={leftImageSrc} style={{ objectFit: "cover", height: "23rem", width: "100%" }} />
                                        <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteLeftPictureConfirmationDialog({ show: true })}><FontAwesomeIcon icon={faX} /></button></span>
                                    </div>
                                :
                                <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                    <img src={"/uploads/offer/" + leftImage.path} style={{ objectFit: "cover", height: "23rem", width: "100%" }} />
                                    <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteLeftPictureConfirmationDialog({ show: true, id: 1 })}><FontAwesomeIcon icon={faX} /></button></span>
                                </div>
                            }
                        </div>
                        <div className="col-6 text-center">
                            {rightImage === null ?
                                rightImageSrc === null ?
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem", height: "23rem", borderRadius: ".475rem", border: ".01rem solid" }}>
                                        <div className="form-group mb-2 position-absolute top-50 start-50 translate-middle rounded-pill ">
                                            <input className="form-control" type="file" onChange={(e) => onSelectFile(e, setRightImageSrc)} accept="image/*" max={1} />
                                        </div>
                                    </div>
                                    :
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={rightImageSrc} style={{ objectFit: "cover", height: "23rem", width: "100%" }} />
                                        <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteRightPictureConfirmationDialog({ show: true })}><FontAwesomeIcon icon={faX} /></button></span>
                                    </div>
                                :
                                <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                    <img src={"/uploads/offer/" + rightImage.path} style={{ objectFit: "cover", height: "23rem", width: "100%" }} />
                                    <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteRightPictureConfirmationDialog({ show: true, id: 2 })}><FontAwesomeIcon icon={faX} /></button></span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <SubmitBar isSubmitting={isSubmitting} labelConfirm="Save" cancelLink="/offers" />
                    </div>
                </div>
            </form>
            <ConfirmationDialog
                show={deletePictureConfirmationDialog.show}
                message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                handleConfirm={() => deleteImage(setMainImageSrc, setMainImage, deletePictureConfirmationDialog.id, setDeletePictureConfirmationDialog)}
                handleCancel={() => setDeletePictureConfirmationDialog({ show: false })}
            />
            <ConfirmationDialog
                show={deleteLeftPictureConfirmationDialog.show}
                message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                handleConfirm={() => deleteImage(setLeftImageSrc, setLeftImage, deleteLeftPictureConfirmationDialog.id, setDeleteLeftPictureConfirmationDialog)}
                handleCancel={() => setDeleteLeftPictureConfirmationDialog({ show: false })}
            />
            <ConfirmationDialog
                show={deleteRightPictureConfirmationDialog.show}
                message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                handleConfirm={() => deleteImage(setRightImageSrc, setRightImage, deleteRightPictureConfirmationDialog.id, setDeleteRightPictureConfirmationDialog)}
                handleCancel={() => setDeleteRightPictureConfirmationDialog({ show: false })}
            />
        </>
    )


}