import { useEffect, useState } from "react";
import { LeadInvitation } from "./leads";
import { Modal } from "react-bootstrap";
import { fetchWrapper } from "_helpers";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

export function Actions() {
    const authUser = useSelector(x => x.auth.user);
    const [showInvitationDialog, setShowInvitationDialog] = useState(false);
    const [invitationDialog, setInvitationDialog] = useState({
        show: false,
        contactDetails: null
    });
    const [qrCodeDialog, setQrCodeDialog] = useState({
        show: false,
        invitationCode: null
    });

    useEffect(() => {
        if (qrCodeDialog.show) {
            getPersonalInvitationCode();
        }
    }, [qrCodeDialog.show]);

    function getPersonalInvitationCode() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/lead-invitation").then(response => {
            setQrCodeDialog({...qrCodeDialog, invitationCode: response.data.value});
        });
    }

    const downloadBusinessCardApple = () => {
        fetchWrapper.plainFetch(process.env.REACT_APP_API_URL + "/vendor/wallet/apple/business-card").then(response => {
            return response.arrayBuffer();
        }).then(data => {
            saveByteArray(authUser.firstName + ".pkpass", new Uint8Array(data));
        }).catch(error => {
            console.error('Error fetching byte array:', error);
        });
    }

    function saveByteArray(reportName, byte) {
        var blob = new Blob([byte], {type: "application/vnd.apple.pkpass"});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var fileName = reportName;
        link.download = fileName;
        link.click();
    };

    return (
        <>
            <div className="row" id="dashboard-actions">
                <div className="col" >
                    <button onClick={() => {setShowInvitationDialog(true);setInvitationDialog({show:false}) }} className="btn btn-success rounded-pill" >Invite Lead</button>
                    <div className="float-end mt-1">
                        <a onClick={() => {setQrCodeDialog({show: true})}} className="cursor"><img src="/id-card-light.svg" alt="personal" width={32} height={32}/></a>
                    </div>
                    {(invitationDialog.show && invitationDialog.contactDetails) && 
                        <div className="alert alert-success alert-dismissible fade show mt-3 mb-0">
                            <p className="text-success mb-0">An invitation to {invitationDialog.contactDetails} sent.</p>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}
                </div>
            </div>
            <LeadInvitation show={showInvitationDialog} handleCallback={(contactDetails) => {setShowInvitationDialog(false);setInvitationDialog({show: true, contactDetails: contactDetails});}}/>
            <Modal show={qrCodeDialog.show} onHide={() => setQrCodeDialog({show: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>Scan the QR code and connect with me.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {qrCodeDialog.invitationCode &&
                        <>
                            <div className="text-center">
                                <QRCode
                                    id="lead-qr"
                                    size={128}
                                    style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                    value={process.env.REACT_APP_BASE_URL + "/" + authUser.channelName + "/" + qrCodeDialog.invitationCode}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className="text-center mt-3">
                                <a onClick={() => downloadBusinessCardApple()} download={"Uros"} className="cursor"><img src="/US-UK_Add_to_Apple_Wallet_RGB_101421.svg" alt="apple-wallet" width={256} height={48}/></a>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )

}