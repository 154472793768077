import { Loading, NoResults, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Message, NewLeadMessage } from ".";
import AppContext from "_context/AppContext";

export function Messages({leadId, reload}) {
    const [messages, setMessages] = useState([]);
    const { setReloadMessages } = useContext(AppContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [editDialog, setEditDialog] = useState({
        show: false
    });

    useEffect(() => {
        loadMessages();
    }, [page, leadId]);

    useEffect(() => {
        if (reload === true) {
            loadMessages();
        }
    }, [reload]);

    const loadMessages = () => {
        setLoading(true);
        setMessages([]);
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/messages", {"pageNumber": page, "pageSize": 5}).then(response => {
            if (response.status === 200) {
                setTotalPages(response.data.totalPages);
                setTotalElements(response.data.totalElements)
                setMessages(response.data.content);
                setLoading(false);
                setReloadMessages(false);
            }
        });
    }

    const newMessageCallback = async (data) => {
        await fetchWrapper.put(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/message", data).then(response => {
            loadMessages();
            setEditDialog({show: false})
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    return (
        <>
            <div className="row mt-2" style={{backgroundColor: "#e2e2e2"}} id="messages-section">
                <div className="col-10">
                    <h6 className="mt-1 mb-1"><strong>Messages{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>  
                </div>
                <div className="col-2">
                    <a onClick={() => {setEditDialog({show: true})}} className="float-end cursor" ><img src="circle-plus-regular.svg" width={20} alt="message-add"/></a>
                </div>
            </div>
            {loading === true ? 
                <div className="row justify-content-center">
                    <div className="col-8">
                        <Loading />
                    </div>
                </div>
                :
                <>
                    {messages.length === 0 ?
                        <div className="row justify-content-center">
                            <div className="col">
                                <NoResults />
                            </div>
                        </div>
                    :
                    messages.map((item, index) => 
                        <div className="row" key={item.code}>
                            {item.leadMessageType === 'OUTGOING' && <div className="col-4">&nbsp;</div>}
                            <div className="col-8">
                                <Message item={item} index={index} />
                            </div>
                        </div>
                        )
                    }
                </>
            }
            <div className="row justify-content-center">
                <div className="col">
                    <Paginate totalPages={totalPages} handlePageClick={handlePageClick}/>
                </div>
            </div>
            <Modal show={editDialog.show} onHide={() => setEditDialog({show: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>New Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewLeadMessage callback={newMessageCallback} />
                </Modal.Body>
            </Modal>
        </>
    )

}