import { useState } from "react";
import { Modal } from "react-bootstrap";

export function HeaderPhones({phoneNumbers}) {
    const [phoneNumbersDialog, setPhoneNumbersDialog] = useState(false);

    return (
        <>
            {phoneNumbers &&
            <>
                {phoneNumbers.length === 1 ?
                <>
                    <a className="btn" href={"tel:" + phoneNumbers[0].value}>
                        <img src="/phone-sharp-regular.svg" alt="phone" width={24} />
                    </a>
                    <p className="mb-0">Call</p>
                </>
                :
                <>
                    <button className="btn" onClick={() => setPhoneNumbersDialog(true)}>
                        <img src="/phone-sharp-regular.svg" alt="phone" width={24} />
                    </button>
                    <p className="mb-0">Call</p>
                </>
                }
            </>
            }
            {phoneNumbersDialog &&
                <Modal show={phoneNumbersDialog} onHide={() => setPhoneNumbersDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Phone numbers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {phoneNumbers.length > 0 &&
                            phoneNumbers.map(phoneNumber => 
                                <div key={phoneNumber.value}>
                                    <strong>{phoneNumber.title}:</strong> <a className='mb-0' href={"tel:" + phoneNumber.value}>{phoneNumber.value}</a>
                                </div>
                            )
                        }
                    </Modal.Body>
                </Modal>
            }
        </>
    )

}