import { Modal, Tab, Tabs } from "react-bootstrap";

export function ImagePreview({show, imageSrc, mobileImageSrc, handleCancel}) {

    return (
        <Modal show={show} onHide={handleCancel} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="desktop"  className="mb-3">
                    <Tab eventKey="desktop" title="Desktop" className="text-center">
                        <img src={"/uploads/offer/" + imageSrc} className="img-fluid" alt="desktop" />
                    </Tab>
                    <Tab eventKey="mobile" title="Mobile" className="text-center">
                        <img src={"/uploads/offer/" + mobileImageSrc} className="img-fluid" alt="mobile" />
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
        
    )

}