import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from "react";
import { Modal } from "react-bootstrap";

import { constants, fetchWrapper } from '_helpers';
import { ConfirmationCode, PhoneNumberInputField, TextInputField } from "_components";
import { LeadOfferStates, ResolveConflict } from "lead";
import { Link } from "react-router-dom";

export function Subscribe({ label, callToAction, welcomeMessage, channelName, invitationCode }) {
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [subscribed, setSubscribed] = useState(null);
    const [show, setShow] = useState(false);
    const [showManage, setShowManage] = useState(false);

    // form validation rules
    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Please enter your first name'),
        lastName: Yup.string().required('Please enter your last name'),
        'email': Yup.string().when('phoneNumber', {
            is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
            then: Yup.string().required('Email or phone number is required field').email("Please enter a valid email address"),
        }),
        'phoneNumber': Yup.string().matches(constants.phoneRegExp, 'Phone number is not valid')
    }, ['email', 'phoneNumber']);
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        getValues,
        setError
    } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const onSubmit = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/subscribe/" + channelName + "/" + invitationCode, getValues()).then(response => {
            if (response.status === 540) {
                setShow(true);
            }
            if (response.status === 541) {
                setShowManage(true);
            }
            if (response.status === 200) {
                setSubscribed(response.data);
            }
            if (response.status === 536) {
                setError("phoneNumber", { type: 'custom', message: "Phone number is not valid! Please try again" }, { shouldFocus: true });
            }
            if (response.status === 553) {
                setError("phoneNumber", { type: 'custom', message: "Something went wrong. Please try again." }, { shouldFocus: true });
            }
        }).catch(() => {
            setError("phoneNumber", {
                type: 'custom',
                message: "Something went wrong. Please try again."
            }, { shouldFocus: true });
        });
    }

    function handleChecked(event) {
        setAcceptedTerms(event.target.checked);
    }

    return (
        <>
            {subscribed !== null ?
                <>
                    <div className="alert alert-warning mt-3 mb-0">
                        {welcomeMessage ?
                            welcomeMessage
                            : <p>Thanks for sharing. <br />Do you like ABC? <Link to={"/register"}>Get yours for FREE</Link></p>
                        }
                    </div>
                    {subscribed.offerCode &&
                        <LeadOfferStates
                            channelName={channelName}
                            leadCode={subscribed.leadCode}
                            offerCode={subscribed.offerCode}
                        />
                    }
                </>
                :
                <>
                    {label && <p className="text-center mb-0" style={{ fontSize: "large" }}><strong>{label}</strong></p>}
                    {callToAction && <p className="mb-3">{callToAction}</p>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextInputField name="firstName" label="First Name" error={errors.firstName} register={register} />
                        <TextInputField name="lastName" label="Last Name" error={errors.lastName} register={register} />
                        <PhoneNumberInputField label="Cell" name="phoneNumber" control={control} />
                        <TextInputField name="email" label="E-mail" error={errors.email} register={register} />
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="accept-terms" onChange={handleChecked} />
                            <label className="form-check-label" htmlFor="accept-terms">I give <a href="">Consent</a> and Accept <a href="">Terms and Conditions</a></label>
                        </div>
                        <div className="d-grid gap-2 mt-1 mb-3">
                            <button disabled={isSubmitting || acceptedTerms === false}
                                className="btn btn-lg btn-dark mt-3 rounded-pill">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                Confirm
                            </button>
                        </div>
                    </form>
                    <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm your identity</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ConfirmationCode
                                url={"/subscribe/" + channelName + "/" + invitationCode}
                                callback={(confirmationCode, status, data) => setSubscribed(data)}
                            />
                        </Modal.Body>
                    </Modal>

                    <Modal show={showManage} onHide={() => setShowManage(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Manage your identity</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ResolveConflict
                                conflictValues={getValues}
                                channelName={channelName}
                                invitationCode={invitationCode}
                                callback={(status, data) => { setShowManage(false); setSubscribed(data); }}
                            />
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )

}