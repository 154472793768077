import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";

export function Logo({logo, avatar, path, deleteAllowed, handleDelete}) {

    return (
        <>
        {logo ?
            <>
                <div className="card" style={{border: "none"}}>
                    <img src={path + "/" + logo} alt={logo} className='card-img-top' />
                    {deleteAllowed && <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light"><button type="button" className="btn btn-link btn-sm text-danger" onClick={handleDelete}><FontAwesomeIcon icon={faX}/></button></span>}
                </div>
            </>
            : 
            <>
                <Avatar name={avatar} round={true} size="75" />
            </>
        }
        </>
    )
}