import {useState} from "react";
import {useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/free-solid-svg-icons'
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { fetchWrapper, constants } from '_helpers';

export {
    CompanyIndustry
}

function CompanyIndustry(props) {
    const authUser = useSelector(x => x.auth.user);
    const [company, setCompany] = useState(props.company);
    const [editMode, setEditMode] = useState(false);

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm();
    const {errors} = formState;

    function editProperty() {
        setValue('value', company.industry);
        setEditMode(true);
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/accountowner/company/industry", getValues()).then(response => {
            setEditMode(false);
            setCompany(response.data);
        });
    }

    return (
        <div className="mb-3">
            <p className="mb-0">Industry</p>
            <p className="text-secondary mb-0 fs-6">
                Choose the industry you work in and help us to customize the app to better serve your needs.
            </p>
            {editMode === true ? 
            <>
                <form onSubmit={
                        handleSubmit(saveProperty)
                    }>
                <div className="row g-2">
                <div className="col-sm">
                    <select id="industry" {...register('value')} className='form-select'>
                        <option defaultValue=" ">Choose...</option>
                        {
                        constants.industries?.map((industry, index) => 
                            <option key={index} value={industry}>{industry}</option>)
                        } 
                    </select>
                </div>
                <div className="col">
                    <button type="submit" className="btn btn-primary" >Confirm</button>
                    <button type="button" className="btn btn-light" onClick={() => setEditMode(false)}>Cancel</button>
                </div>
                </div>
                </form>
            </>
            :
            <p className="mt-0">{company.industry}
                {authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true ? <a className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil}/></a> : null}
            </p>
            }
        </div>
    )

}