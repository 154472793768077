import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { TextInputField } from "./base/TextInputField";
import { CountriesSelectField } from "./CountriesSelectField";
import { SubmitBar } from "./base/SubmitBar";
import { useEffect } from "react";

export function AddressForm({onSubmitCallback, data}) {

    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        address1: Yup.string().required('Address 1 is required field'),
        postalCode: Yup.string().required('Zip is required field'),
        city: Yup.string().required('City is required field'),
        countryId: Yup.string().required('Country is required field'),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        clearErrors
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    useEffect(() => {
        clearErrors();
        resetField("title");
        resetField("address1");
        resetField("address2");
        resetField("postalCode");
        resetField("state");
        resetField("city");
        if (data) {
            if (data.id) {
                setValue('id', data.id);
            }
            setValue('title', data.title);
            setValue('address1', data.address1);
            setValue("address2", data.address2);
            setValue("postalCode", data.postalCode);
            setValue("state", data.state);
            setValue("city", data.city);
            setValue("countryId", data.countryId);
            if (data.key) {
                setValue('key', data.key);
            }
        }
    }, [])

    const onSubmit = () => {
        onSubmitCallback(getValues());
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength={100} />
                <TextInputField register={register} label="Address 1" name="address1" error={errors.address1} maxLength={100} />
                <TextInputField register={register} label="Address 2" name="address2" error={errors.address2} maxLength={100} />
                <CountriesSelectField register={register} setValue={setValue} name="countryId" error={errors.countryId} selected={data?.countryId} />
                <TextInputField register={register} label="State" name="state" error={errors.state} maxLength={100} />
                <TextInputField register={register} label="City" name="city" error={errors.city} maxLength={100} />
                <TextInputField register={register} label="Zip" name="postalCode" error={errors.postalCode} maxLength={100} />
                <SubmitBar labelConfirm="Save" showCancel={false} isSubmitting={isSubmitting}/>
            </form>
        </>
    )

}