import { useState } from "react";
import { Modal } from "react-bootstrap";

export function HeaderEmails({emails}) {
    const [emailsDialog, setEmailsDialog] = useState(false);

    return (
        <>
            {emails &&
            <>
                {emails.length === 1 ?
                <>
                    <a className="btn" href={"tel:" + emails[0].value}>
                        <img src="/envelope-sharp-regular.svg" alt="email" width={24} />
                    </a>
                    <p className="mb-0">Email</p>
                </>
                :
                <>
                    <button className="btn" onClick={() => setEmailsDialog(true)}>
                        <img src="/envelope-sharp-regular.svg" alt="email" width={24} />
                    </button>
                    <p className="mb-0">Email</p>
                </>
                }
            </>
            }
            {emailsDialog &&
                <Modal show={emailsDialog} onHide={() => setEmailsDialog(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Emails</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {emails.length > 0 &&
                            emails.map(email => 
                                <div key={email.value}>
                                    <strong>{email.title}:</strong> <a className='mb-0' href={"tel:" + email.value}>{email.value}</a>
                                </div>
                            )
                        }
                    </Modal.Body>
                </Modal>
            }
        </>
    )

}