export function EmailsPreview({emails}) {

    return (
        <>
        {emails.map(email =>
            <div key={email.value}>
                <p className="mb-0"><strong>{email.title}:</strong> {email.value}</p>
            </div>
        )}
        </>
    )

}