import { Loading, QRCodePreview } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";

export function ScanAndSubscribe({ offer, pictures, channelName, invitationCode }) {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [content, setContent] = useState(null);
    const [pageUrl, setPageUrl] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [leftImage, setLeftImage] = useState(null);
    const [rightImage, setRightImage] = useState(null);

    useEffect(() => {
        if (offer !== undefined) {
            let content = JSON.parse(offer.content);
            setContent(content);
            setPageUrl(process.env.REACT_APP_BASE_URL + "/" + channelName + "/" + invitationCode);
        }
        loadPersonalDetails();
    }, [offer.code])

    useEffect(() => {
        if (pictures) {
            for (let index = 0; index < pictures.length; index++) {
                const picture = pictures[index];
                if (picture.info === 'mainImage') {
                    setMainImage(picture)
                } else if (picture.info === 'leftImage') {
                    setLeftImage(picture);
                } else if (picture.info === 'rightImage') {
                    setRightImage(picture);
                }
            }
        }
    }, [pictures])

    const loadPersonalDetails = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/personal/" + channelName + "/offer/" + offer.code).then(response => {
            setProfile(response.data);
            setLoading(false);
        });
    }

    return (
        <>
            {loading && <Loading />}
            {profile &&
                <>
                    <div className="container container-scan-and-subcribe montserrat-text">
                        <div className="row">
                            <div className="col">
                                <p className="mt-4 mb-0 ms-2">{content.companyWebsite}</p>
                                <p className="ms-2">{content.phoneNumber}</p>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2 justify-content-center">
                            <div className="col text-center">
                                <h3>{content.welcomeMessage}</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col text-center" >
                                {mainImage &&
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={"/uploads/offer/" + mainImage.path} style={{ objectFit: "cover", height: "23rem", width: "100%" }} alt={mainImage.path} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-4 mb-4 justify-content-center">
                            <div className="col-4" style={{ borderRight: ".05rem solid" }}>
                                {content.info && <p className="mb-0 ms-2" style={{ whiteSpace: "pre-line" }}>{content.info}</p>}
                            </div>
                            <div className="col-4" style={{ borderRight: ".05rem solid" }}>
                                <h4 className="text-center mb-3">PLEASE SCAN & SUBSCRIBE</h4>
                                <div className="row mt-2 justify-content-center">
                                    <div className="col-5 col-lg-4">
                                        {pageUrl &&
                                            <QRCodePreview value={pageUrl} maxWidth={64} />
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                {profile &&
                                    <>
                                        <div className="row" >
                                            <div className="col">
                                                {profile.profileImage &&
                                                    <div className="text-center mt-3" >
                                                        <img src={"/uploads/user/" + profile.profileImage} alt={profile.profileImage} style={{ maxHeight: "5.5rem", borderRadius: ".475rem" }} />
                                                    </div>
                                                }
                                                <h4 className="mt-2 mb-0 text-center">{profile.firstName} {profile.lastName}</h4>
                                                <h5 className="mb-0 text-center">{profile.occupation}</h5>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row g-0 justify-content-center">
                            <div className="col-6 text-center">
                                {leftImage &&
                                    <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={"/uploads/offer/" + leftImage.path} alt={leftImage.path} className='card-img-top' style={{ objectFit: "cover", height: "15rem", width: "100%" }} />
                                    </div>
                                }
                            </div>
                            <div className="col-6 text-center">
                                {rightImage &&
                                    <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={"/uploads/offer/" + rightImage.path} alt={rightImage.path} className='card-img-top' style={{ objectFit: "cover", height: "15rem", width: "100%" }} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )

}