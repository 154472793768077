import { SubmitBar } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export function OfferState({ channelName, leadCode, offerCode, successCallback }) {
    const [offerStates, setOfferStates] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [stateUpdated, setStateUpdated] = useState(false);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setError
    } = useForm();
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        loadOfferStates()
    }, [])

    const loadOfferStates = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + leadCode + "/offer/" + offerCode + "/states").then(response => {
            if (response.status === 200) {
                setOfferStates(response.data)
                getOfferState()
            }
        });
    }

    const getOfferState = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + leadCode + "/offer/" + offerCode + "/state").then(response => {
            if (response.status === 200) {
                setSelectedState(response.data)
            }
        });
    }

    const onSubmit = () => {
        setStateUpdated(false)
        fetchWrapper.patch(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + leadCode + "/offer/" + offerCode + "/state/" + getValues('state')).then(() => {
            setStateUpdated(true)
        }).catch(() => {
            setError("state", {
                type: 'custom',
                message: "Something went wrong. Please try again."
            }, { shouldFocus: true });
        });
    }

    return (
        <>
            {offerStates && offerStates.length > 0 &&
            <>
                {stateUpdated &&
                    <div className="alert alert-success mt-3 mb-0">
                        <p className="mb-0">Thank you for response!</p>
                    </div>
                }
                <form onSubmit={handleSubmit(onSubmit)} type="submit">
                    <div className="form-group mt-4">
                        <label htmlFor="type" className="mb-1">Please respond to event</label>
                        <select id="type" {...register('state')} className={`form-select ${errors.state ? 'is-invalid' : ''}`} onClick={() => setStateUpdated(false)}>
                            {offerStates.map(item =>
                                <option key={item.id} value={item.id} selected={selectedState?.state === item.state ? true : false} >{t("LEADOFFERSTATE." + item.state)}</option>
                            )}
                        </select>
                        <div className="invalid-feedback">{errors.state?.message}</div>
                    </div>
                    <SubmitBar showCancel={false} labelConfirm="Save" isSubmitting={isSubmitting} />
                </form>
            </>
            }
        </>
    )

}