import Offer from "page/Offer";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

export function Preview({show, code, invitationCode, cancelCallback}) {
    const authUser = useSelector(x => x.auth.user);

    return (
        <Modal show={show} onHide={cancelCallback} fullscreen={true}>
            <Modal.Header closeButton>
            <Modal.Title>Offer Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: "0px"}}>
                <Offer channelName={authUser.channelName} code={code} invitationCode={invitationCode} preview={true} />
            </Modal.Body>
        </Modal>
    )

}