import Modal from 'react-bootstrap/Modal';

export function ConfirmationDialog({show, showCancel, message, bodyText, handleConfirm, handleCancel}) {

    return (
        <Modal show={show} onHide={handleCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {message !== undefined ? message : "You are about to delete this field. Are you sure?"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {bodyText && <p>{bodyText}</p>}
                <button onClick={handleConfirm} type="button" className="btn btn-dark mt-3 me-1 rounded-pill">Confirm</button>
                {showCancel !== undefined && showCancel === false ? null :
                    <button onClick={handleCancel} type="button" className="btn btn-secondary mt-3 me-1 rounded-pill" >Cancel</button>
                }
            </Modal.Body>
        </Modal>
    )

}
