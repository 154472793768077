import FileSaver from "file-saver";
import VCard from "vcard-creator";

export function VCardPersonal({profile, phoneNumbers, addresses, company}) {

    function createVCard() {
        const vCard = new VCard()
        vCard
            .addName(profile.lastName, profile.firstName)
            .addEmail(profile.email);
        for (let index = 0; index < phoneNumbers.length; index++) {
            const phoneNumber = phoneNumbers[index];
            if (phoneNumber.value) {
                vCard.addPhoneNumber(phoneNumber.value, phoneNumber.title);
            }
        }
        for (let index = 0; index < addresses.length; index++) {
            const address = addresses[index];
            if (address.address1) {
                vCard.addAddress("", "", address.address1, address.city, address.state, address.postalCode, address.countryName);
            }
        }
        if (company.name) {
            vCard.addCompany(company.name)
        }
        if (profile.linkedin) {
            vCard.addSocial(profile.linkedin, 'LinkedIn');
        }
        if (profile.facebook) {
            vCard.addSocial(profile.facebook, 'Facebook');
        }
        if (profile.twitter) {
            vCard.addSocial(profile.twitter, 'Twitter');
        }
        if (profile.instagram) {
            vCard.addSocial(profile.instagram, 'Instagram');
        }
        if (profile.tiktok) {
            vCard.addSocial(profile.tiktok, 'TikTok');
        }
        const blob = new Blob([ vCard.toString() ], {type: "text/vcard;charset=utf-8"});
        FileSaver.saveAs(blob, profile.firstName + " " + profile.lastName + ".vcf");
    }

    return (
        <button className="btn btn-dark btn-10 float-end" onClick={() => createVCard()}>Save contact</button>
    )

}