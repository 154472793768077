import {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil, faX} from '@fortawesome/free-solid-svg-icons'

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog } from ".";

export function ProfileField({data, name, label, icon, apiEndpoint, editAllowed, deleteAllowed}) {
    const [profile, setProfile] = useState(data);
    const [editMode, setEditMode] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const validationSchema = Yup.object({
        value: Yup.string().nullable().required('Please enter a valid value')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        clearErrors
    } = useForm(formOptions);
    const {errors} = formState;

    function editProperty() {
        setValue('value', profile[name]);
        clearErrors();
        setEditMode(true);
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + apiEndpoint, getValues()).then(response => {
            setEditMode(false);
            setProfile(response.data);
        });
    }

    function deleteProperty() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + apiEndpoint).then(response => {
            setProfile(response.data);
            setShowConfirmationDialog(false);
        });
    }

    return (
        <div className="mb-2">
            {icon && <img src={icon} alt={name} width={24} height={24} className="me-2" />}
            {label && <p className="mb-0">{label}</p>}
            {editMode === true ? 
                <form onSubmit={handleSubmit(saveProperty)}>
                    <div className="row g-2">
                    <div className="col-sm">
                        <input {...register('value')} type="text" className={`form-control ${errors.value ? 'is-invalid' : ''}`}></input>
                        <div className="invalid-feedback">{errors.value?.message}</div>
                    </div>
                    <div className="col">
                        <button type="submit" className="btn btn-primary" >Confirm</button>
                        <button type="button" className="btn btn-light" onClick={() => setEditMode(false)}>Cancel</button>
                    </div>
                    </div>
                </form>
            :
            <>
                {profile[name]}
                {editAllowed &&
                    <>
                        <button type="button" className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil}/></button>
                        {profile[name] && deleteAllowed === true && <button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setShowConfirmationDialog(true)}><FontAwesomeIcon icon={faX}/></button>}
                    </>
                }
                {showConfirmationDialog && 
                    <ConfirmationDialog 
                        show={showConfirmationDialog}
                        handleConfirm={deleteProperty} 
                        handleCancel={() => setShowConfirmationDialog(false)}
                    />
                }
            </>
            }
        </div>
    )
}