import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import {saveSvgAsPng} from 'save-svg-as-png';


export function QRCodePreview({value, downloadAllowed}) {

    function handleDownload() {
        saveSvgAsPng(document.getElementById(value), "qrcode.png", {scale: 10});
    }

    return (
        <div className="card" style={{border: 'none'}}>
            <QRCode
                id={value}
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={value}
                viewBox={`0 0 256 256`}
            />
            {downloadAllowed === true && <span className="position-absolute top-100 start-100 translate-middle rounded-pill bg-success"><button onClick={() => handleDownload()} className="btn btn-link" alt="qrcode" ><FontAwesomeIcon icon={faDownload}/></button></span>}
        </div>
    )

}