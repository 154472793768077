import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from '_helpers';
import { Nav } from '../Nav';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    
    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />
    }

    // authorized so return child components
    return (
        <div className="app-container">
            <Nav />
            <div className="container justify-content-center">
                {children}
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center py-3 mt-5" style={{backgroundColor: "#F2F3FA"}}>
                <div className='d-none d-lg-block d-md-block' >
                    <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2024. All rights reserved.</p>
                </div>
                <div className="d-block d-sm-none">
                    <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2024.</p>
                    <p className="mb-0 text-muted text-center">All rights reserved.</p>
                </div>
                <ul className="nav justify-content-center">
                <li className="nav-item"><a href="/dashboard" className="nav-link px-2 text-muted">Home</a></li>
                <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
                </ul>
            </div>
        </div>
    )
}