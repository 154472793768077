import Modal from "react-bootstrap/Modal";
import {Personal} from "../../../page";

export function BusinessCardPreview({showPreview, onHide, invitationCode, channelName}) {

    return (
        <Modal show={showPreview} onHide={onHide} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Bussiness Card Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: "0px"}}>
                <Personal channelName={channelName} invitationCode={invitationCode} preview={true} />
            </Modal.Body>
        </Modal>
    )

}