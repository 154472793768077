import { useState, useEffect } from "react";
import Avatar from 'react-avatar';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import { fetchWrapper } from '_helpers';
import { Loading, NoResults, Paginate } from "_components";
import { Link } from "react-router-dom";

export {
    Vendors
}

function Vendors() {
    const { register, watch, getValues, setValue } = useForm();
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(true);
    const [vendors, setVendors] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        loadVendors(page);
    }, [searchPattern, page])

    function loadVendors(page) {
        setLoading(true);
        setValue("pageSize", 10, { shouldTouch: true });
        setValue("pageNumber", page, { shouldTouch: true });
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/accountowner/vendors", getValues()).then(response => {
            setVendors(response.data.content);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    function suspendVendor(id) {
        setLoading(true);
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/accountowner/vendor/" + id + "/suspend").then(response => {
            loadVendors(page);
        });
    }

    function reactivateVendor(id) {
        setLoading(true);
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/accountowner/vendor/" + id + "/reactivate").then(response => {
            loadVendors(page);
        });
    }

    return (
        <div className="row justify-content-center">
            <div className="col-lg-11 col-xl-9">
                <div className="mt-5 mb-3">
                    <input type="text" className="form-control" name="searchPattern" {...register('searchPattern')} placeholder="Search vendors" />
                </div>
                <div className="">
                    <Link to="/invitation/vendor">Add new</Link>
                </div>
                {loading === false ?
                    <>
                        {vendors.length > 0 ?
                            <>
                                <div className='mt-4 border-bottom'></div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>User</td>
                                            <td>Last active</td>
                                            <td>Status</td>
                                            <td>Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {vendors.map(vendor =>
                                            <tr key={vendor.id} >
                                                <td className="align-middle w-10">
                                                    <Avatar name={vendor.lastName + " " + vendor.firstName} round={true} size="50" />
                                                </td>
                                                <td className="align-middle w-50">
                                                    <h6 className='mb-0'>{vendor.lastName} {vendor.firstName}</h6>
                                                    <p className="mb-0">{vendor.email}</p>
                                                    <p className="mb-0">{vendor.mobilePhone}</p>
                                                </td>
                                                <td className="align-middle">
                                                    {vendor.lastActiveTime && moment(vendor.lastActiveTime).format('MMM Do YYYY HH:mm')}
                                                </td>
                                                <td className="align-middle">
                                                    {vendor.status}
                                                </td>
                                                <td className="align-middle">
                                                    {vendor.status === 'ACTIVE' &&
                                                        <button className="btn btn-light shadow" type="submit" onClick={() => suspendVendor(vendor.id)} title="Suspend" ><FontAwesomeIcon icon={faPause} /></button>
                                                    }
                                                    {vendor.status === 'SUSPENDED' &&
                                                        <button className="btn btn-light shadow" type="submit" onClick={() => reactivateVendor(vendor.id)} title="Reactivate"><FontAwesomeIcon icon={faArrowsRotate} /></button>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                            : <NoResults />}
                    </>
                    : <Loading />
                }
                <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
            </div>
        </div>
    );

}
