import { ConfirmationDialog } from "_components";
import { fetchWrapper } from "_helpers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function Delete({leadId}) {
    const navigate = useNavigate();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    
    const deleteLead = () => {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId).then(response => {
            if (response.status === 200) {
                navigate(0);
            }
            setShowDeleteDialog(false);
        });
    }

    return (
        <>
            <a className="btn" onClick={() => setShowDeleteDialog(true)}>
                <img src="/trash-sharp-regular.svg" width={24} alt="delete-lead"/>
            </a>
            <ConfirmationDialog 
                show={showDeleteDialog}
                message="You are about to delete this lead. Are you sure?"
                handleConfirm={deleteLead} 
                handleCancel={() => setShowDeleteDialog(false)}
            />
        </>
    )

}