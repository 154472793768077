import { useState } from "react";
import { Parser } from '@json2csv/plainjs';
import FileSaver from "file-saver";
import { fetchWrapper } from "_helpers";

export function LeadsCsv({fileName, audienceId}) {
    const [loading, setLoading] = useState(false);
    const opts = { fields: ['name', 'email', 'phoneNumber'] };

    const getLeadsCsv = () => {
        if (loading === false) {
            setLoading(true);
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/audience/" + audienceId + "/leads", {pageSize: 0}).then(response => {
                const parser = new Parser(opts);
                const csv = parser.parse(response.data.content);
                const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                FileSaver.saveAs(csvData, fileName + '.csv');
                setLoading(false);
            
            });
        }
    }


    return (
        <>
            <button onClick={getLeadsCsv} disabled={loading} className="dropdown-item">
                {loading === true ? <span className="spinner-border spinner-border-sm mr-1"></span> : "Export"}
            </button>
        </>
    )

}