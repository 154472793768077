
export function Footer() {

    return (
        <div className="row g-0 justify-content-center" >
            <div className="col-sm-4 mt-4" style={{height: "60px"}}>
                <div className="row justify-content-center">
                    <div className="col-4">
                        <img src="/navbar.svg" alt="" width="160" height="50"/>
                    </div>
                    <div className="col-8">
                        <p className="text-dark mb-0">Powered by ABC.CRM</p>
                        <a href="https://abccrm.ca" style={{color: "#000"}} >https://abccrm.ca</a>
                    </div>
                </div>
            </div>
        </div>
    )

}