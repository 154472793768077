import { Loading } from "_components";
import { fetchWrapper } from "_helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { MessageReply } from "./MessageReply";
import { Footer } from "./Footer";

export function Message() {
    const { channelName, code } = useParams();
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState([])
    const [leadCode, setLeadCode] = useState(null);
    const [showReplyMessageDialog, setShowReplyMessageDialog] = useState(false);

    useEffect(() => {
        loadMessage()
    }, [channelName, code])

    const loadMessage = () => {
        setLoading(true)
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + code + "/message").then(response => {
            setMessage(response.data);
            setLeadCode(response.data.leadCode);
            setLoading(false);
        });
    }

    return (
        <>
            
                <div className="row justify-content-center">
                    <div className="col-sm-5">
                        {loading === true ? <Loading /> :
                            <>
                                <div className="mb-1">&nbsp;</div>
                                <div className="speech-bubble mt-2 ptop aleft" style={{ backgroundColor: "#fac8b6" }} >
                                    <div className="title" style={{ whiteSpace: "pre-line" }}>{message.content}</div>
                                    {message.offerCode &&
                                        <div className="row">
                                            <div className="col">
                                                <Link to={"/" + channelName + "/lead/" + code + "/offer/" + message.offerCode} className="float-end cursor fs-6" style={{ fontWeight: 600 }} >{message.offerTitle} &gt;&gt;</Link>
                                            </div>
                                        </div>
                                    }
                                    <p className="mb-0 fs-6">
                                        {message.createdByFirstName !== null ?
                                            <>{message.createdByFirstName} {message.createdByLastName}</>
                                            :
                                            <>{message.leadFirstName} {message.leadLastName}</>
                                        } on {moment(message.creationDate).format('MMM Do YYYY HH:mm')}</p>
                                </div>
                                <Link to={"/p/" + channelName + "/" + leadCode} >&lt;&lt;&nbsp;My profile</Link>
                                <a className="float-end cursor" onClick={() => setShowReplyMessageDialog(true)}>Reply&nbsp;&gt;&gt;</a>
                            </>
                        }
                    </div>
                </div>
            <Modal show={showReplyMessageDialog} onHide={() => setShowReplyMessageDialog(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>New Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MessageReply channelName={channelName} code={message?.code} callback={() => setShowReplyMessageDialog(false)} />
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    )

}