import { useState } from "react";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faX } from '@fortawesome/free-solid-svg-icons'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog } from "_components";

export function Slogan(props) {
    const authUser = useSelector(x => x.auth.user);
    const [company, setCompany] = useState(props.company);
    const [editMode, setEditMode] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const validationSchema = Yup.object({
        value: Yup.string().required('Slogan is required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        clearErrors
    } = useForm(formOptions);
    const { errors } = formState;

    function editProperty() {
        setValue('value', company.slogan);
        setEditMode(true);
        clearErrors();
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/accountowner/company/" + company.id + "/slogan", getValues()).then(response => {
            setEditMode(false);
            setCompany(response.data);
        });
    }

    function deleteProperty() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/accountowner/company/slogan").then(response => {
            setCompany(response.data);
            setShowConfirmationDialog(false);
        });
    }

    return (
        <div className="mb-3">
            <p className="mb-0">Slogan</p>
            <p className="text-secondary mb-0 fs-6">Add a striking and memorable phrase to catch the attention and engage the visitors. Be unique and specific, and distinguish yourself from others.</p>
            {editMode === true ?
                <>
                    <form onSubmit={handleSubmit(saveProperty)}>
                        <textarea type="text" {...register('value')} className={`form-control ${errors.value ? 'is-invalid' : ''}`} rows="3" ></textarea>
                        <div className="invalid-feedback">{errors.value?.message}</div>
                        <div className="mt-1">
                            <button type="submit" className="btn btn-sm me-1 btn-primary" >Confirm</button>
                            <a className="btn btn-sm btn-light" onClick={() => setEditMode(false)}>Cancel</a>
                        </div>
                    </form>
                </>
                :
                <>
                    <p className="mt-0" style={{ whiteSpace: "pre-line" }}>{company.slogan}
                        {authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true &&
                            <>
                                <a className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil} /></a>
                                {company.slogan && <a className="btn btn-link btn-sm text-danger" onClick={() => setShowConfirmationDialog(true)}><FontAwesomeIcon icon={faX} /></a>}
                            </>
                        }
                    </p>
                    {showConfirmationDialog &&
                        <ConfirmationDialog
                            show={showConfirmationDialog}
                            handleConfirm={deleteProperty}
                            handleCancel={() => setShowConfirmationDialog(false)}
                        />
                    }
                </>
            }
        </div>
    )

}