import { Tab, Tabs } from "react-bootstrap";
import { CompanyProfile } from "./company";
import { UserProfile } from "./user";

export {Profile}

function Profile() {
    const localStorageKey = "tab-profile-active";

    const handleSelect = (key) => {
        localStorage.setItem(localStorageKey, key);
    }

    return (
        <>
        <div className="row justify-content-center">
            <div className="col">
                <h3 className="mt-4 text-center">My Profiles</h3>
            </div>
        </div>
        <div className='mt-4 mb-4'></div>
        <Tabs defaultActiveKey={localStorage.getItem(localStorageKey) !== null ? localStorage.getItem(localStorageKey) : "company"} className="mb-3 justify-content-center" onSelect={handleSelect}>
            <Tab eventKey="company" title="COMPANY"><CompanyProfile/></Tab>
            <Tab eventKey="personal" title="PERSONAL"><UserProfile/></Tab>
        </Tabs>
        </>
    )

}