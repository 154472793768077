import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export function PasswordInputField({label, name, register, error}) {
    const [fieldType, setFieldType] = useState("password");
    const [eyeIcon, setEyeIcon] = useState(faEyeSlash);

    const toggleMode = () => {
        if (fieldType === "password") {
            setFieldType("text");
            setEyeIcon(faEye);
        } else {
            setFieldType("password");
            setEyeIcon(faEyeSlash);
        }
    }

    return (
        <div className="form-group mb-4">
            <label className="mb-2">{label ? label : "Password"}</label>
            <div className="input-group">
                <input name="password" type={fieldType} {...register(name ? name : 'password')} maxLength={100} className={`form-control ${error ? 'is-invalid' : ''}`} style={{borderRight: "none"}} />
                <button className="btn btn-outline-secondary" type="button" onClick={toggleMode} style={{border: "1px solid #ced4da", borderLeft: "none"}} ><FontAwesomeIcon icon={eyeIcon} /></button>
                <div className="invalid-feedback">{error?.message}</div>
            </div>
        </div>
    )

}