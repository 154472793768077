import { useTranslation } from 'react-i18next';
import { constants } from "_helpers";

export function IndustryInputField({register, error, label, name}) {
    const { t } = useTranslation();

    return (
        <div className="form-group mb-4">
            <label htmlFor="industry" className="mb-1">Industry</label>
            <select id="industry" {...register(name)} className={`form-select ${error ? 'is-invalid' : ''}`}>
                <option value="">Choose...</option>
                {constants.industries?.map(industry => 
                    <option key={industry} value={industry}>{t("INDUSTRY." + industry)}</option>
                )}
            </select>
            <div className="invalid-feedback">{error?.message}</div>
        </div>
    )

}