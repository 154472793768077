import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loading, NoResults, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import { Audience } from "./Audience";
import { Modal } from "react-bootstrap";
import { AudienceForm } from "./AudienceForm";

export function Audiences({handleAudienceClicked}) {
    const { watch, getValues, setValue } = useForm();
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(true);
    const [audiences, setAudiences] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [editDialog, setEditDialog] = useState(false);

    useEffect(() => {
        loadAudiences(page);
    }, [searchPattern, page])

    function loadAudiences(page) {
        setLoading(true);
        setValue("pageSize", 10, { shouldTouch: true });
        setValue("pageNumber", page, { shouldTouch: true });
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/audiences", getValues()).then(response => {
            setAudiences(response.data.content);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    return (
        <>
        <div className="row justify-content-center mt-3">
            <div className="col-10">
                <h4 className="mt-2">Audiences</h4>
            </div>
            <div className="col-2">
                <a onClick={() => {setEditDialog(true)}} className="btn" ><img src="circle-plus-regular.svg" width={20} alt="audience-add"/></a>
            </div>
        </div>
        {loading === false ?
            <>
                {audiences.length > 0 ?
                    <>
                        {audiences.map((audience, index) =>
                            <div key={index} >
                                <Audience
                                    audience={audience}
                                    handleAudienceClicked={handleAudienceClicked}
                                    callback={() => loadAudiences(page)}
                                />
                                {index < audiences.length - 1 && <div className="border-bottom"></div>}
                            </div>
                        )}
                    </>
                    : <NoResults />}
            </>
            : <Loading />
        }
        <Paginate totalPages={totalPages} handlePageClick={handlePageClick}/>
        <Modal show={editDialog} onHide={() => setEditDialog(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add New Audience</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AudienceForm callback={() => {setEditDialog(false);handlePageClick(0)}} />
            </Modal.Body>
        </Modal>
        </>
    )

}