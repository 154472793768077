import { Modal } from "react-bootstrap";
import { NewLeadMessage } from "./messages";
import {useEffect, useState} from "react";
import { fetchWrapper } from "_helpers";

export function SendToGroup({audienceId, excludeLeadIds, includeLeadIds, filterValues, selectedAll, deselectedAll}) {
    const [showDialog, setShowDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [recipientsCount, setRecipientsCount] = useState(0);

    useEffect(() => {
        if (showDialog === true) {
            countRecipients()
        }
    }, [showDialog]);

    const countRecipients = () => {
        if (selectedAll === true || (deselectedAll === true && includeLeadIds.size > 0)) {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/audience/" + audienceId + "/leads/messages-recipients", {
                "searchPattern": filterValues('searchPattern'),
                "offerId": filterValues('offerId'),
                "offerStateId": filterValues('offerStateId'),
                "tags": filterValues('tags'),
                "interactionsCount": filterValues('interactionsCount'),
                "lastInteractionWithin": filterValues('lastInteractionWithin'),
                "excludeLeadIds": Array.from(excludeLeadIds),
                "includeLeadIds": Array.from(includeLeadIds)
            }).then(response => {
                setRecipientsCount(response.data.value)
            });
        } else {
            setRecipientsCount(0)
        }
    }

    const newMessageCallback = async (data) => {
        await fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/audience/" + audienceId + "/leads/message", {
            "value": data.value,
            "offerId": data.offerId,
            "contactVendorAllowed": data.contactVendorAllowed,
            "leadsFilter": {
                "searchPattern": filterValues('searchPattern'),
                "offerId": filterValues('offerId'),
                "offerStateId": filterValues('offerStateId'),
                "tags": filterValues('tags'),
                "interactionsCount": filterValues('interactionsCount'),
                "lastInteractionWithin": filterValues('lastInteractionWithin'),
                "excludeLeadIds": Array.from(excludeLeadIds),
                "includeLeadIds": Array.from(includeLeadIds)
            }
        }).then(response => {
            if (response.status === 400) {
                setErrorMessage(response.data);
            } else {
                setShowDialog(false);
            }
        });
    }

    return (
        <>
            <a onClick={() => setShowDialog(true)} className="cursor" >Send To Group</a>
            <Modal show={showDialog} onHide={() => setShowDialog(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>New Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recipientsCount > 0 && <div className="alert alert-warning" >This message will be sent to {recipientsCount} numbers.</div>}
                    <NewLeadMessage callback={newMessageCallback} errorMessage={errorMessage} submitDisabled={recipientsCount > 0 ? false : true} />
                </Modal.Body>
            </Modal>
        </>
    )

}