import {useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil} from '@fortawesome/free-solid-svg-icons'
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { fetchWrapper } from '_helpers';

export {
    Email
}

function Email(props) {
    const [profile, setProfile] = useState(props.profile);
    const [editMode, setEditMode] = useState(false);

    const validationSchema = Yup.object({
        value: Yup.string().required('Email is required').email("Please enter a valid email address"),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        clearErrors
    } = useForm(formOptions);
    const {errors} = formState;

    function editProperty() {
        setValue('value', profile.email);
        clearErrors();
        setEditMode(true);
    }

    function saveProperty() {
        fetchWrapper.put(process.env.REACT_APP_API_URL + "/profile/email", getValues()).then(response => {
            setEditMode(false);
            setProfile(response.data);
        });
    }

    return (
        <div className="mb-3">
            <p className="mb-0">Email</p>
            {editMode === true ? 
            <>
                <form onSubmit={handleSubmit(saveProperty)}>
                    <div className="row g-2">
                        <div className="col-sm">
                            <input type="text" {...register('value')} maxLength="100" className={`form-control ${errors.value ? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.value?.message}</div>
                        </div>
                        <div className="col">
                            <button type="submit" className="btn btn-primary" >Confirm</button>
                            <a className="btn btn-light" onClick={() => setEditMode(false)}>Cancel</a>
                        </div>
                    </div>
                </form>
            </>
            :
            <p className="mt-0">{profile.email}
                <a className="btn btn-link btn-sm" onClick={() => editProperty()}><FontAwesomeIcon icon={faPencil}/></a>
            </p>
            }
        </div>
    )

}