import { fetchWrapper } from "_helpers";
import moment from "moment";
import { useState } from "react";
import { OfferPreview } from "vendor/offers";

export function Message({item, index}) {

    const [offerPreviewDialog, setOfferPreviewDialog] = useState({
        show: false,
        code: null
    });

    function previewOffer(id) {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + id + "/preview").then(response => {
            if (response.status === 200) {
                setOfferPreviewDialog({show: true, code: response.data.value})
            }
        });
    }

    return (
        <>
            <div className={`${item.leadMessageType === 'INCOMING' ? `speech-bubble aleft` : `speech-bubble aright`} pbottom mt-2 `} key={"message-" + index} >
                <div className="title">{item.content}</div>
                {item.offerId &&
                    <div className="row">
                        <div className="col">
                            <a className="float-end cursor fs-6" onClick={() => previewOffer(item.offerId)} style={{fontWeight: 600}} >{item.offerTitle} &gt;&gt;</a>
                        </div>
                    </div>
                }
                <p className="mb-0 fs-6">
                    {item.createdByFirstName !== null ?
                        <>{item.createdByFirstName} {item.createdByLastName}</>
                    :
                        <>{item.leadFirstName} {item.leadLastName}</>
                    } on {moment(item.creationDate).format('MMM Do YYYY HH:mm')}</p>
            </div>
            {offerPreviewDialog.show &&
                <OfferPreview 
                    show={offerPreviewDialog.show}
                    invitationCode={offerPreviewDialog.code}
                    cancelCallback={() => setOfferPreviewDialog({show: false})}
                />
            }
        </>
    )

}