import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
        "LEADOFFERSTATE.OPEN": "Open",
        "LEADOFFERSTATE.CLOSED_WON": "Won",
        "LEADOFFERSTATE.CLOSED_LOST": "Lost",
        "LEADOFFERSTATE.DEPRECATED": "Deprecated",
        "LEADOFFERSTATE.GOING": "Going",
        "LEADOFFERSTATE.INTERESTED": "Interested",
        "LEADOFFERSTATE.NOT_INTERESTED": "Not Interested",
        "LEADNOTETYPE.REMARK": "Remark",
        "LEADNOTETYPE.MEETING": "Meeting",
        "LEADNOTETYPE.CALL": "Call",
        "LEADNOTETYPE.PRESENTATION": "Presentation",
        "LEADNOTETYPE.DEMO": "Demo",
        "LEADNOTETYPE.WARNING": "Warning",
        "LEADNOTETYPE.REQUEST": "Request",
        "INDUSTRY.GENERAL": "General",
        "INDUSTRY.PERSONAL": "Personal",
        "INDUSTRY.REALTORS": "Realtors",
        "INDUSTRY.DEALERSHIP": "Dealership",
        "LEADINTERACTIONTYPE.AUDIENCE_SUBSCRIBE": "Audience Subscribed",
        "LEADINTERACTIONTYPE.AUDIENCE_UNSUBSCRIBE": "Audience Unsubscribed",
        "LEADINTERACTIONTYPE.OFFER_SUBSCRIBE": "Offer Subscribed",
        "LEADINTERACTIONTYPE.OFFER_OPEN": "Offer Opened",
        "LEADINTERACTIONTYPE.OFFER_UNSUBSCRIBE": "Offer Unsubscribed",
        "LEADINTERACTIONTYPE.COMPANY_SUBSCRIBE": "Company Subscribed",
        "LEADINTERACTIONTYPE.COMPANY_UNSUBSCRIBE": "Company Unsubscribed",
        "LEADINTERACTIONTYPE.PERSONAL_SUBSCRIBE": "Personal Subscribed",
        "LEADINTERACTIONTYPE.PERSONAL_UNSUBSCRIBE": "Personal Unsubscribed",
        "LEADINTERACTIONTYPE.MESSAGE_OPEN": "Clicks",
        "LEADINTERACTIONTYPE.MESSAGE_RECEIVED": "Message sent by Vendor",
        "LEADINTERACTIONTYPE.AUDIENCE_SUBSCRIBE.KEY": "AUDIENCE SUBSCRIBED",
        "LEADINTERACTIONTYPE.AUDIENCE_UNSUBSCRIBE.KEY": "AUDIENCE UNSUBSCRIBED",
        "LEADINTERACTIONTYPE.OFFER_SUBSCRIBE.KEY": "OFFER SUBSCRIBED",
        "LEADINTERACTIONTYPE.OFFER_OPEN.KEY": "OFFER OPENED",
        "LEADINTERACTIONTYPE.OFFER_UNSUBSCRIBE.KEY": "OFFER UNSUBSCRIBED",
        "LEADINTERACTIONTYPE.COMPANY_SUBSCRIBE.KEY": "COMPANY SUBSCRIBED",
        "LEADINTERACTIONTYPE.COMPANY_UNSUBSCRIBE.KEY": "COMPANY UNSUBSCRIBED",
        "LEADINTERACTIONTYPE.PERSONAL_SUBSCRIBE.KEY": "PERSONAL SUBSCRIBED",
        "LEADINTERACTIONTYPE.PERSONAL_UNSUBSCRIBE.KEY": "PERSONAL UNSUBSCRIBED",
        "LEADINTERACTIONTYPE.MESSAGE_OPEN.KEY": "CLICKS",
        "LEADINTERACTIONTYPE.MESSAGE_RECEIVED.KEY": "MESSAGE RECEIVED",
        "LANDINGPAGETYPE.COMPANY": "Company",
        "LANDINGPAGETYPE.PERSONAL": "Personal",
        "AudienceType.COMPANY": "All Leads",
        "AudienceType.PERSONAL": "My Leads",
        "AccountType.Personal": "Personal",
        "AccountType.PROFESSIONAL": "Professional"
    }
  },
  fr: {
    translation: {
      "Welcome to React": "Bienvenue à React et react-i18next"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;