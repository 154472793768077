import { useEffect } from "react";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

import { SubmitBar, TextInputField } from "_components";
import { constants, fetchWrapper } from "_helpers";

export function Edit({leadId, noteId, callback}) {
    const { t } = useTranslation();
    // form validation rules
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        contactTime: Yup.string().required('Contact time is required field')
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        control,
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        resetField,
        clearErrors,
        reset
    } = useForm(formOptions);
    const {errors, isSubmitting, isSubmitted, isSubmitSuccessful} = formState;

    useEffect(() => {
        reset()
        clearErrors();
        resetField('title');
        setValue('type', constants.noteTypes[0]);
        setValue('contactTime', new Date());
        if (noteId != null) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/note/" + noteId).then(response => {
                setValue('title', response.data.title);
                setValue('body', response.data.body);
                setValue('type', response.data.type);
                setValue('contactTime', new Date(response.data.contactTime));
            });
        }
    }, [noteId]);

    const onSubmit = async () => {
        if (noteId != null) {
            await fetchWrapper.put(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/note/" + noteId, getValues()).then(response => {
                callback();
            });
        } else {
            await fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/note", getValues()).then(response => {
                callback();
            });
        }
    };

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-4">
                            <label htmlFor="type" className="mb-1">Type</label>
                            <select id="type" {...register('type')} className={`form-select ${errors.type ? 'is-invalid' : ''}`}>
                                {constants.noteTypes?.map(noteType => 
                                    <option key={noteType} value={noteType}>{t("LEADNOTETYPE." + noteType)}</option>
                                )}
                            </select>
                            <div className="invalid-feedback">{errors.type?.message}</div>
                        </div>
                        <Controller
                            control={control}
                            name='contactTime'
                            render={({ field: { onChange, value }, fieldState: {error} }) => (
                                <div className="form-group mb-4">
                                    <label className="mb-1">Contact time</label>
                                    <DatePicker
                                        className={error?.message.length > 0 ? "form-control is-invalid" : "form-control"}
                                        placeholderText='Select date'
                                        onChange={onChange}
                                        selected={value}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        showTimeSelect
                                    />
                                    {error?.message.length > 0 && <div className="invalid-feedback">{error?.message}</div>}
                                </div>
                            )}
                        />
                        <TextInputField
                            register={register}
                            label="Title"
                            name="title"
                            error={errors.title}
                            maxLength="100"
                        />
                        <TextInputField
                            register={register}
                            label="Body"
                            name="body"
                            error={errors.body}
                            textarea={true}
                        />
                        <SubmitBar showCancel={false} labelConfirm="Save" isSubmitting={isSubmitting || (isSubmitted && isSubmitSuccessful)}/>
                    </form>
                </div>
            </div>
        </>
    )

}