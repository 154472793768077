import {useState, useEffect} from "react";

import {fetchWrapper} from '_helpers';

export {
    CountriesSelectField
}

function CountriesSelectField({register, setValue, error, name, selected, valueName}) {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ipCountryCode, setIpCountryCode] = useState('');

    const getIpData = (countries) => {
        fetch('https://ipapi.co/json/')
            .then(response => response.json())
            .then(data => {
                setIpCountryCode(data.country_code.toLowerCase())
                var result = countries.find(item => item.alpha2 === data.country_code.toLowerCase());
                if (result) {
                    if (valueName === true) {
                        setValue(name, result.country_name);
                    } else {
                        setValue(name, result.id);
                    }
                }
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/public/countries").then(response => {
            let countries = response.data.content;
            setCountries(countries);
            setLoading(false);
        });
    }, [])

    useEffect(() => {
        if (selected) {
            setValue(name, selected);
        } else {
            getIpData(countries);
        }
    }, [loading]);

    return (
        <div className="form-group mb-4">
            <label htmlFor="country" className="mb-2">Country</label>
            <select id="country" {...register(name)} className={`form-select ${error ? 'is-invalid' : ''}`}>
                <option key="empty" value=""></option>
                {countries?.map(country => 
                    <option key={country.alpha2} value={valueName === true ? country.name : country.id}>
                        {country.name}
                    </option>
                )}
            </select>
            <div className="invalid-feedback">{error?.message}</div>
        </div>
    )

}
