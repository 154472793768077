import { Controller } from "react-hook-form";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input/mobile";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useEffect, useState } from "react";

export function PhoneNumberInputField({label, name, control, phoneNumberChange}) {
    const [countryCode, setCountryCode] = useState(null);

    useEffect(() => {
        fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
            setCountryCode(data.country_code);
        })
        .catch(error => console.error(error));
    }, []);

    return (
        <Controller 
            name={name}
            control={control}
            rules={{
                required: true,
                validate: (value) => isValidPhoneNumber(value)
            }}
            render={({ field: { onChange, value }, fieldState: {error} }) => (
                <div className="form-group mb-2">
                    <label className="mb-1">{label}</label>
                    <PhoneInput 
                        value={value}
                        defaultCountry={countryCode}
                        onChange={(value) => {
                            onChange(value);
                            if (phoneNumberChange !== undefined) {
                                phoneNumberChange();
                            }
                        }}
                        limitMaxLength={true}
                        international={true}
                        autoComplete="false"
                        className={error?.message.length > 0 ? "form-control is-invalid" : "form-control"}
                    />
                    {error?.message.length > 0 && <div className="invalid-feedback">{error?.message}</div>}
                </div>
            )}
        />
    )

}