
export function Error({errorCode}) {

    return (
        <>
        {errorCode === 551 &&
            <div className="alert alert-warning" role="alert">
                The offer you are interested in is no longer available. Please subscribe to the company’s page, and somebody will contact you.
            </div>
        }
        </>
    )

}