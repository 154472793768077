import { useSelector } from 'react-redux';

import { AdminDashboard } from 'admin';
import { VendorDashboard } from 'vendor';

export { Home };

function Home() {
    const { user: authUser } = useSelector(x => x.auth);

    return (
        <>
            {authUser.roles.includes('ROLE_ADMIN') === true && <AdminDashboard/>}
            {authUser.roles.includes('ROLE_ACCOUNT_OWNER') === true && <VendorDashboard/>}
            {authUser.roles.includes('ROLE_VENDOR') === true && <VendorDashboard/>}
        </>
    );
}
