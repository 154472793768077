import {Link, NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

import {authActions} from '_store';
import { NavbarLogo, UserFullName } from '_components';
import { Offcanvas } from 'react-bootstrap';
import { useState } from 'react';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Notifications } from './Notifications';

export {
    Nav
};

function Nav() {
    const authUser = useSelector(x => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const [showSideMenu, setShowSideMenu] = useState(false);

    return (
        <header className="p-3 mb-3" style={{backgroundColor: 'black'}}>
            <div className="container">
                <div className='d-block d-sm-none' >
                <a onClick={() => setShowSideMenu(true)} className='float-left align-middle text-light me-3' ><FontAwesomeIcon icon={faBars} size='2xl' /></a>
                <NavbarLogo/>
                <Offcanvas show={showSideMenu} onHide={() => setShowSideMenu(false)} >
                    <Offcanvas.Header>
                    <Offcanvas.Title>
                        <NavbarLogo/>
                    </Offcanvas.Title>
                    <a onClick={() => setShowSideMenu(false)} className='float-right text-light' ><FontAwesomeIcon icon={faX} /></a>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ul>
                            <li>
                                <Link to="/dashboard" onClick={() => setShowSideMenu(false)} className="text-light">Dashboard</Link>
                            </li>
                            <li>
                                <Link to="/offers" onClick={() => setShowSideMenu(false)} className="text-light">Offers</Link>
                            </li>
                            <li>
                                <Link to="/account/manage" onClick={() => setShowSideMenu(false)} className='text-light'>Manage Account</Link>
                            </li>
                            <li>
                                <Link to="/vendors" onClick={() => setShowSideMenu(false)} className='text-light'>Users</Link>
                            </li>
                            <li>
                                <Link to="/profile" onClick={() => setShowSideMenu(false)} className='text-light'>My Profiles</Link>
                            </li>
                            <li>
                                <Link to="/password/manage" onClick={() => setShowSideMenu(false)} className='text-light'>Manage Password</Link>
                            </li>
                            <li>
                                <a className="text-light" onClick={logout} href="#">Sign out</a>
                            </li>
                        </ul>
                    </Offcanvas.Body>
                </Offcanvas>
                <Notifications />
                </div>
                <div className="d-none d-lg-block d-md-block">
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                        <NavbarLogo/>
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li>
                                <NavLink to="/dashboard" className="nav-item nav-link text-light">Dashboard</NavLink>
                            </li>
                            <li>
                                <NavLink to="/offers" className="nav-item nav-link text-light">Offers</NavLink>
                            </li>
                        </ul>
                        <Notifications />
                        <div className="dropdown">
                            <a href="#" className="d-block text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <UserFullName profile={authUser}/>    
                            </a>
                            <ul className="dropdown-menu text-small dropdown-menu-dark">
                                <li>
                                    <Link to="/account/manage" className='dropdown-item'>Manage Account</Link>
                                </li>
                                <li>
                                    <Link to="/vendors" className='dropdown-item'>Users</Link>
                                </li>
                                <li><hr className="dropdown-divider"/></li>
                                <li>
                                    <Link to="/profile" className='dropdown-item'>My Profiles</Link>
                                </li>
                                <li>
                                    <Link to="/password/manage" className='dropdown-item'>Manage Password</Link>
                                </li>
                                <li><hr className="dropdown-divider"/></li>
                                <li>
                                    <a className="dropdown-item" onClick={logout} href="#">Sign out</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );

}
