import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCameraAlt} from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { fetchWrapper } from '_helpers';
import { ImageUpload, Logo, ConfirmationDialog } from '_components';

export {
    CompanyLogo
}

function CompanyLogo(props) {
    const [show, setShow] = useState(false);
    const [company, setCompany] = useState(props.company)
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    function deleteLogo() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/accountowner/company/logo").then(response => {
            setCompany(response.data);
            setShowConfirmationDialog(false);
        });
    }

    function handleUpload(formData) {
        fetchWrapper.postFormData(process.env.REACT_APP_API_URL + "/accountowner/company/logo", formData).then(response => {
            setCompany(response.data);
            setShow(false);
        });
    }

    return (
        <>
        <Logo 
            logo={company.logo} 
            avatar={company.channelName} 
            path="/uploads/company" 
            handleDelete={() => setShowConfirmationDialog(true)}
            deleteAllowed={true}
        />
        <br/><button type='button' className='btn btn-link' onClick={() => setShow(true)}><FontAwesomeIcon icon={faCameraAlt}/></button>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
            <Modal.Title>Upload Company Logo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ImageUpload postData={handleUpload}/>
            </Modal.Body>
        </Modal>
        {showConfirmationDialog && 
            <ConfirmationDialog 
                show={showConfirmationDialog}
                handleConfirm={deleteLogo} 
                handleCancel={() => setShowConfirmationDialog(false)}
            />
        }
        </>
    )

}