import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

import { history } from '_helpers';
import { PrivateRoute } from '_components';
import { Home, PasswordManage, Profile } from 'home';
import { EmailVerify, Login, PasswordForget, PasswordReset } from 'login';
import { AdminCompany, Contacts } from 'admin';
import { AccountOwnerRegistration, RegistrationSuccess, VendorRegistration } from 'registration';
import {AccountOwnerInvitation, VendorInvitation} from 'invitation';
import { ManageAccount, Vendors} from 'accountowner';
import { Page } from 'page';
import { LeadEmailVerify, LeadMessage, LeadOffer, LeadProfile, Manage, Unsubscribed } from 'lead';
import { EditOffer, NewOffer, Offers } from 'vendor/offers';
import { PersonalProfileEdit } from 'home/profile/user';
import { CompanyProfileEdit } from 'home/profile/company';

export { App };

function App() {
    // init custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Home />
                    </PrivateRoute>
                }
            />
            <Route
                path="/admin/company/:companyId"
                element={
                    <PrivateRoute>
                        <AdminCompany />
                    </PrivateRoute>
                }
            />
            <Route
                path="/admin/invitation"
                element={
                    <PrivateRoute>
                        <AccountOwnerInvitation />
                    </PrivateRoute>
                }
            />
            <Route
                path="/admin/contacts"
                element={
                    <PrivateRoute>
                        <Contacts />
                    </PrivateRoute>
                }
            />
            <Route
                path="/profile"
                element={
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                }
            />
            
            <Route
                path="/profile/personal/edit"
                element={
                    <PrivateRoute>
                        <PersonalProfileEdit />
                    </PrivateRoute>
                }
            />

            <Route
                path="/profile/company/edit"
                element={
                    <PrivateRoute>
                        <CompanyProfileEdit />
                    </PrivateRoute>
                }
            />
            
            <Route
                path="/invitation/vendor"
                element={
                    <PrivateRoute>
                        <VendorInvitation />
                    </PrivateRoute>
                }
            />

            <Route
                path="/vendors"
                element={
                    <PrivateRoute>
                        <Vendors />
                    </PrivateRoute>
                }
            />

            <Route
                path="/offers"
                element={
                    <PrivateRoute>
                        <Offers />
                    </PrivateRoute>
                }
            />
            <Route
                path="/offer/new"
                element={
                    <PrivateRoute>
                        <NewOffer />
                    </PrivateRoute>
                }
            />

            <Route
                path="/offer/edit/:id"
                element={
                    <PrivateRoute>
                        <EditOffer />
                    </PrivateRoute>
                }
            />
            <Route path="/account/manage" 
                element={
                    <PrivateRoute>
                        <ManageAccount />
                    </PrivateRoute>
                }
            />
            <Route path="/password/manage" 
                element={
                    <PrivateRoute>
                        <PasswordManage />
                    </PrivateRoute>
                }
            />

            <Route path="/login" element={<Login />} />
            <Route path="/password/forget" element={<PasswordForget />} />
            <Route path="/password/reset/:token" element={<PasswordReset />} />
            <Route path="/m" element={<Manage />} />
            <Route path="/c/:channelName/:code" element={<LeadMessage />} />
            <Route path="/p/:channelName/:code" element={<LeadProfile />} />
            <Route path="/unsubscribed" element={<Unsubscribed />} />
            <Route path="/lead/email-verify/:code" element={<LeadEmailVerify />} />
            <Route path="/register" element={<AccountOwnerRegistration />} />
            <Route path="/register/vendor" element={<VendorRegistration />} />
            <Route path="/register/success" element={<RegistrationSuccess />} />
            <Route path="/verify/email/:token" element={<EmailVerify />} />
            <Route path="/:channelName/:invitationCode" element={<Page />} />
            <Route path="/:channelName/lead/:leadCode/offer/:offerCode" element={<LeadOffer />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
