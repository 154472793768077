import {useNavigate} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';
import { PasswordInputField, SubmitBar } from '_components';

export {PasswordManage}

function PasswordManage() {
    const navigate = useNavigate();

    // form validation rules
    const validationSchema = Yup.object({
        oldPassword: Yup.string().required('Enter an old password'),
        password: Yup.string().required('Enter a new password').min(8, "Password must be at least 8 characters"),
        confirmPassword: Yup.string().oneOf(
            [
                Yup.ref('password'), null
            ],
            'Passwords must match'
        ),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setError,
        getValues
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const onSubmit = () => {
        const values = getValues();
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/password/manage", values).then(response => {
            if (response.status === 535) {
                setError("oldPassword", {
                    type: 'custom',
                    message: "Wrong password!"
                }, {shouldFocus: true});
            } else {
                navigate("/");
            }
        });
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <h3 className="mt-4 text-center">Manage Password</h3>
                </div>
            </div>
            <div className='mt-4 mb-4 border-bottom'></div>
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xl-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <PasswordInputField label="Old Password" name='oldPassword' register={register} error={errors.oldPassword} />
                        <PasswordInputField label="New Password" register={register} error={errors.password} />
                        <PasswordInputField label="Retype Password" name='confirmPassword' register={register} error={errors.confirmPassword} />
                        <div className='mt-4 mb-4 border-bottom'></div>
                        <SubmitBar labelConfirm="Save" cancelLink="/" isSubmitting={isSubmitting} />
                    </form>
                </div>
            </div>
        </>
    )

}