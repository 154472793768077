import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SubmitBar, TextInputField } from "_components";
import { useEffect } from "react";

export function RealEstateRoom({handleConfirmed, id, room}) {

    const validationSchema = Yup.object({
        room: Yup.object({
                roomType: Yup.string().required('Room type is required field')
            })
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;
    
    useEffect(() => {
        if (room !== undefined) {
            setValue('room.roomType', room.roomType);
            setValue('room.level', room.level);
            setValue('room.dimension1', room.dimension1);
            setValue('room.dimension2', room.dimension2);
            setValue('room.id', room.id);
        }
    }, []);

    const onSubmitRoom = () => {
        setValue('room.id', id);
        handleConfirmed(getValues('room'));
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmitRoom)}>
                <input type="hidden" {...register('id')} />
                <TextInputField register={register} label="Room type" name="room.roomType" maxLength="100" error={errors.room?.roomType}/>
                <TextInputField register={register} label="Level" name="room.level" maxLength="100"/>
                <TextInputField register={register} label="Dimension 1" name="room.dimension1" maxLength="50"/>
                <TextInputField register={register} label="Dimension 2" name="room.dimension2" maxLength="50"/>
                <SubmitBar isSubmitting={isSubmitting} labelConfirm="Confirm" showCancel={false} />
            </form>
        </>
    )

}