import { Loading } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";

export function EmailVerify() {
    const [loading, setLoading] = useState(true);
    const {token} = useParams();

    useEffect(() => {
        verify()
    }, []);

    const verify = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/verify/email/" + token).then(response => {
            if (response.status === 200) {
                setLoading(false)
            }
        });
    }

    return (
        <>
            <div className="row g-0 justify-content-center">
                <div className="col-sm-6">
                    <div className="d-none d-lg-block d-md-block">
                        <img src="/login-optimized.png" alt="logo" className='img-fluid float-end' />
                    </div>
                    <div className='d-block d-sm-none' >
                        <img src="/login-narrow.png" alt="logo" className='img-fluid' />
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className='row g-0'>
                        <div className='col-1 d-none d-lg-block'>&nbsp;</div>
                        <div className='col'>
                            {loading === true ? <Loading/>
                            :
                                <div className={"content-justify-center"}>
                                    <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                                    <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                                    <h3 className='text-center mt-4'><strong>You have successfully verified email</strong></h3>
                                    <h5 className='text-center mt-4'><Link to={"/login"}>Go to the log-in page</Link></h5>
                                    <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                                    <div className='container'>
                                        
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='col-3 d-none d-lg-block'>&nbsp;</div>
                    </div>
                </div>
                <div className="col"></div>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center py-3" style={{backgroundColor: "#F2F3FA"}}>
                <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2024. All rights reserved.</p>
                <ul className="nav justify-content-center">
                    <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                    <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
                </ul>
            </div>
        </>
    )

}