import {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPencil, faX, faPlus} from '@fortawesome/free-solid-svg-icons'

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog, PhoneNumberForm } from "_components";

export function PhoneNumbers() {
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [show, setShow] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        id: null
    });

    const handleClose = () => setShow(false);

    useEffect(() => {
        loadPhoneNumbers();
    }, [])

    function loadPhoneNumbers() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/phone-numbers").then(response => {
            setPhoneNumbers(response.data);
        });
    }

    function newPhoneNumber() {
        setPhoneNumber(null);
        setShow(true);
    }
    
    function editPhoneNumber(phoneNumberId) {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/phone-number/" + phoneNumberId).then(response => {
            setPhoneNumber(response.data);
            setShow(true);
        });
    }

    function deletePhoneNumber() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/profile/phone-number/" + confirmationDialog.id).then(response => {
            loadPhoneNumbers();
            setConfirmationDialog({show:false});
        });
    }

    function onSubmit(data) {
        if (phoneNumber) {
            fetchWrapper.put(process.env.REACT_APP_API_URL + "/profile/phone-number/" + phoneNumber.id, data).then(response => {
                setShow(false);
                loadPhoneNumbers();
            });
        } else {
            fetchWrapper.post(process.env.REACT_APP_API_URL + "/profile/phone-number", data).then(response => {
                setShow(false);
                loadPhoneNumbers();
            });
        }
    };

    return (
        <>
            <div className="row mb-3">
                <p className="mb-0">Phone numbers<a className="btn btn-sm btn-primary ms-2 rounded-circle" href="#" onClick={newPhoneNumber}><FontAwesomeIcon icon={faPlus}/></a></p>
                <p className="text-secondary mb-0 fs-6">Enter your phone number so that others can reach you.</p>
                {phoneNumbers.map(phoneNumber => 
                    <div key={phoneNumber.id}>
                        {phoneNumber.title}: {phoneNumber.value}
                        <a className="btn btn-link btn-sm" onClick={() => editPhoneNumber(phoneNumber.id)}><FontAwesomeIcon icon={faPencil}/></a>
                        {phoneNumber.defaultResponseNumber === false && <a className="btn btn-link btn-sm text-danger" onClick={() => setConfirmationDialog({show:true, id: phoneNumber.id})}><FontAwesomeIcon icon={faX}/></a>}
                    </div>
                )}
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{phoneNumber ? 'Edit Phone Number' : 'Add New Phone Number'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        <div className="col">
                            <PhoneNumberForm 
                                data={phoneNumber}
                                onSubmitCallback={onSubmit}
                                showDefaultResponseNumber={true}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ConfirmationDialog 
                show={confirmationDialog.show}
                handleConfirm={deletePhoneNumber} 
                handleCancel={() => setConfirmationDialog({show: false})}
            />
        </>
    )

}