import { Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function Offers({channelName, code}) {    
    const { t } = useTranslation();
    const [offers, setOffers] = useState([])
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        loadOffers()
    }, [])

    const loadOffers = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/lead/" + channelName + "/" + code + "/offers", {"pageSize": 10, "pageNumber": page}).then(response => {
            setOffers(response.data.content);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements)
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    return (
        <>
            {offers.length > 0 && 
                <>
                <div className="row mt-2" style={{backgroundColor: "#e2e2e2", borderRadius: "1.5rem"}}>
                    <div className="col">
                        <h6 className="mt-1 mb-1"><strong>Offers{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>
                    </div>
                </div>
                <table className="offer-table">
                    <tbody>
                        {offers.map((item) => 
                            <tr key={item.code}>
                                <td>
                                    <p className="mb-1 mt-1 ms-3">
                                        <Link to={"/" + channelName + "/lead/" + code + "/offer/" + item.code} >{item.title}</Link>
                                    </p>
                                    <p className="mb-0 ms-3 fs-6">From {moment(item.offerCreationDate).format('MMM Do YYYY')}</p>
                                </td>
                                <td className={"w-5 offer-" + item.state} >
                                    <strong style={{ textTransform: "uppercase" }}>{t("LEADOFFERSTATE." + item.state)}</strong>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>  
                <Paginate totalPages={totalPages} handlePageClick={handlePageClick}/>
                </>
            }
        </>
    )

}