import { useTranslation } from 'react-i18next';

export function Icon({type}) {
    const width = 18;
    const { t } = useTranslation();

    return (
        <>
            {type === 'REMARK' && <img src='/note-sticky-regular.svg' alt="note-type" width={width} title={t("LEADNOTETYPE." + type)}/>}
            {type === 'MEETING' && <img src='/screen-users-regular.svg' alt="note-type" width={width}/>}
            {type === 'CALL' && <img src='/square-phone-regular.svg' alt="note-type" width={width}/>}
            {type === 'PRESENTATION' && <img src='/presentation-screen-regular.svg' alt="note-type" width={width}/>}
            {type === 'DEMO' && <img src='/laptop-binary-regular.svg' alt="note-type" width={width}/>}
            {type === 'WARNING' && <img src='/triangle-exclamation-regular.svg' alt="note-type" width={width}/>}
            {type === 'REQUEST' && <img src='/arrow-up-left-from-circle-sharp-regular.svg' alt="note-type" width={width}/>}
        </>
    )

}