// AppProvider.js
import React, { useState } from 'react';
import AppContext from './AppContext';

const AppProvider = ({ children }) => {
    const [selectedLead, setSelectedLead] = useState(null);
    const [reloadMessages, setReloadMessages] = useState(false);

    return (
        <AppContext.Provider value={{ selectedLead, setSelectedLead, reloadMessages, setReloadMessages }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;