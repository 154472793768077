import { ProfileField } from "_components";

export function Socials({profile}) {

    return (
        <>
            <p className="mb-0">Social Networks</p>
            <p className="text-secondary mb-0 fs-6">Add your social networks and enable your visitors to start following you.</p>
            <ProfileField data={profile} name="linkedin" editAllowed={true} deleteAllowed={true} apiEndpoint="/profile/linkedin" icon="/linkedin.svg"/>
            <ProfileField data={profile} name="twitter" editAllowed={true} deleteAllowed={true} apiEndpoint="/profile/twitter" icon="/twitter-tile.svg"/>
            <ProfileField data={profile} name="facebook" editAllowed={true} deleteAllowed={true} apiEndpoint="/profile/facebook" icon="/facebook-tile.svg"/>
            <ProfileField data={profile} name="instagram" editAllowed={true} deleteAllowed={true} apiEndpoint="/profile/instagram" icon="/instagram-icon.svg"/>
            <ProfileField data={profile} name="tiktok" editAllowed={true} deleteAllowed={true} apiEndpoint="/profile/tiktok" icon="/tiktok.svg"/>
        </>
    )

}