import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function RegistrationSuccess() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null) {
            navigate("/login")
        }
    },[])

    return (
        <>
            <div className="row g-0 justify-content-center">
                <div className="col-sm-6">
                    <div className="d-none d-lg-block d-md-block">
                        <img src="/login-optimized.png" alt="logo" className='img-fluid float-end' />
                    </div>
                    <div className='d-block d-sm-none' >
                        <img src="/login-narrow.png" alt="logo" className='img-fluid' />
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className='row g-0'>
                        <div className='col-1 d-none d-lg-block'>&nbsp;</div>
                        <div className='col'>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            {location.state && 
                                <h3 className='text-center mt-4'>
                                    <strong>
                                        Hi {location.state?.firstName}
                                    </strong>
                                </h3>
                            }
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <div className='container'>
                                You have successfully created accont. Please check email and follow instructions to verify account.
                            </div>
                        </div>
                        <div className='col-3 d-none d-lg-block'>&nbsp;</div>
                    </div>
                </div>
                <div className="col"></div>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center py-3" style={{backgroundColor: "#F2F3FA"}}>
                <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2024. All rights reserved.</p>
                <ul className="nav justify-content-center">
                    <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                    <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
                </ul>
            </div>
        </>
    )

}