import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AudienceTitle, ConfirmationDialog} from "_components";
import TextTruncate from "react-text-truncate";
import {LeadsCsv} from "./LeadsCsv";
import {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {AudienceForm} from "./AudienceForm";
import {fetchWrapper} from "_helpers";
import moment from "moment";

export function Audience({audience, callback, handleAudienceClicked}) {
    const [confirmationDialog, setConfirmationDialog] = useState({
        show: false,
        id: null
    });
    const [editDialog, setEditDialog] = useState({
        show: false,
        id: null
    });
    const [activationDialog, setActivationDialog] = useState({
        show: false,
        id: null
    });
    const [deactivationDialog, setDeactivationDialog] = useState({
        show: false,
        id: null
    });
    const [archiveDialog, setArchiveDialog] = useState({
        show: false,
        id: null
    });
    const [unarchiveDialog, setUnarchiveDialog] = useState({
        show: false,
        id: null
    });
    const [analytics, setAnalytics] = useState(null);

    useEffect(() => {
        loadAnalytics();
    }, [audience])

    const loadAnalytics = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audience/" + audience.id + "/analytics").then(response => {
            setAnalytics(response.data);
        });
    }

    const onAudienceSaved = () => {
        setEditDialog({show: false})
        callback();
    }

    function deleteAudience() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/vendor/audience/" + confirmationDialog.id).then(response => {
            setConfirmationDialog({show: false})
            callback();
        });
    }

    function activateAudience() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audience/" + activationDialog.id + "/activate").then(response => {
            setActivationDialog({show: false})
            callback();
        });
    }

    function archiveAudience() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audience/" + archiveDialog.id + "/archive").then(response => {
            setArchiveDialog({show: false})
            callback();
        });
    }

    function unarchiveAudience() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audience/" + unarchiveDialog.id + "/unarchive").then(response => {
            setUnarchiveDialog({show: false})
            callback();
        });
    }

    function deactivateAudience() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audience/" + deactivationDialog.id + "/deactivate").then(response => {
            setDeactivationDialog({show: false})
            callback();
        });
    }

    return (
        <>
            <div className="row mt-1 mb-1">
                <div className="col-8">
                    <button className="btn btn-link ps-0 pb-0" style={{textAlign: 'left'}}
                            onClick={() => handleAudienceClicked(audience)}>
                        <p className='mb-0'><AudienceTitle audience={audience}/></p>
                    </button>
                    {(audience.type === 'GENERAL' && audience.description) &&
                        <TextTruncate
                            line={1}
                            truncateText="…"
                            text={audience.description}
                            containerClassName="mb-0 text-secondary"
                        />
                    }
                    {audience.type === 'COMPANY' &&
                        <p className="mb-0 text-secondary">Account leads</p>
                    }
                    {audience.type === 'PERSONAL' &&
                        <p className="mb-0 text-secondary">Personal leads</p>
                    }
                    <div className="row">
                        <div className="col">
                            {audience.status === 'ACTIVE' && <span className="badge text-bg-success">Active</span>}
                            {audience.status === 'INACTIVE' &&
                                <span className="badge text-bg-secondary">Inactive</span>}
                            {audience.type === 'GENERAL' &&
                                <p className="text-secondary ms-1 mb-0 fs-6" style={{
                                    display: "inline",
                                    paddingTop: ".1rem"
                                }}>{audience.createdByFirstName && <>{audience.createdByFirstName + " " + audience.createdByLastName}</>} {moment(audience.creationDate).format('MMM Do YYYY')}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">
                    {analytics &&
                        <>
                            <h4 className="mt-2 mb-0">{analytics.leadsCount}</h4>
                            {analytics.leadsLastWeekCount - analytics.leadsBeforeLastWeekCount !== 0 &&
                                <p className={analytics.leadsLastWeekCount - analytics.leadsBeforeLastWeekCount > 0 ? 'text-success h5' : 'text-danger h5'}>
                                    <img
                                        src={analytics.leadsLastWeekCount - analytics.leadsBeforeLastWeekCount > 0 ? '/green-triangle.svg' : '/red-triangle.svg'}
                                        className="me-1" alt="triangle"/>
                                    {analytics.leadsLastWeekCount - analytics.leadsBeforeLastWeekCount}
                                </p>
                            }
                        </>
                    }
                </div>
                <div className="col-2">
                    <div className="dropdown mt-2 float-end">
                        <button className="btn btn-link btn-sm dropdown-toggle shadow" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faEllipsisV}/>
                        </button>
                        <ul className="dropdown-menu">
                            {audience.type === 'GENERAL' &&
                                <>
                                    {audience.archived === true ?
                                        <li>
                                            <button className="dropdown-item" onClick={() => setUnarchiveDialog({
                                                show: true,
                                                id: audience.id
                                            })}>Unarchive
                                            </button>
                                        </li>
                                        :
                                        <>
                                            <li>
                                                <button className="dropdown-item" onClick={() => setEditDialog({
                                                    show: true,
                                                    id: audience.id
                                                })}>Edit
                                                </button>
                                            </li>
                                            {audience.status === 'INACTIVE' && <li>
                                                <button className="dropdown-item" onClick={() => setActivationDialog({
                                                    show: true,
                                                    id: audience.id
                                                })}>Activate
                                                </button>
                                            </li>}
                                            {audience.status === 'ACTIVE' && <li>
                                                <button className="dropdown-item" onClick={() => setDeactivationDialog({
                                                    show: true,
                                                    id: audience.id
                                                })}>Deactivate
                                                </button>
                                            </li>}
                                            <li>
                                                <button className="dropdown-item" onClick={() => setArchiveDialog({
                                                    show: true,
                                                    id: audience.id
                                                })}>Archive
                                                </button>
                                            </li>
                                            <li>
                                                <button className="dropdown-item" onClick={() => setConfirmationDialog({
                                                    show: true,
                                                    id: audience.id
                                                })}>Delete
                                                </button>
                                            </li>
                                        </>
                                    }
                                </>
                            }
                            <li><LeadsCsv fileName={<AudienceTitle audience={audience}/>} audienceId={audience.id}/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                show={confirmationDialog.show}
                message="You are about to remove this audience permanently. The leads will not be deleted. Are you sure?"
                handleConfirm={deleteAudience}
                handleCancel={() => setConfirmationDialog({show: false, id: null})}
            />
            <ConfirmationDialog
                show={activationDialog.show}
                message="You are about to activate this audience. Are you sure?"
                handleConfirm={activateAudience}
                handleCancel={() => setActivationDialog({show: false, id: null})}
            />
            <ConfirmationDialog
                show={deactivationDialog.show}
                message="You are about to deactivate this audience. Are you sure?"
                handleConfirm={deactivateAudience}
                handleCancel={() => setDeactivationDialog({show: false, id: null})}
            />
            <ConfirmationDialog
                show={archiveDialog.show}
                message="You are about to archive this audience. Are you sure?"
                handleConfirm={archiveAudience}
                handleCancel={() => setArchiveDialog({show: false, id: null})}
            />
            <ConfirmationDialog
                show={unarchiveDialog.show}
                message="You are about to unarchive this audience. Are you sure?"
                handleConfirm={unarchiveAudience}
                handleCancel={() => setUnarchiveDialog({show: false, id: null})}
            />
            <Modal show={editDialog.show} onHide={() => setEditDialog({show: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>{editDialog.id ? 'Edit Audience' : 'Add New Audience'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AudienceForm audienceId={editDialog.id} callback={onAudienceSaved}/>
                </Modal.Body>
            </Modal>
        </>
    )

}